import FileSaver from 'file-saver'
import store from './modules/auth/store'
import { mapActions } from 'vuex'

export const myMixin = {
    
    methods: {
        ...mapActions('controlPanel', ['ActionExportPdf']),

        getObject(result){
            return getObject(result);
        },

        download(code, params = null){
            return this.request(code, params, 'excel/export');
        },

        exportPdf(type, id, url = null){
            
            var root = process.env.VUE_APP_ROOT_API;
            
            if(url){
                url = root + url;
            }else{
                var route = 'pdf/export/' + id + '/' + type;
                url = root + route + '?token=' + store.state.token;
            }

            var newwindow = window.open(url, '_blank');
            newwindow.onbeforeunload = function () { };

            // var payload = {
            //     id: id,
            //     type: type
            // };

            //this.ActionExportPdf(payload)
            //    .then((res) => {
                    
                    // const textEncoder = new TextEncoder();
                    // const uint8Array = textEncoder.encode(res.data);

                    //const blob = new Blob([res], { type: 'application/pdf' });
                    //var url2 = (window.URL || window.webkitURL).createObjectURL(blob);
                    //var url2 = window.webkitURL.createObjectURL(blob);

                    // const blob = new Blob([res.blob], { type: 'application/pdf' });
                    // const url2 = window.URL.createObjectURL(blob);
                    //window.open(url2, '_blank');

                //});
                
            
            // var payload = {
            //     id: id,
            //     type: type
            // };
            
            // this.ActionExportPdf(payload)
            //     .then((res) => {
            //         const url = window.URL.createObjectURL(new Blob([res]));
            //         const link = document.createElement('a');
            //         link.href = url;
            //         link.setAttribute('download', 'teste.pdf');
            //         document.body.appendChild(link);
            //         link.click();
            //     })
            //     .finally(() => {
                    
            //     });
        },

        downloadLayout(name){
            return this.request(name, null, 'excel/layout');
        },

        request(param, params = null, route = null){
            
            var root = process.env.VUE_APP_ROOT_API;
            var url = root + route + '/' + param +'/?token=' + store.state.token;
            
            if(params){
                url += '&' + params;
            }

            // window.open(url, '_blank'); // _blank | _self
            var newwindow = window.open(url, '_blank');
            
            newwindow.onbeforeunload = function () { };
           
        },
    }
}

export const getObject = (result) => {
    var object = eval('(' + result.bodyText + ')');
    //var object = eval(result.bodyText);
    //var object = result.bodyText;
    return object;
};

export const downloadCsv = (text, fileName, ext = 'csv') => {
    
    const BOM = "\uFEFF";
    const csvData = BOM + text;
    
    var blob = new Blob([csvData],{ 
        type: "text/csv;charset=utf-8"
    });

    FileSaver.saveAs(blob, fileName + "." + ext);
      
};


