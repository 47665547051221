<template>
    <sys-register-base 
        :loading="saving" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :icon="icon" 
        v-bind:show.sync="dialog"
        maxWidth="70%"
    >
        <template v-slot:form>
            
            <v-progress-linear
                v-if="loading"
                indeterminate
                color="yellow darken-2"
            />
            
            <v-tabs class="ma-0" v-model="tab">
                <v-tab key="main">Textos</v-tab>
                <v-tab key="show">Exibição</v-tab>
            </v-tabs>
            <v-tabs-items  v-model="tab">
                <v-tab-item key="main">
                    <v-container v-if="model">
                            <v-row v-if="acesso.instituicao.id == 1">
                                <v-col cols="12" sm="12">
                                    <v-alert
                                        dense
                                        border="left"
                                        type="warning"
                                    >
                                        As alterações realizadas serão consideradas <strong>PADRÃO</strong> para as <strong>DEMAIS INSTITUIÇÕES</strong>.
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-text-field 
                                        v-model="model.title" 
                                        autofocus 
                                        :error="!!errors.title" 
                                        :error-messages="errors.title" 
                                        label="Título"
                                        clearable
                                        persistent-placeholder
                                        :placeholder="model.default.title"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-textarea
                                        :error-messages="errors.header" 
                                        v-model="model.header" 
                                        label="Cabeçalho"
                                        outlined
                                        clearable
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field 
                                        v-model="model.caption_availablity"     
                                        :error="!!errors.caption_availablity" 
                                        :error-messages="errors.caption_availablity" 
                                        clearable
                                        label="Cabeçalho do quadro Disponibilidade"
                                        persistent-placeholder
                                        :placeholder="model.default.caption_availablity"
                                    />
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field 
                                        v-model="model.caption_attributions" 
                                        :error="!!errors.caption_attributions" 
                                        :error-messages="errors.caption_attributions"
                                        clearable
                                        label="Cabeçalho do quadro Atribuições"
                                        persistent-placeholder
                                        :placeholder="model.default.caption_attributions"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="4">
                                    <v-text-field 
                                        v-model="model.caption_class_time_tables"     
                                        :error="!!errors.caption_class_time_tables" 
                                        :error-messages="errors.caption_class_time_tables"
                                        clearable
                                        label="Cabeçalho do quadro Horário das Aulas"
                                        persistent-placeholder
                                        :placeholder="model.default.caption_class_time_tables"
                                    />
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-text-field 
                                        v-model="model.caption_activities" 
                                        :error="!!errors.caption_activities" 
                                        :error-messages="errors.caption_activities"
                                        clearable
                                        label="Cabeçalho Quadro Atividades Docentes"
                                        persistent-placeholder
                                        :placeholder="model.default.caption_activities"
                                    />
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-text-field 
                                        v-model="model.caption_activities_admin" 
                                        :error="!!errors.caption_activities_admin" 
                                        :error-messages="errors.caption_activities_admin"
                                        clearable
                                        label="Cabeçalho Quadro Atividades Administrativas"
                                        persistent-placeholder
                                        :placeholder="model.default.caption_activities_admin"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="3">
                                    <v-text-field 
                                        v-model="model.caption_activity_time_tables"     
                                        :error="!!errors.caption_activity_time_tables" 
                                        :error-messages="errors.caption_activity_time_tables" 
                                        clearable
                                        label="Cabeçalho do quadro Horário das Atividades"
                                        persistent-placeholder
                                        :placeholder="model.default.caption_activity_time_tables"
                                    />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field 
                                        v-model="model.caption_summary" 
                                        :error="!!errors.caption_summary" 
                                        :error-messages="errors.caption_summary"
                                        clearable
                                        label="Cabeçalho do Quadro Resumo"
                                        persistent-placeholder
                                        :placeholder="model.default.caption_summary"
                                    />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field 
                                        v-model="model.caption_joins" 
                                        :error="!!errors.caption_joins" 
                                        :error-messages="errors.caption_joins"
                                        clearable
                                        label="Cabeçalho do Quadro de Junção"
                                        persistent-placeholder
                                        :placeholder="model.default.caption_joins"
                                    />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field 
                                        v-model="model.text_additional_signature" 
                                        :error="!!errors.text_additional_signature" 
                                        :error-messages="errors.text_additional_signature"
                                        clearable
                                        label="Responsável Assinatura Adicional"
                                        persistent-placeholder
                                        :placeholder="model.default.text_additional_signature"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-textarea
                                        :error-messages="errors.footer" 
                                        v-model="model.footer" 
                                        label="Rodapé"
                                        outlined
                                        clearable
                                    />
                                </v-col>
                            </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="show">
                    <v-container v-if="model">
                        <v-row>
                            <v-col cols="12" sm="3">
                                <s-switch text-helper="Exibir quadro resumo da Carga Horária" inset v-model="model.show_summary" label="Quadro Resumo"/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <s-switch text-helper="Exibir quadro de Disponibilidade" inset v-model="model.show_availability" label="Disponibilidade"/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <s-switch text-helper="Exibir quadro de Atribuição de aulas" inset v-model="model.show_attributions" label="Atribuição"/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <s-switch text-helper="Exibir quadro de Horário de Aula" inset v-model="model.show_class_time_tables" label="Horário de Aulas"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <s-switch text-helper="Exibir quadro de Atividades Docente" inset v-model="model.show_activities" label="Atividades Docente"/>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <s-switch text-helper="Exibir quadro de Atividades Administrativa" inset v-model="model.show_activities_admin" label="Atividades Administrativas"/>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <s-switch text-helper="Exibir quadro de Horário de Atividades" inset v-model="model.show_activity_time_tables" label="Horário das Atividades"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3">
                                <s-switch text-helper="Exibir logo da empresa" inset v-model="model.show_company_logo" label="Logo da empresa"/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <s-switch text-helper="Exibir foto do professor" inset v-model="model.show_avatar" label="Foto do Professor"/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <s-switch text-helper="Exibir as turmas da junção" inset v-model="model.show_joins" label="Exibir Junções"/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <s-switch text-helper="Agrupar horários das aulas com início e fim" inset v-model="model.group_schedules" label="Agrupar Horários"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3">
                                <s-switch text-helper="Exibir confirmação de carga horária" inset v-model="model.show_workload_confirmation" label="Confirmação de CH"/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <s-switch text-helper="Exibir Cnpj no título do extrato" inset v-model="model.show_cnpj" label="CNPJ"/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <s-switch text-helper="Exibir cargo do professor" inset v-model="model.show_position" label="Cargo"/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <s-switch text-helper="Exibir Regime de Trabalho do professor" inset v-model="model.show_work_regime" label="Regime de Trabalho"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3">
                                <s-switch text-helper="Exibir titulação do professor" inset v-model="model.show_titling" label="Titulação"/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <s-switch text-helper="Exibir tipo da disciplina no quadro de atribuições" inset v-model="model.show_discipline_type" label="Tipo Disciplina"/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <s-switch text-helper="Exibir datas das ofertas por data no quadro de horários" inset v-model="model.show_grid_dates" label="Data da Aula"/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <s-switch text-helper="Exibir município da Instituição junto com a data no rodapé do documento" inset v-model="model.show_footer_city_date" label="Município"/>
                            </v-col>
                            <!-- <v-col cols="12" sm="3">
                                <s-switch text-helper="Ao agrupar os horários de aulas, exibir os horários com intervalo" inset :disabled="!model.group_schedules" v-model="model.show_ranges" label="Exibir intervalos"/>
                            </v-col> -->
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3">
                                <s-switch text-helper="Exibir campo de assinatura do professor" inset v-model="model.show_teacher_signature" label="Assinatura"/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <s-switch text-helper="Exibir campo de assinatura adicional" inset v-model="model.show_additional_signature" label="Ass. Adicional"/>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>

            </v-tabs-items>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysTeacherExtractSetting",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        saving: false,
        model: null,
        tab: 0,
    }),
    created(){
       
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                this.tab = 0;
                this.error = false;
                this.errors = {};
                this.getData();
            }
        },
        
    },
    props: {
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-cog-box"
        },
        title: {
            type: String,
            default: "Configuração Extrato Professor"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('teacher', ['ActionIndexTeacherExtractSetting', 'ActionUpdateTeacherExtractSetting']),

        getData(){

            this.error = false;
            this.message = '';
            this.loading = true;
            this.model = null;
            this.modelDefault = null;

            var payload = {
                find_columns: {
                    institution_id: this.acesso.instituicao.id,
                },
            };
            

            this.ActionIndexTeacherExtractSetting(payload)
                .then((res) => {
                    this.model = res.data.length > 0 ? res.data[0] : null;
                })
                .catch((error) =>{
                    
                })
                .finally(() => {
                     this.loading = false;
                })

        },
        
        save(edit)
        {
            if(!this.model){
                return;
            }
            
            this.error = false;
            this.errors = [];
            this.message = '';
            this.saving = true;

            Object.assign(this.model, {institution_id: this.acesso.instituicao.id})

            if(edit) {
                this.ActionUpdateTeacherExtractSetting(this.model)
                .then((res) => {
                    this.model = res.data;
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.saving = false;
                     this.dialog = false;
                })
            } 
        },
    }
}
</script>