var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: { icon: "mdi-home-import-outline", title: "Vínculo de Ambientes" },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    color: "success darken-1",
                                    icon: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.download(25)
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-microsoft-excel")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v(" Relatório de Ambientes ")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    color: "success darken-1",
                                    icon: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.download(4, "type=ambience")
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-microsoft-excel")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v(" Horário dos Ambientes ")])]
              ),
              _c("s-icon-report-download", {
                attrs: {
                  "show-message": "",
                  color: "purple",
                  "tool-tip": "Horários em Linhas",
                  report: "12"
                }
              }),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: { color: "teal darken-1", icon: "" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      _vm.showFilterPrints = true
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-printer-check")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Impressão de Horários")])]
              ),
              _c("v-divider", {
                staticClass: "ma-2",
                attrs: { vertical: "", inset: "" }
              }),
              _c("sys-ambience-pop-over-link", {
                attrs: { configs: _vm.config },
                on: {
                  "update:configs": function($event) {
                    _vm.config = $event
                  }
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("emc-loader-progress", { attrs: { show: _vm.loading } }),
      _c("sys-print-time-table", {
        attrs: { show: _vm.showFilterPrints },
        on: {
          "update:show": function($event) {
            _vm.showFilterPrints = $event
          }
        }
      }),
      _c("sys-ambience-search", {
        attrs: {
          autoSearch: _vm.autoSearch,
          searchStart: _vm.searchStart,
          show: _vm.showFormSearch
        },
        on: {
          onSearch: _vm.searchAmbiences,
          "update:show": function($event) {
            _vm.showFormSearch = $event
          }
        }
      }),
      _c("emc-alert-modal", {
        attrs: {
          show: _vm.showJoin,
          title: _vm.itemJoin
            ? "Junção [" +
              _vm.itemJoin.id +
              "]" +
              (_vm.itemJoin.students ? " Alunos: " + _vm.itemJoin.students : "")
            : "",
          icon: "mdi-vector-union"
        },
        on: {
          "update:show": function($event) {
            _vm.showJoin = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "text",
            fn: function() {
              return [
                _vm.loadingJoins
                  ? _c("v-progress-linear", {
                      staticClass: "mb-0",
                      attrs: { indeterminate: "", color: "blue" }
                    })
                  : _vm._e(),
                _vm.itemJoin && _vm.itemJoin.grids
                  ? _c(
                      "ul",
                      _vm._l(_vm.itemJoin.grids, function(join, i) {
                        return _c(
                          "li",
                          { key: i },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  join.group.campus.abbreviation +
                                    " | " +
                                    join.group.name +
                                    " | " +
                                    join.discipline.name
                                ) +
                                " "
                            ),
                            join.students
                              ? _c("span", [
                                  _vm._v("(" + _vm._s(join.students) + ")")
                                ])
                              : _vm._e(),
                            join.join.main
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticStyle: {
                                                        cursor: "help"
                                                      },
                                                      attrs: {
                                                        "x-small": "",
                                                        icon: ""
                                                      }
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        "x-small": "",
                                                        color: "amber",
                                                        btn: ""
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-star-half-full"
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [_c("span", [_vm._v(" Turma Principal ")])]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "v-container",
        { staticClass: "pa-0 ma-0", attrs: { fluid: "" } },
        [
          false
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12" } },
                    [
                      _c(
                        "v-expansion-panels",
                        { attrs: { height: "100px", focusable: "" } },
                        [
                          _c(
                            "v-expansion-panel",
                            [
                              _c("v-expansion-panel-header", [
                                _c(
                                  "strong",
                                  {
                                    staticClass: "primary--text justify-center"
                                  },
                                  [_vm._v("Filtros Gerais")]
                                )
                              ]),
                              _c("v-expansion-panel-content")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4" } },
                [
                  _c(
                    "v-expansion-panels",
                    {
                      attrs: { height: "100px", focusable: "" },
                      model: {
                        value: _vm.panelGroup,
                        callback: function($$v) {
                          _vm.panelGroup = $$v
                        },
                        expression: "panelGroup"
                      }
                    },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              attrs: { "disable-icon-rotate": "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "actions",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass: "ma-1",
                                                          attrs: {
                                                            disabled: !_vm.enableFilterGroup,
                                                            icon: "",
                                                            small: ""
                                                          }
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "blue"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              $event.preventDefault()
                                                              return _vm.clearFilterGroup()
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " mdi-filter-remove-outline "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(" Limpar Filtros ")
                                          ])
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            },
                            [
                              _c(
                                "strong",
                                { staticClass: "primary--text" },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "primary" } },
                                    [_vm._v(" mdi-filter-outline ")]
                                  ),
                                  _vm._v(" Filtro de Turmas ")
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "12" } },
                                    [
                                      _c("sys-select-campus", {
                                        attrs: {
                                          model: _vm.campuses,
                                          label: "Campus da Turma*",
                                          multiple: _vm.multipleCampus,
                                          "auto-complete": !_vm.multipleCampus,
                                          "filter-relation": "groups"
                                        },
                                        on: {
                                          "update:model": function($event) {
                                            _vm.campuses = $event
                                          },
                                          change: _vm.changeCampusGroup
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("sys-select-shift", {
                                        attrs: {
                                          model: _vm.shifts,
                                          label: "Turno*",
                                          multiple: ""
                                        },
                                        on: {
                                          "update:model": function($event) {
                                            _vm.shifts = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("sys-select-work-load-type", {
                                        attrs: {
                                          model: _vm.workLoads,
                                          label: "Tipo de CH*",
                                          multiple: "",
                                          selectAll: ""
                                        },
                                        on: {
                                          "update:model": function($event) {
                                            _vm.workLoads = $event
                                          },
                                          onSelectAll: function($event) {
                                            _vm.isSelectAllWorkLoadTypes = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "12" } },
                                    [
                                      _c("sys-select-ambience-type", {
                                        attrs: {
                                          model: _vm.ambienceGridTypes,
                                          label:
                                            "Tipo de Ambiente configurado na Grade",
                                          multiple: "",
                                          selectAll: "",
                                          "only-with-link": ""
                                        },
                                        on: {
                                          "update:model": function($event) {
                                            _vm.ambienceGridTypes = $event
                                          },
                                          onSelectAll: function($event) {
                                            _vm.isSelectAllAmbienceTypes = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "12" } },
                                    [
                                      _c("sys-select-course", {
                                        attrs: {
                                          model: _vm.courses,
                                          label: "Curso*",
                                          multiple: "",
                                          selectAll: "",
                                          "campus-id": _vm.selectedCampus
                                            .campus_id_parent
                                            ? _vm.selectedCampus
                                                .campus_id_parent
                                            : _vm.selectedCampus.id,
                                          "campus-key": _vm.selectedCampus
                                            .campus_id_parent
                                            ? "campus_id_parent"
                                            : "id",
                                          shifts: _vm.shifts,
                                          disabled:
                                            !_vm.campuses ||
                                            _vm.campuses.length == 0 ||
                                            !_vm.shifts ||
                                            _vm.shifts.length == 0
                                        },
                                        on: {
                                          "update:model": function($event) {
                                            _vm.courses = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "8" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          dense: "",
                                          inset: "",
                                          label: "Exibir pendências"
                                        },
                                        model: {
                                          value: _vm.onlyPending,
                                          callback: function($$v) {
                                            _vm.onlyPending = $$v
                                          },
                                          expression: "onlyPending"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "4" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            disabled:
                                              !_vm.enableFilterGroup ||
                                              _vm.loadingFilterGroups,
                                            loading: _vm.loadingFilterGroups
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.filterGroups()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v("Filtrar"),
                                          _c(
                                            "v-icon",
                                            { attrs: { right: "", dark: "" } },
                                            [_vm._v("mdi-filter-menu-outline")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              attrs: { "disable-icon-rotate": "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "actions",
                                  fn: function() {
                                    return [
                                      _vm.group
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  color: "error"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    $event.stopPropagation()
                                                                    _vm.group = null
                                                                  }
                                                                }
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  dark: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-close-circle-multiple-outline"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                2792302677
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Limpar Seleção")
                                              ])
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            disabled: !_vm.group,
                                                            icon: "",
                                                            color: "primary"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              $event.stopPropagation()
                                                              _vm.autoSearch = true
                                                              _vm.showFormSearch = true
                                                            }
                                                          }
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { dark: "" } },
                                                        [_vm._v("search")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Pesquisa Avançada")
                                          ])
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            },
                            [
                              _c(
                                "strong",
                                { staticClass: "primary--text" },
                                [
                                  _c("v-icon", { attrs: { color: "amber" } }, [
                                    _vm._v(" mdi-account-group-outline ")
                                  ]),
                                  _vm._v(" Turmas ")
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c("v-text-field", {
                                staticClass: "ma-0 pa-0",
                                attrs: {
                                  "prepend-inner-icon": "search",
                                  "x-small": "",
                                  placeholder: "Pesquisar",
                                  rounded: "",
                                  "single-line": "",
                                  clearable: "",
                                  autofocus: ""
                                },
                                model: {
                                  value: _vm.searchGroup,
                                  callback: function($$v) {
                                    _vm.searchGroup = $$v
                                  },
                                  expression: "searchGroup"
                                }
                              }),
                              _c(
                                "v-card",
                                {
                                  staticClass: "mt-0 elevation-0",
                                  staticStyle: {
                                    "overflow-y": "scroll",
                                    "max-height": "400px"
                                  }
                                },
                                [
                                  _c("v-treeview", {
                                    ref: "tree",
                                    attrs: {
                                      items: _vm.groups,
                                      "load-children": _vm.fetchItemsGroup,
                                      activatable: "",
                                      "item-children": "children",
                                      color: "warning",
                                      "open-on-click": false,
                                      transition: "",
                                      "item-key": "key",
                                      "return-object": "",
                                      dense: "",
                                      search: _vm.searchGroup
                                    },
                                    on: {
                                      "update:active": function($event) {
                                        return _vm.selectGroup($event[0])
                                      }
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function(ref) {
                                          var item = ref.item
                                          return [
                                            _c(
                                              "draggable",
                                              {
                                                on: {
                                                  start: function($event) {
                                                    return _vm.start(
                                                      $event,
                                                      item
                                                    )
                                                  },
                                                  end: function($event) {
                                                    return _vm.end($event)
                                                  }
                                                }
                                              },
                                              [
                                                item.level == 0
                                                  ? _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticStyle: {
                                                                                cursor:
                                                                                  "pointer"
                                                                              }
                                                                            },
                                                                            "span",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.name
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _c("span", [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.course
                                                                      .name
                                                                  )
                                                                )
                                                              ]),
                                                              item.time_course
                                                                ? _c("small", [
                                                                    _c("br"),
                                                                    _vm._v(
                                                                      "Período: " +
                                                                        _vm._s(
                                                                          item.time_course
                                                                        )
                                                                    )
                                                                  ])
                                                                : _vm._e()
                                                            ])
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : item.level == 1
                                                  ? _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticStyle: {
                                                                                cursor:
                                                                                  "pointer"
                                                                              }
                                                                            },
                                                                            "span",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.name
                                                                                )
                                                                              )
                                                                            ]
                                                                          ),
                                                                          item.category &&
                                                                          !item
                                                                            .category
                                                                            .default
                                                                            ? _c(
                                                                                "span",
                                                                                [
                                                                                  _c(
                                                                                    "br"
                                                                                  ),
                                                                                  _c(
                                                                                    "v-chip",
                                                                                    {
                                                                                      staticClass:
                                                                                        "ml-1",
                                                                                      attrs: {
                                                                                        color:
                                                                                          "purple",
                                                                                        label:
                                                                                          "",
                                                                                        "x-small":
                                                                                          "",
                                                                                        outlined:
                                                                                          ""
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            item
                                                                                              .category
                                                                                              .initials
                                                                                          ) +
                                                                                          " "
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  item.specificity
                                                                                    ? _c(
                                                                                        "small",
                                                                                        [
                                                                                          _vm._v(
                                                                                            " [" +
                                                                                              _vm._s(
                                                                                                item
                                                                                                  .specificity
                                                                                                  .name
                                                                                              ) +
                                                                                              "] "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e()
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _c(
                                                                                "span",
                                                                                [
                                                                                  _c(
                                                                                    "br"
                                                                                  ),
                                                                                  item.specificity
                                                                                    ? _c(
                                                                                        "small",
                                                                                        [
                                                                                          _vm._v(
                                                                                            " [" +
                                                                                              _vm._s(
                                                                                                item
                                                                                                  .specificity
                                                                                                  .name
                                                                                              ) +
                                                                                              "] "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              ),
                                                                          item.join
                                                                            ? _c(
                                                                                "span",
                                                                                [
                                                                                  _c(
                                                                                    "v-chip",
                                                                                    {
                                                                                      staticClass:
                                                                                        "ml-1",
                                                                                      attrs: {
                                                                                        color:
                                                                                          "indigo",
                                                                                        label:
                                                                                          "",
                                                                                        "x-small":
                                                                                          "",
                                                                                        outlined:
                                                                                          ""
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          $event.preventDefault()
                                                                                          $event.stopPropagation()
                                                                                          return _vm.loadJoin(
                                                                                            item
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        {
                                                                                          attrs: {
                                                                                            "x-small":
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " mdi-call-split "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e()
                                                                        ]
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _c("span", [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.name
                                                                  )
                                                                )
                                                              ]),
                                                              item.subGroup
                                                                ? _c("span", [
                                                                    _c("br"),
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item
                                                                          .subGroup
                                                                          .name
                                                                      )
                                                                    )
                                                                  ])
                                                                : _vm._e(),
                                                              item.teacher
                                                                ? _c("span", [
                                                                    _c("br"),
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item
                                                                          .teacher
                                                                          .name
                                                                      ) + " "
                                                                    )
                                                                  ])
                                                                : _vm._e(),
                                                              _c("br"),
                                                              _vm._v(
                                                                " Alunos: " +
                                                                  _vm._s(
                                                                    item.students_join
                                                                  ) +
                                                                  " "
                                                              ),
                                                              item.students_join_forecast
                                                                ? _c("span", [
                                                                    _c("br"),
                                                                    _vm._v(
                                                                      "Previsão Alunos: " +
                                                                        _vm._s(
                                                                          item.students_join_forecast
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ])
                                                                : _vm._e()
                                                            ])
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          cursor: "pointer"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        )
                                                      ]
                                                    )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ]),
                                    model: {
                                      value: _vm.selection,
                                      callback: function($$v) {
                                        _vm.selection = $$v
                                      },
                                      expression: "selection"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4" } },
                [
                  _c("sys-ambience-hours-link", {
                    attrs: {
                      renderComponent: _vm.renderComponent,
                      loading: _vm.loadingSaving,
                      group: _vm.group,
                      ambience: _vm.ambience
                    },
                    on: {
                      remove: _vm.removeAmbience,
                      drop: function($event) {
                        return _vm.drop()
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4" } },
                [
                  _c(
                    "v-expansion-panels",
                    {
                      attrs: { height: "100px", focusable: "" },
                      model: {
                        value: _vm.panelAmbience,
                        callback: function($$v) {
                          _vm.panelAmbience = $$v
                        },
                        expression: "panelAmbience"
                      }
                    },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              attrs: { "disable-icon-rotate": "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "actions",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            color: "primary"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              $event.stopPropagation()
                                                              _vm.autoSearch = false
                                                              _vm.showFormSearch = true
                                                            }
                                                          }
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { dark: "" } },
                                                        [_vm._v("search")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Pesquisa Avançada")
                                          ])
                                        ]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass: "ma-1",
                                                          attrs: {
                                                            disabled: !_vm.enableFilterAmbience,
                                                            icon: "",
                                                            small: ""
                                                          }
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "blue"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              $event.preventDefault()
                                                              return _vm.clearFilterAmbience()
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " mdi-filter-remove-outline "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(" Limpar Filtros ")
                                          ])
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            },
                            [
                              _c(
                                "strong",
                                { staticClass: "primary--text" },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "primary" } },
                                    [_vm._v(" mdi-filter-outline ")]
                                  ),
                                  _vm._v(" Filtro de Ambientes ")
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _vm._v("´ "),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "12" } },
                                    [
                                      _c("sys-select-campus", {
                                        attrs: {
                                          model: _vm.campusesAmbiences,
                                          label: "Campus do Ambiente*",
                                          "auto-complete": "",
                                          "filter-relation": "ambiences"
                                        },
                                        on: {
                                          "update:model": function($event) {
                                            _vm.campusesAmbiences = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "12" } },
                                    [
                                      _c("sys-select-ambience-type", {
                                        attrs: {
                                          model: _vm.types,
                                          label: "Tipo de Ambiente*",
                                          multiple: "",
                                          selectAll: "",
                                          "only-with-link": "",
                                          "campus-id": _vm.campusesAmbiences
                                        },
                                        on: {
                                          "update:model": function($event) {
                                            _vm.types = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("sys-select-floor", {
                                        attrs: {
                                          model: _vm.floors,
                                          label: "Andar*",
                                          multiple: "",
                                          selectAll: "",
                                          "campus-id": _vm.campusesAmbiences
                                        },
                                        on: {
                                          "update:model": function($event) {
                                            _vm.floors = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("sys-select-block", {
                                        attrs: {
                                          model: _vm.blocks,
                                          label: "Bloco",
                                          multiple: "",
                                          selectAll: "",
                                          "campus-id": _vm.campusesAmbiences
                                        },
                                        on: {
                                          "update:model": function($event) {
                                            _vm.blocks = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: false,
                                      expression: "false"
                                    }
                                  ]
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "12" } },
                                    [
                                      _c("sys-select-category-ambience", {
                                        attrs: {
                                          model: _vm.categories,
                                          label: "Categoria",
                                          multiple: "",
                                          selectAll: ""
                                        },
                                        on: {
                                          "update:model": function($event) {
                                            _vm.categories = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            disabled:
                                              !_vm.enableFilterAmbience ||
                                              _vm.loadingFilterAmbiences,
                                            loading: _vm.loadingFilterAmbiences
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.filterAmbiences()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v("Filtrar"),
                                          _c(
                                            "v-icon",
                                            { attrs: { right: "", dark: "" } },
                                            [_vm._v("mdi-filter-menu-outline")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              attrs: { "disable-icon-rotate": "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "actions",
                                  fn: function() {
                                    return [
                                      _vm.ambience
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  color: "error"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    $event.stopPropagation()
                                                                    _vm.ambience = null
                                                                  }
                                                                }
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  dark: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-close-circle-multiple-outline"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                3803663744
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Limpar Seleção")
                                              ])
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            },
                            [
                              _c(
                                "strong",
                                { staticClass: "primary--text" },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "primary" } },
                                    [_vm._v(" mdi-home ")]
                                  ),
                                  _vm._v(" Ambientes ")
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c("v-text-field", {
                                staticClass: "ma-0 pa-0",
                                attrs: {
                                  "prepend-inner-icon": "search",
                                  "x-small": "",
                                  placeholder: "Pesquisar",
                                  rounded: "",
                                  "single-line": "",
                                  clearable: "",
                                  autofocus: ""
                                },
                                model: {
                                  value: _vm.searchAmbience,
                                  callback: function($$v) {
                                    _vm.searchAmbience = $$v
                                  },
                                  expression: "searchAmbience"
                                }
                              }),
                              _c(
                                "emc-util-scroll",
                                { attrs: { maxHeight: "350px" } },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item-group",
                                        { on: { change: _vm.selectAmbience } },
                                        _vm._l(_vm.ambiencesFiltered, function(
                                          item,
                                          i
                                        ) {
                                          return _c(
                                            "v-list-item",
                                            { key: i },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            ""
                                                                        },
                                                                        domProps: {
                                                                          textContent: _vm._s(
                                                                            item.name
                                                                          )
                                                                        }
                                                                      },
                                                                      "v-list-item-title",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  )
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("emc-alert-snackbar", {
        attrs: { show: _vm.error, errors: _vm.errors, color: "error" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }