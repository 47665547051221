<template>
    <div>
        <EmcChartLoading v-if="loading" />
        <v-card v-show="!loading" class="pa-2">
            <canvas v-if="renderComponent" :id="name" :width="width" :height="height"></canvas>
            <v-card-actions>

                <ChartInfoBudget title="Indicador consolidado de sala de aula e outras atividades" />
                
                <span v-if="process">
                    <small v-if="process.updated_last">
                        Atualização: {{ process.updated_last | dateTime }}
                    </small>
                    <small><strong v-if="process.budget_process_status_id <= 3">({{ process.status.name }}...)</strong></small>
                </span>
                
                <v-spacer></v-spacer>

                <v-btn-toggle
                    v-model="valueType"
                    borderless
                    color="deep-purple accent-3"
                    class="mr-1"
                >
                    <v-btn  @click.prevent.stop="forceRerender()" x-small value="1">
                        <span class="hidden-sm-and-down">H/A</span>

                        <v-icon x-small right>
                            mdi-calendar-clock
                        </v-icon>
                    </v-btn>

                    <v-btn  @click.prevent.stop="forceRerender()" x-small value="2">
                        <span class="hidden-sm-and-down">R$</span>
                        <v-icon x-small right>
                            mdi-currency-brl
                        </v-icon>
                    </v-btn>

                </v-btn-toggle>

                <emc-button-icon v-if="user.is_admin" :loading="loadingGenerate" icon="mdi-flash" text="Processar" color="amber" @click.prevent.stop="refresh()" />
                <emc-button-icon :loading="loadingRefresh" icon="mdi-refresh-circle" text="Atualizar" color="success" @click.prevent.stop="forceRerender()" />

            </v-card-actions>
        </v-card>

    </div>
</template>

<script>

import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { mapActions, mapState } from 'vuex'
import ChartInfoBudget from './components/ChartInfoBudget.vue'

export default {
    name: 'EmcChartBudgetGeneral',
    data () {
        return {
            loading: true,
            loadingGenerate: false,
            loadingRefresh: false,
            renderComponent: true,
            valueType: '1',
            width: '400',
            height: '240',
            name: 'BudgetGeneral',
            chartData: [],
            process: null,
            title: '',
            chartOptions: {
                responsive: true,
                    plugins: {
                    legend: {
                        position: 'bottom',
                    },
                    title: {
                        display: true,
                        text: 'Chart.js Bar Chart'
                    },
                    
                },
                aspectRatio: 5 / 3,
                // layout: {
                //     padding: {
                //         top: 24,
                //         right: 16,
                //         bottom: 0,
                //         left: 8
                //     }
                // },
                elements: {
                    line: {
                        fill: false
                    },
                    point: {
                        hoverRadius: 7,
                        radius: 5
                    }
                },
                scales: {
                        x: {
                            stacked: false
                        },
                        y: {
                            stacked: false
                    }        
                }
            }
        }
    },
    components: {
        ChartInfoBudget,
    },
    methods: {
        ...mapActions('chart', ['ActionChartBudgetGeneral']),
        ...mapActions('budget', ['ActionBudgetProcessRefresh']),
        
        async getData(loadingRefresh = false) {
            
            this.chartData = [];
            this.loadingRefresh = loadingRefresh;
            
            let payload = {
                filter: {
                    value_type_id: this.valueType
                }
            };
            
            await this.ActionChartBudgetGeneral(payload)
                .then((r) => {
                    this.chartData = r.data;
                    this.process = r.data.process;
                    this.chartOptions.plugins.title.text = r.data['title'];
                }).finally(() => {
                    this.loadingRefresh = false;
                });
            
            this.getChart();
            
        },

        refresh() {
            
            this.loadingGenerate = true;
            let payload = {
                with: 'status'
            }
            
            this.ActionBudgetProcessRefresh(payload)
                .then((r) => {
                    this.process = r.data;
                })
                .catch((error) =>{
                  
                })
                .finally(() => {
                    this.loadingGenerate = false;
                });
           
        },

        forceRerender () {
            
            this.renderComponent = false;
    
            this.$nextTick(() => {
                this.renderComponent = true;
                this.getData(true);
            });
    
          },

        getChart() {

            if(!document.getElementById(this.name)) {
                return;
            }
            
            Chart.register(ChartDataLabels);

            // Change default options for ALL charts
            Chart.defaults.set('plugins.datalabels', {
                color: '#999999',
                anchor: 'start',
                align: 'end'
            });

            var speedCanvas = document.getElementById(this.name)
            new Chart(speedCanvas, {
                    type: 'bar',
                    data: this.chartData,
                    options: this.chartOptions
                });

            this.loading = false
        }
    },
    created() {
        this.getData();
    },
    computed: {
        ...mapState('auth', ['user']),
    }

    
}
</script>