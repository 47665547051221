var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("emc-info-help", {
    attrs: { title: _vm.title },
    scopedSlots: _vm._u([
      {
        key: "text",
        fn: function() {
          return [
            _c("strong", [_vm._v("Orçado")]),
            _c("br"),
            _vm._v(" Valor Orçado "),
            _c("br"),
            _c("strong", [_vm._v("Previsto")]),
            _c("br"),
            _vm._v(" Valor Previsto na Oferta "),
            _c("br"),
            _c("strong", [_vm._v("Empenhado")]),
            _c("br"),
            _vm._v(" Valor Empenhado com Professor "),
            _c("br"),
            _c("strong", [_vm._v("Realizado")]),
            _c("br"),
            _vm._v(" Valor Processado na Folha de Pagto ")
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }