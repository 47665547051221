var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: { icon: _vm.$route.meta.icon, title: _vm.$route.meta.title },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return [
              _vm.$vuetify.breakpoint.name == "xs" ||
              _vm.$vuetify.breakpoint.name == "sm"
                ? _c(
                    "span",
                    [
                      _c("emc-info-help", {
                        attrs: {
                          title: "Informações",
                          text:
                            "Os Log's de Integrações ficarão disponíveis por 18 meses!"
                        }
                      })
                    ],
                    1
                  )
                : _c(
                    "span",
                    [
                      _c("v-icon", { attrs: { color: "primary", small: "" } }, [
                        _vm._v("mdi-information-outline")
                      ]),
                      _c("strong", { staticClass: "subtitle-1 ml-1  " }, [
                        _vm._v(
                          "Os Log's de Integrações ficarão disponíveis por 18 meses!"
                        )
                      ])
                    ],
                    1
                  )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("EmcLoaderProgress", {
        attrs: { show: _vm.loading },
        on: {
          "update:show": function($event) {
            _vm.loading = $event
          }
        }
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "9" } },
            [
              _c(
                "emc-util-scroll",
                {
                  staticClass: "ma-2",
                  attrs: { maxHeight: "500px", "overflow-x": "hidden" }
                },
                [
                  _c(
                    "v-row",
                    _vm._l(_vm.integrations, function(item, i) {
                      return _c(
                        "v-col",
                        {
                          key: i,
                          attrs: { id: "item-" + i, cols: "12", sm: "4" }
                        },
                        [
                          [
                            _c(
                              "v-sheet",
                              {
                                attrs: {
                                  outlined: "",
                                  color: item.is_running
                                    ? "orange darken-1"
                                    : "grey lighten-1",
                                  rounded: ""
                                }
                              },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "mx-auto",
                                    attrs: { elevation: "0" }
                                  },
                                  [
                                    _c(
                                      "v-list-item",
                                      { attrs: { "three-line": "" } },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-overline mb-4"
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color:
                                                        _vm.direction[
                                                          item
                                                            .direction_integration
                                                        ].color
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.direction[
                                                          item
                                                            .direction_integration
                                                        ].icon
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " + _vm._s(item.name) + " "
                                                )
                                              ],
                                              1
                                            ),
                                            item.direction_integration == "U"
                                              ? _c(
                                                  "v-list-item-title",
                                                  {
                                                    staticClass: "text-h4 mb-1",
                                                    staticStyle: {
                                                      cursor: "pointer"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.showIntegrationProcessItems(
                                                          item.id,
                                                          item.name
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm.renderComponent
                                                      ? _c(
                                                          "span",
                                                          [
                                                            _vm.itemIndex ==
                                                              item.id ||
                                                            !_vm.indexIds.includes(
                                                              item.id
                                                            )
                                                              ? _c(
                                                                  "v-progress-circular",
                                                                  {
                                                                    attrs: {
                                                                      width: 2,
                                                                      color:
                                                                        "success",
                                                                      indeterminate:
                                                                        ""
                                                                    }
                                                                  }
                                                                )
                                                              : _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.total_items
                                                                    ) + " "
                                                                  )
                                                                ]),
                                                            _vm._v(" "),
                                                            _c(
                                                              "small",
                                                              {
                                                                staticClass:
                                                                  "caption"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "itens pendentes"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c("v-list-item-subtitle", [
                                              _vm._v(_vm._s(item.description))
                                            ])
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-icon",
                                          {
                                            attrs: {
                                              tile: "",
                                              size: "80",
                                              color: "grey"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "purple",
                                                  "x-large": ""
                                                }
                                              },
                                              [_vm._v(_vm._s(item.icon))]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-card-actions",
                                      [
                                        _vm.isAnima()
                                          ? _c(
                                              "v-container",
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    ![43, 53].includes(item.id)
                                                      ? _c(
                                                          "v-col",
                                                          [
                                                            _c(
                                                              "sys-select-brand",
                                                              {
                                                                attrs: {
                                                                  model:
                                                                    _vm.brand_id,
                                                                  label:
                                                                    "Marca",
                                                                  clearable: "",
                                                                  dense: ""
                                                                },
                                                                on: {
                                                                  "update:model": function(
                                                                    $event
                                                                  ) {
                                                                    _vm.brand_id = $event
                                                                  }
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    [47, 49, 44].includes(
                                                      item.id
                                                    )
                                                      ? _c(
                                                          "v-col",
                                                          [
                                                            _c(
                                                              "sys-select-category",
                                                              {
                                                                attrs: {
                                                                  model:
                                                                    _vm.category_id,
                                                                  label:
                                                                    "Categoria Curso",
                                                                  clearable: "",
                                                                  dense: ""
                                                                },
                                                                on: {
                                                                  "update:model": function(
                                                                    $event
                                                                  ) {
                                                                    _vm.category_id = $event
                                                                  }
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    item.id == 43
                                                      ? _c(
                                                          "v-col",
                                                          [
                                                            _c(
                                                              "sys-select-join-category",
                                                              {
                                                                attrs: {
                                                                  model:
                                                                    _vm.join_category_id,
                                                                  label:
                                                                    "Tipo de Junção",
                                                                  clearable: "",
                                                                  dense: ""
                                                                },
                                                                on: {
                                                                  "update:model": function(
                                                                    $event
                                                                  ) {
                                                                    _vm.join_category_id = $event
                                                                  }
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                ),
                                                [47, 49, 44, 43, 53].includes(
                                                  item.id
                                                )
                                                  ? _c(
                                                      "v-row",
                                                      [
                                                        _vm.isAnima()
                                                          ? _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  sm: "4"
                                                                }
                                                              },
                                                              [
                                                                _c("v-select", {
                                                                  attrs: {
                                                                    "item-value":
                                                                      "id",
                                                                    "item-text":
                                                                      "name",
                                                                    items:
                                                                      _vm.actionItems,
                                                                    label:
                                                                      "Ação",
                                                                    multiple:
                                                                      "",
                                                                    clearable:
                                                                      ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.actions,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.actions = $$v
                                                                    },
                                                                    expression:
                                                                      "actions"
                                                                  }
                                                                })
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              sm: "8"
                                                            }
                                                          },
                                                          [
                                                            _c("s-switch", {
                                                              attrs: {
                                                                inset: "",
                                                                disabled: "",
                                                                label:
                                                                  "Múltiplos Lotes"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.multiLot,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.multiLot = $$v
                                                                },
                                                                expression:
                                                                  "multiLot"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c("v-spacer")
                                      ],
                                      1
                                    ),
                                    _c("v-divider", {
                                      class: item.is_running
                                        ? "orange darken-1"
                                        : "grey lighten-1"
                                    }),
                                    _c(
                                      "v-card-actions",
                                      [
                                        _c("emc-button-icon", {
                                          attrs: {
                                            color: "green",
                                            text: "Integrar",
                                            icon: "mdi-play-circle-outline",
                                            loading:
                                              (_vm.loadingRun &&
                                                item.id == _vm.itemSelected) ||
                                              item.is_running,
                                            disabled: item.is_auto
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.questionIntegration(
                                                item
                                              )
                                            }
                                          }
                                        }),
                                        item.is_auto
                                          ? _c(
                                              "small",
                                              { staticClass: "ml-1" },
                                              [_vm._v("Automático")]
                                            )
                                          : _vm._e(),
                                        _c("v-spacer"),
                                        _c("emc-button-icon", {
                                          attrs: {
                                            icon: "mdi-refresh",
                                            text: "Atualizar",
                                            color: "success",
                                            loading:
                                              _vm.loadingRefresh &&
                                              _vm.itemIndex == item.id
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.getData(item.id)
                                            }
                                          }
                                        }),
                                        _c(
                                          "span",
                                          [
                                            item.id == 47 || item.id == 2
                                              ? _c("emc-excel-import-icon", {
                                                  attrs: {
                                                    "show-clear-items": "",
                                                    param:
                                                      "integration_class_schedules_week"
                                                  }
                                                })
                                              : _vm._e(),
                                            item.id == 49
                                              ? _c("emc-excel-import-icon", {
                                                  attrs: {
                                                    "show-clear-items": "",
                                                    param:
                                                      "integration_class_schedules_date"
                                                  }
                                                })
                                              : _vm._e(),
                                            item.id == 44 || item.id == 11
                                              ? _c("emc-excel-import-icon", {
                                                  attrs: {
                                                    "show-clear-items": "",
                                                    param:
                                                      "integration_attributions"
                                                  }
                                                })
                                              : _vm._e(),
                                            item.id == 50
                                              ? _c("emc-excel-import-icon", {
                                                  attrs: {
                                                    "show-clear-items": "",
                                                    param:
                                                      "integration_ambience"
                                                  }
                                                })
                                              : _vm._e(),
                                            item.id == 13
                                              ? _c("emc-excel-import-icon", {
                                                  attrs: {
                                                    "show-clear-items": "",
                                                    param:
                                                      "integration_ambience_link"
                                                  }
                                                })
                                              : _vm._e(),
                                            item.id == 46
                                              ? _c("emc-excel-import-icon", {
                                                  attrs: {
                                                    "show-clear-items": "",
                                                    param:
                                                      "integration_ambience_link_week"
                                                  }
                                                })
                                              : _vm._e(),
                                            item.id == 51
                                              ? _c("emc-excel-import-icon", {
                                                  attrs: {
                                                    "show-clear-items": "",
                                                    param:
                                                      "integration_ambience_link_date"
                                                  }
                                                })
                                              : _vm._e(),
                                            item.id == 3 || item.id == 43
                                              ? _c("emc-excel-import-icon", {
                                                  attrs: {
                                                    "show-clear-items": "",
                                                    param: "integration_join"
                                                  }
                                                })
                                              : _vm._e(),
                                            item.id == 53 || item.id == 64
                                              ? _c("emc-excel-import-icon", {
                                                  attrs: {
                                                    "show-clear-items": "",
                                                    param:
                                                      "integration_activity_item"
                                                  }
                                                })
                                              : _vm._e(),
                                            item.id == 1
                                              ? _c("emc-excel-import-icon", {
                                                  attrs: {
                                                    "show-clear-items": "",
                                                    param: "integration_grids"
                                                  }
                                                })
                                              : _vm._e(),
                                            item.id == 54
                                              ? _c("emc-excel-import-icon", {
                                                  attrs: {
                                                    "show-clear-items": "",
                                                    param:
                                                      "integration_time_table_grids"
                                                  }
                                                })
                                              : _vm._e(),
                                            item.id == 8
                                              ? _c("emc-excel-import-icon", {
                                                  attrs: {
                                                    "show-clear-items": "",
                                                    param: "integration_groups",
                                                    toolTip: "Importar Turmas"
                                                  }
                                                })
                                              : _vm._e(),
                                            item.id == 8
                                              ? _c("emc-excel-import-icon", {
                                                  attrs: {
                                                    "show-clear-items": "",
                                                    param:
                                                      "integration_sub_groups",
                                                    toolTip:
                                                      "Importar SubTurmas",
                                                    color: "primary"
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                icon: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.stopPropagation()
                                                                  _vm.processNumber = null
                                                                  _vm.showDetailsIntegration(
                                                                    item,
                                                                    1
                                                                  )
                                                                  _vm.icon =
                                                                    item.icon
                                                                  _vm.title =
                                                                    "DETALHES: " +
                                                                    item.name
                                                                }
                                                              }
                                                            },
                                                            "v-btn",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-card-text-outline"
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(" Mais informações ")
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        ],
                        2
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "3" } },
            [
              _c(
                "v-card",
                { staticClass: "mx-auto", attrs: { elevation: "6" } },
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-title",
                        [
                          _c("span", { staticClass: "text-overline" }, [
                            _vm._v(" Integrações ")
                          ]),
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                small: "",
                                color: _vm.direction["U"].color
                              }
                            },
                            [_vm._v(_vm._s(_vm.direction["U"].icon))]
                          ),
                          _c("small", [_vm._v(" UP")]),
                          _c(
                            "v-icon",
                            {
                              staticClass: "ml-1",
                              attrs: {
                                small: "",
                                color: _vm.direction["D"].color
                              }
                            },
                            [_vm._v(_vm._s(_vm.direction["D"].icon))]
                          ),
                          _c("small", [_vm._v(" DOWN ")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-list",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "emc-util-scroll",
                            { attrs: { maxHeight: "410px" } },
                            _vm._l(_vm.integrations, function(item, i) {
                              return _c(
                                "v-list-item",
                                { key: i },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          class: [
                                            "text-caption",
                                            {
                                              "orange--text darken-1":
                                                item.is_running
                                            }
                                          ]
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                color:
                                                  _vm.direction[
                                                    item.direction_integration
                                                  ].color
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.direction[
                                                    item.direction_integration
                                                  ].icon
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              class: {
                                                "mdi-spin": item.is_running
                                              },
                                              attrs: {
                                                color: item.is_running
                                                  ? "orange darken-1"
                                                  : "",
                                                small: ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  item.is_running
                                                    ? "mdi-loading"
                                                    : item.icon
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "ml-1",
                                              staticStyle: {
                                                "text-decoration": "none",
                                                color: "black"
                                              },
                                              attrs: { href: "#item-" + i }
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("emc-modal-form", {
        attrs: {
          show: _vm.showIntegrationPencences,
          title: _vm.titleIntegrationProcessItems
        },
        on: {
          "update:show": function($event) {
            _vm.showIntegrationPencences = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "tools",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: _vm.filterDisabled ? "primary" : "secundary",
                      small: ""
                    },
                    on: {
                      click: function($event) {
                        _vm.filterDisabled = !_vm.filterDisabled
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.filterDisabled ? "Ocultar" : "Exibir") +
                        " itens desmarcados"
                    )
                  ]
                )
              ]
            },
            proxy: true
          },
          {
            key: "form",
            fn: function() {
              return [
                _c(
                  "v-card",
                  { attrs: { elevation: "0" } },
                  [
                    _c(
                      "v-card-title",
                      [
                        _vm._v(" Relação de itens "),
                        _c("v-spacer"),
                        _c("v-text-field", {
                          attrs: {
                            "append-icon": "mdi-magnify",
                            label: "Pesquisar...",
                            "single-line": "",
                            "hide-details": ""
                          },
                          model: {
                            value: _vm.searchIntegrationProcessItem,
                            callback: function($$v) {
                              _vm.searchIntegrationProcessItem = $$v
                            },
                            expression: "searchIntegrationProcessItem"
                          }
                        })
                      ],
                      1
                    ),
                    _c("v-data-table", {
                      staticClass: "elevation-1",
                      attrs: {
                        headers: _vm.headersIntegration,
                        items: _vm.filteredIntegrationProcessItems,
                        search: _vm.searchIntegrationProcessItem,
                        "item-key": "id",
                        "show-select": "",
                        "items-per-page": 5,
                        loading: _vm.loadingIntegrationProcessItems,
                        "loading-text": "Carregando..."
                      },
                      model: {
                        value: _vm.selectedIntegration,
                        callback: function($$v) {
                          _vm.selectedIntegration = $$v
                        },
                        expression: "selectedIntegration"
                      }
                    }),
                    _c("div", { staticClass: "float-left mt-3" }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.filteredIntegrationProcessItems.length)
                        )
                      ]),
                      _vm._v(" itens selecionados para integração. ")
                    ]),
                    _c(
                      "div",
                      { staticClass: "float-right mt-3" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "primary",
                              disabled:
                                _vm.loadingSavingIntegrationProcessItems,
                              loading: _vm.loadingSavingIntegrationProcessItems
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.saveIntegrationProcessItems()
                              }
                            }
                          },
                          [
                            _vm._v(" Salvar "),
                            _c("v-icon", { attrs: { right: "", dark: "" } }, [
                              _vm._v("mdi-content-save")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-modal-form", {
        attrs: { show: _vm.show, title: _vm.title, icon: _vm.icon },
        on: {
          "update:show": function($event) {
            _vm.show = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "tools",
            fn: function() {
              return [
                _c(
                  "v-responsive",
                  { staticClass: "mx-auto", attrs: { "max-width": "200" } },
                  [
                    _c("v-text-field", {
                      staticClass: "mt-4",
                      attrs: {
                        label: "Núm. Processo",
                        "append-icon": "search",
                        dense: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.showDetailsIntegration(
                            _vm.selectedItemProcess,
                            1
                          )
                        }
                      },
                      model: {
                        value: _vm.processNumber,
                        callback: function($$v) {
                          _vm.processNumber = $$v
                        },
                        expression: "processNumber"
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "form",
            fn: function() {
              return [
                _c("EmcLoaderProgress", {
                  attrs: { show: _vm.loadingShow },
                  on: {
                    "update:show": function($event) {
                      _vm.loadingShow = $event
                    }
                  }
                }),
                _c(
                  "v-expansion-panels",
                  {
                    model: {
                      value: _vm.panel,
                      callback: function($$v) {
                        _vm.panel = $$v
                      },
                      expression: "panel"
                    }
                  },
                  [
                    _vm._l(_vm.process, function(item, i) {
                      return _c(
                        "v-expansion-panel",
                        { key: i },
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              attrs: { "disable-icon-rotate": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "actions",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-chip",
                                          {
                                            attrs: {
                                              color: item.status.color,
                                              label: "",
                                              outlined: ""
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  small: "",
                                                  left: "",
                                                  color: item.status.color
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.status.icon) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(item.status.name) +
                                                " "
                                            ),
                                            _c("emc-excel-export-icon", {
                                              attrs: {
                                                "report-id": "71",
                                                params: "id=" + item.id
                                              }
                                            }),
                                            _vm.isAnima() && item.url_file_name
                                              ? _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          item.import_status_id <=
                                                                          2,
                                                                        small:
                                                                          "",
                                                                        icon:
                                                                          "",
                                                                        href:
                                                                          item.url_file_name +
                                                                          "?token=" +
                                                                          _vm.token,
                                                                        target:
                                                                          "_blank"
                                                                      }
                                                                    },
                                                                    "v-btn",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                        color:
                                                                          "cyan"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-code-json"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(" Download Json ")
                                                    ])
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.isAnima() &&
                                            ![50, 47, 49, 46, 51].includes(
                                              item.integration_item_id
                                            ) &&
                                            (item.integration_process_status_id ==
                                              1 ||
                                              item.integration_process_status_id ==
                                                2)
                                              ? _c(
                                                  "v-tooltip",
                                                  {
                                                    staticClass: "ml-2",
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                        color:
                                                                          "success darken-1",
                                                                        icon:
                                                                          "",
                                                                        loading:
                                                                          _vm.loadingResponse &&
                                                                          _vm
                                                                            .selectedItem
                                                                            .id ==
                                                                            item.id
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          $event.stopPropagation()
                                                                          return _vm.getResponse(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    "v-btn",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "mdi-refresh-circle"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        " Processar Retorno "
                                                      )
                                                    ])
                                                  ]
                                                )
                                              : _vm._e(),
                                            item.exception
                                              ? _c("emc-info-help", {
                                                  attrs: {
                                                    "show-copy": "",
                                                    icon:
                                                      "mdi-alert-rhombus-outline",
                                                    title: "Detalhes do erro",
                                                    text: item.exception
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c("span", [
                                _vm._v(" Processo: " + _vm._s(item.id) + " "),
                                item.auto || item.user_creator
                                  ? _c("span", [
                                      _c("br"),
                                      item.auto
                                        ? _c(
                                            "small",
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "success",
                                                    small: ""
                                                  }
                                                },
                                                [_vm._v("mdi-av-timer")]
                                              ),
                                              _vm._v(" Automático ")
                                            ],
                                            1
                                          )
                                        : item.user_creator
                                        ? _c(
                                            "small",
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "mdi-account-tie-outline"
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.user_creator.name
                                                  ) +
                                                  " "
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ])
                                  : _vm._e(),
                                _c("br"),
                                _c("small", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("dateTime")(item.created_at)
                                    ) +
                                      " (" +
                                      _vm._s(item.created_at_human) +
                                      ")"
                                  )
                                ])
                              ])
                            ]
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              [
                                item.message_response || item.exception
                                  ? _c(
                                      "v-alert",
                                      {
                                        attrs: {
                                          dense: "",
                                          outlined: "",
                                          type: "error"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            item.message_response
                                              ? item.message_response
                                              : item.exception
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                !_vm.isAnima()
                                  ? _c(
                                      "div",
                                      { staticClass: "text-center" },
                                      [
                                        _c(
                                          "v-chip",
                                          {
                                            staticClass: "ma-2",
                                            attrs: {
                                              color: "success",
                                              outlined: ""
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " Total com sucesso: " +
                                                _vm._s(item.success_amount) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-chip",
                                          {
                                            staticClass: "ma-2",
                                            attrs: {
                                              color: "error",
                                              outlined: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                _vm.filterFailed = !_vm.filterFailed
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " Total com erro: " +
                                                _vm._s(item.errors_amount) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-chip",
                                          {
                                            staticClass: "ma-2",
                                            attrs: {
                                              color: "warning",
                                              outlined: ""
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " Total restante: " +
                                                _vm._s(
                                                  item.items_amount -
                                                    (item.success_amount +
                                                      item.errors_amount)
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-card",
                                  [
                                    _c(
                                      "v-card-title",
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            "append-icon": "mdi-magnify",
                                            label: "Pesquisar",
                                            "single-line": "",
                                            "hide-details": ""
                                          },
                                          model: {
                                            value: _vm.search,
                                            callback: function($$v) {
                                              _vm.search = $$v
                                            },
                                            expression: "search"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c("v-data-table", {
                                      staticClass: "elevation-1",
                                      attrs: {
                                        loading: _vm.loadingItems,
                                        search: _vm.search,
                                        headers: _vm.headers,
                                        items: item.items
                                          ? item.items.filter(function(f) {
                                              if (!_vm.filterFailed) {
                                                return f.success == 0
                                              } else {
                                                return true
                                              }
                                            })
                                          : [],
                                        "items-per-page": 10
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    _c("v-pagination", {
                      staticClass: "my-4",
                      attrs: { length: _vm.totalPages },
                      on: {
                        input: function($event) {
                          return _vm.showDetailsIntegration(
                            _vm.selectedItemProcess,
                            _vm.page
                          )
                        }
                      },
                      model: {
                        value: _vm.page,
                        callback: function($$v) {
                          _vm.page = $$v
                        },
                        expression: "page"
                      }
                    })
                  ],
                  2
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-modal", {
        attrs: {
          show: _vm.showAlert,
          icon: _vm.error.icon,
          title: _vm.error.title,
          color: _vm.error ? "error" : "success",
          colorIcon: "white",
          classTitle: "white--text title-1",
          textCancel: "OK"
        },
        on: {
          "update:show": function($event) {
            _vm.showAlert = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "text",
            fn: function() {
              return [
                _c("span", { staticClass: "subtitle-2 text-center" }, [
                  _vm._v(_vm._s(_vm.error.message || _vm.message))
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-modal", {
        attrs: {
          text: "Confirma a integração dos itens?",
          show: _vm.showQuestionIntegration
        },
        on: {
          "update:show": function($event) {
            _vm.showQuestionIntegration = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "green" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.runIntegration(_vm.selectedItemIntegration)
                      }
                    }
                  },
                  [
                    _vm._v("SIM - Integrar "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-play-circle-outline")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }