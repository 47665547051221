import { default as sub_group } from './sub_group/routes'
import { default as identifier } from './identifier/routes'
import { default as vacancy_control } from './vacancy_control/routes'

export default [
    ...sub_group,
    ...identifier,
    ...vacancy_control,
    {
        path: '/groups',
        name: 'groups',
        component: () => import ('./pages/Index')
    },
]