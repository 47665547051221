<template>
    <v-container>


        <v-row class="ma-0" v-if="teacher && teacher.realInstitution && teacher.realInstitution.text_availability && teacher.realInstitution.text_availability != ''">
            <v-col>
                <v-alert border="top"
                        colored-border
                        type="info"
                        elevation="2"
                >
                {{ teacher.realInstitution.text_availability }}
                </v-alert>
            </v-col>
        </v-row>
                  <v-row class="ma-0">
                    <v-col cols="12" sm="8" v-show="config.showAvailabilityWeek" >
                        <v-card id="simple-table">
                            <v-simple-table v-if="acessoValido">
                              
                              <template v-slot:default>
                                <caption  v-if="showHourGroup">
                                  <v-btn-toggle
                                      v-model="selectedHourGroup"
                                      :rounded="false"
                                      group
                                  >
                                  
                                    <v-tooltip v-for="(g, i) in hourGroupsList" :key="i" bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-btn v-bind="attrs" v-on="on" :value="g.id">
                                            <span v-if="g.icon">
                                              <span class="hidden-sm-and-down">{{ g.initials }}</span>
                                              <v-icon small right end>
                                                {{ g.icon }}
                                              </v-icon>
                                            </span>
                                            <span v-else>{{ g.initials }}</span>
                                          </v-btn>
                                        </template>
                                        <span> 
                                          {{ g.name }}
                                          <small v-if="g.description"><br/>{{ g.description }}</small>
                                        </span>
                                      </v-tooltip>

                                </v-btn-toggle>
                                </caption>
                                <thead>
                                  <tr>
                                    <th class="text-center" v-for="(item, i) in headers" :key="i">
                                      <span v-if="i == 0">
                                          <emc-info-help title="Disponibilidade Semanal" text="Selecione os dias e horários que está disponível para lecionar" />
                                      </span>
                                      <span v-else>
                                          {{ (i == 0) ? '' : item.text }}
                                      </span>
                                    </th>
                                  </tr>
                                  
                                </thead>
                                <tbody>
                                    <tr v-show="showHourByGroup(hour)" v-for="(hour, i) in filteredItemsHours" :key="i">
                                      <td class="text-caption text-center">{{ hour.start.substring(0, 5) }} <br/> {{ hour.end.substring(0, 5) }}</td>
                                      <td 
                                        v-for="(header, h) in headers.filter(e => e.number != 0)" 
                                        :key="h" class="text-center">
                                        <v-icon 
                                          v-show="showDays(hour, header.number)" 
                                          :color="getColor(hour[header.value])" 
                                          :disabled="!selectedTeacher" 
                                          @click="select(hour, header.number)">
                                            {{ getIcon(hour[header.value]) }}
                                            </v-icon>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td colspan="2">
                                      <v-tooltip v-if="acessoValido && (isAvailabilityEnabled || release) && selectedTeacher" bottom >
                                          <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" color="primary" class="mx-2" dark @click="validar()">
                                              <v-icon dark>save</v-icon>
                                              Salvar
                                            </v-btn>
                                          </template>
                                          <span>Salvar</span>
                                        </v-tooltip>
                                    </td>
                                    <td :colspan="headers.length - 4">
                                      <v-switch v-if="selectedTeacher && showEnableActivityTime" inset v-model="modelActivityHour" label="Hora Atividade"></v-switch>
                                    </td>
                                    <td colspan="2">
                                      <div v-if="acessoValido && (isAvailabilityEnabled || release) && selectedTeacher">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                              <v-btn x-small @click="disponibilizar(release ? 3 : 1, true)" :color="getColor(release ? 3 : 1)" fab class="mx-2" dark v-on="on">
                                                <v-icon dark>mdi-checkbox-marked-circle</v-icon>
                                              </v-btn>
                                            </template>
                                            <span>Disponibilizar Tudo</span>
                                          </v-tooltip>

                                          <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                              <v-btn @click="disponibilizar(0, true)" :color="getColor(0)" fab x-small class="mx-2" dark v-on="on">
                                                <v-icon dark>close</v-icon>
                                              </v-btn>
                                            </template>
                                            <span>Indisponibilizar Tudo</span>
                                          </v-tooltip>
                                      </div>
                                    </td>
                                  </tr>
                                </tfoot>
                              </template>
                            </v-simple-table>
                          </v-card>
                       </v-col>
                       <v-col v-if="selectedTeacher" cols="12" sm="4">
                          <v-card
                            v-if="user.is_master || config.visibleBox.digital"
                            class="mx-auto"
                            outlined
                          >
                            <v-app-bar class="elevation-0" dense>
                                <v-toolbar-title class="text-body-2">
                                    <emc-info-help title="Disponibilidade EAD" :text="isAvailabilityEnabled ? 'Mova o balão para informar sua carga horária semanal disponível para as disciplinas EAD' : 'Carga horária semanal disponível para disciplinas EAD:'" />
                                    <strong>Disponibilidade EAD</strong>
                                </v-toolbar-title>
                            </v-app-bar>
                            <v-card-text>
                                <v-slider
                                    v-model="chMaxDigital"
                                    :label="(!isAvailabilityEnabled) ? chMaxDigital.toString() : '0'"
                                    :thumb-color="getThumbColor()"
                                    thumb-label="always"
                                    @change="updateWorktLoadTotal(true)"
                                    :min="0"
                                    :max="config.maxWorkLoad"
                                    :disabled="!release && !isAvailabilityEnabled"
                                    :persistent-hint="getAvailabilityTeacher() && getAvailabilityTeacher().digital_availability_teacher != chMaxDigital"
                                    :hint="getHint()"
                                  >
                                  <template v-slot:append>
                                    <div class="text-body-1 grey--text text--darken-1" v-text="config.maxWorkLoad"></div>
                                  </template>
                                  </v-slider>
                              </v-card-text>
                          </v-card>
                          <v-card outlined max-height="350" class="pb-2 pa-0 mt-2">
                            <v-app-bar class="elevation-0" dense>
                                <v-toolbar-title class="text-body-2">
                                      <strong>Legenda</strong>
                                </v-toolbar-title>
                            </v-app-bar>
                            <v-divider class="mb-2"></v-divider>
                              <div>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                      <span class="pb-2 pl-1 pr-0 grey--text text--darken-1" style="cursor: help;" v-bind="attrs" v-on="on"><v-icon :color="getColor(0)">{{ getIcon(0) }}</v-icon>Indisponível</span>
                                  </template>
                                  <span>Horário indisponibilizado pelo professor</span>
                                </v-tooltip>
                              </div>
                              <div>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                      <span class="pb-2 pl-1 pr-0 grey--text text--darken-1" style="cursor: help;" v-bind="attrs" v-on="on"><v-icon :color="getColor(1)">{{ getIcon(1) }}</v-icon>Disponível</span>
                                  </template>
                                  <span> Horário disponibilizado pelo professor </span>
                                </v-tooltip>
                              </div>
                              <div>
                                <v-tooltip v-if="showLegendRelease" bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                      <span class="pb-2 pl-1 pr-0 grey--text text--darken-1" style="cursor: help;" v-bind="attrs" v-on="on"><v-icon :color="getColor(2)">{{ getIcon(2) }}</v-icon>Retirado</span>
                                  </template>
                                  <span> Horário retirado pela instituição </span>
                                </v-tooltip>
                              </div>
                              <div>
                                <v-tooltip v-if="showLegendRelease" bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                      <span class="pb-2 pl-1 pr-0 grey--text text--darken-1" style="cursor: help;" v-bind="attrs" v-on="on"><v-icon :color="getColor(3)">{{ getIcon(3) }}</v-icon>Liberado</span>
                                  </template>
                                  <span> Horário liberado pela instituição </span>
                                </v-tooltip>
                              </div>
                              <div>
                                <v-tooltip v-if="showEnableActivityTime || showLegendActivity" bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                      <span class="pb-2 pl-1 pr-0 grey--text text--darken-1" style="cursor: help;" v-bind="attrs" v-on="on"><v-icon :color="getColor(4)">{{ getIcon(4) }}</v-icon>Hora Atividade</span>
                                  </template>
                                  <span> Hora Atividade </span>
                                </v-tooltip>
                              </div>
                        </v-card>
                      </v-col>
          </v-row>
          <v-row v-if="selectedTeacher && (config.visibleBox.shifts || config.visibleBox.total)">
            <v-col v-if="config.visibleBox.shifts && config.showBox.morning" cols="12" sm="2">
                  <v-text-field
                    v-model="chMaxManha"
                    label="CH Desejável Manhã"
                    type="number"
                    :min="0"
                    :max="maxManha"
                    filled
                  ></v-text-field>
            </v-col>
            <v-col v-if="config.visibleBox.shifts && config.showBox.evening" cols="12" sm="2">
                  <v-text-field
                    v-model="chMaxTarde"
                    label="CH Desejável Tarde"
                    type="number"
                    :min="0"
                    :max="maxTarde"
                    filled
                  ></v-text-field>
            </v-col>
            <v-col v-if="config.visibleBox.shifts && config.showBox.night" cols="12" sm="2">
                  <v-text-field
                    v-model="chMaxNoite"
                    label="CH Desejável Noite"
                    type="number"
                    :min="0"
                    :max="maxNoite"
                    filled
                  ></v-text-field>
            </v-col>
            <v-col v-if="config.visibleBox.total" cols="12" sm="2">
                  <v-text-field
                    v-model="chMaxSemanal"
                    type="number"
                    :min="0"
                    :max="config.maxWorkLoad"
                    filled
                  >
                    <template v-slot:label>
                        <emc-info-help title="Carga Horária Máxima" text="Após informar sua disponibilidade de dias e horários, é possível informar também a carga horária máxima semanal que deseja lecionar" />
                        CH Máxima Desejada
                    </template>
                </v-text-field>
                </v-col>
              <!-- <v-col v-if="config.validateMinWorkLoad && user.is_master" cols="12" sm="2">
                <v-text-field
                    v-model="chMinSemanal"
                    type="number"
                    :min="0"
                    :max="config.maxWorkLoad"
                    filled
                  >
                    <template v-slot:label>
                        <emc-info-help title="CH Mínima Permitida" text="Carga Horária Mínima Permitida para disponibilizar no período atual" />
                        CH Mínima Permitida Desejada
                    </template>
                </v-text-field>
              </v-col> -->
            </v-row>

            <emc-alert-modal title="Salvar Disponibilidade" colorIcon="success" icon="mdi-content-save-move-outline" :show.sync="dialog">

                <template v-slot:text>
                    <div  class="mb-2" v-if="!release && workTime && workTime.show_agree_term && workTime.term">
                        <v-alert
                            type="info"
                            border="left"
                            elevation="2"
                            colored-border
                        >
                            <emc-util-scroll maxHeight="300px">
                            <div class="ma-1" v-html="workTime.term.content"></div>
                            <a id="term"></a>
                            <v-checkbox
                                v-if="workTime.term.is_required"  
                                v-model="agree_term"
                                :label="workTime.term.label"
                                color="primary"
                                hide-details
                                class="ma-2"
                                />
                            <span v-else>
                                <v-radio-group
                                v-if="teacher && teacher.realInstitution"
                                v-model="term_option_id"
                                row
                                class="ma-2"
                                >
                                <v-radio
                                    v-for="(opt, i) in teacher.realInstitution.term_options"
                                    :key="i"
                                    :label="opt.label"
                                    :value="opt.id"
                                    :color="opt.color"
                                />
                            </v-radio-group>
                            </span>
                            </emc-util-scroll>
                        </v-alert>
                    </div>

                    <v-alert
                        color="success"
                        border="left"
                        elevation="2"
                        colored-border
                    >
                    <div class="text-center">
                        <strong>Confirma o envio da sua disponibilidade?</strong>
                        <br/><small>{{ textoConfirmacao }}</small>
                    </div>
                    </v-alert>

                </template>

                <template v-slot:actions>
                    <v-btn color="primary" @click.prevent.stop="saveAvailability(3)" >Enviar<v-icon right dark>send</v-icon></v-btn>
                    <v-btn v-if="workTime && workTime.availability_single" color="orange" @click.prevent.stop="saveAvailability(2)" >Salvar<v-icon right dark>save</v-icon></v-btn>
                </template>

            </emc-alert-modal>

            <emc-alert-snackbar :timeout="20000" :show.sync="snackbar" :color="color" :message="texto">
                <template v-if="showOpenNewLink" v-slot:action>
                    <v-btn 
                        class="float-right" 
                        text 
                        @click="$router.push('access-teacher')" 
                        outlined
                        >
                        Gerar Novo Link
                    </v-btn>
                </template>
            </emc-alert-snackbar>

    </v-container>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import state from '../../../modules/auth/store/state'
import { myMixin } from '../../../mixins';

export default {
    name: 'SysTeacherAvailabilityWeek',
    mixins: [myMixin],
    data: function() {
        return {
            title: 'Disponibilidade do Professor',
            teacher: null,
            workTime: null,
            showMailAvailability: false,
            showLegendRelease: false,
            showLegendActivity: false,
            showOpenNewLink: false,
            waiting: 'Carregando...',
            chDigitalOrigem: 0,
            panel: 0,
            chMaxDigital: 0,
            chMaxActivity: 0,
            chMaxManha: 0,
            chMaxTarde: 0,
            chMaxNoite: 0,
            chMinSemanal: 0,
            chMaxSemanal: 0,
            maxManha: 0,
            maxTarde: 0,
            maxNoite: 0,
            originDisps: [],
            maxWorkLoadOrigin: 0,
            agree_term: null,
            selectedHourGroup: null,
            term_option_id: null,
            config: {
                maxWorkLoad: 44,
                showDisciplinesInterest: false,
                showAvailabilityDate: false,
                showAvailabilityWeek: false,
                showUpdateData: false,
                showSaturday: true,
                showSunday: false,
                showSaturdayNight: false,
                showSundayNight: false,
                showAttributions: false,
                showOtherActivities: false,
                showTimeTable: false,
                showActivityTimeTable: false,
                showConfirmation: false,
                showAgreeTerm: false,
                showOccurrences: false,
                showTermSignatures: false,
                showAddress: false,
                validateMinWorkLoad: false,
                visibleBox: {
                    digital: true,
                    shifts: true,
                    total: false
                },
                showBox: {
                    morning: false,
                    evening: false,
                    night: false
                },
            },
            textProgress: 'Carregando dados...',
            progress: true,
            visible: false,
            visibleSearch: true,
            acessoValido: true,
            acessoEspecifico: false,
            modelActivityHour: false,
            nomeProfessor: '',
            dialog: false,
            snackbar: false,
            itemsProfessores: [],
            texto: '',
            search: '',
            textoConfirmacao: "",
            itemsHours: [],
            filteredItemsHours: [],
            color: '',
            colors: ['error', 'success', 'orange', 'blue', 'grey', 'purple'],
            headers: [
            {
                text: 'Horário',
                align: 'start',
                sortable: false,
                value: 'horario',
                number: 0
            },
            {
                text: 'Segunda',
                align: 'center',
                sortable: false,
                value: 'seg',
                number: 2
            },
            {
                text: 'Terça',
                align: 'center',
                sortable: false,
                value: 'ter',
                number: 3
            },
            {
                text: 'Quarta',
                align: 'center',
                sortable: false,
                value: 'qua',
                number: 4
            },
            {
                text: 'Quinta',
                align: 'center',
                sortable: false,
                value: 'qui',
                number: 5
            },
            {
                text: 'Sexta',
                align: 'center',
                sortable: false,
                value: 'sex',
                number: 6
            },
            {
                text: 'Sábado',
                align: 'center',
                sortable: false,
                value: 'sab',
                number: 7
            },
            {
                text: 'Domingo',
                align: 'center',
                sortable: false,
                value: 'dom',
                number: 1
            },
            
            ],
            headersTeacher: [
            { text: 'Nome', align: 'left', value: 'name' },
        ],
        }
    },
    computed: {
        ...mapState('auth', ['acesso', 'user']),

        showActions() {
        
            if(this.$route.query.token) {
            return false;
            }

            if(this.acesso.instituicao) {
            return true
            }

            return false
        },
        
        selectedTeacher () {
            return this.teacher && this.teacher.id != 0;
        },

        showHourGroup () {

            if(this.teacher && this.teacher.institution && this.teacher.institution.settings){
                return this.teacher.institution.settings.show_hour_group_availability;
            }else{
                return false;
            }

        },

        hourGroupsList (){

            if(this.showHourGroup && this.teacher && this.teacher.institution){

                let groups = this.teacher.institution.hour_groups.filter(h => (h.active && h.show_availability));

                return groups;

            }else{
                return [];
            }

        },

        showEnableActivityTime(){
            return (this.acesso.instituicao && this.acesso.instituicao.enable_activity_time) ||
                    (this.teacher && this.teacher.institution && this.teacher.institution.enable_activity_time);
        },

        isAvailabilityEnabled() {

            if(this.progress) {
                return true;
            }

            if(!this.workTime) {
                return false;  
            }

            if(this.teacher && !this.workTime.is_available_availability) {

            /*
                if(this.teacher.is_available_availability) {
                this.workTime.availability_end = this.teacher.release_availability_until
                }

                */
                return this.teacher.is_available_availability;
            }

                return this.workTime.is_available_availability;

            },
    },
    created() {
        
        // Remover o Domingo da Lista de Cabeçalho
        if(!this.config.showSunday){
            this.headers.splice(7, 1);
        }

        if(!this.config.showSaturday){
            this.headers.splice(6, 1);
        }

    },
    props: {
        
        release: {
            type: Boolean,
            default: false
        },
        workTimeId:{
            type: Number,
            required: true
        },
        teacherId: {
            type: Number,
            required: true
        },
        text: {
            type: String,
            default: null
        }
        
    },
    watch: {
        teacherId(val){
            this.getTeacher();
        },
    },
    mounted(){
       this.loadData();
    },
    methods: {
        ...mapActions('availability', ['ActionAvailabilityCreate','ActionAvailabilityUpdate']),
        ...mapActions('hour', ['ActionGetHoursGroups']),
        ...mapActions('teacher', ['ActionFindTeacher']),
        ...mapActions('worktime', ['ActionShowWorkTime']),

        async loadData(){
            await this.getWorkTime();
            await this.getHours();
            await this.getTeacher();
        },

        
        getData() {
        
            if(this.$route.name === 'availability-teacher' && token){
            
                this.visibleSearch = false;
                this.acessoEspecifico = true;
                this.release = false;
            
            }else{
            
                this.release = true;
                this.workTime = state.acesso.periodo;
                this.setConfigs();
            
            }
            
        },

        showHourByGroup(hour){

            if(this.showHourGroup){
                return this.hasGroupHour(hour);
            }else{
                return true;
            }

        },

        showDays(hour, day){
            
            if(day == 1){ // Domingo

                if(this.config.showSunday){
                    
                    if(hour.shift_id == 3 &&  !this.config.showSundayNight){
                    return false;
                    }else{
                    return true;
                    }
                    
                }else{
                    return false;
                }

            }else if(day == 7){ // Sábado

                if(this.config.showSaturday){
                    
                    if(hour.shift_id == 3 &&  !this.config.showSaturdayNight){
                        return false;
                    }else{
                        return true;
                    }
                
                }else{
                    return false;
                }

            }else{
                return true;
            }
            
        },

        async getWorkTime(){
            
            this.workTime = null;
            
            var payload = {
                id: this.workTimeId,
                with: 'term',
            };
            
            await this.ActionShowWorkTime(payload)
                    .then((res) => {
                        this.workTime = res.data;
                        this.setConfigs();
                    })
                    .catch((error) =>{
                        
                        if(error.code == 401 || error.code == 422){
                            this.showOpenNewLink = true;
                        }
                        
                        this.showSnack(error.message, true);
                        this.progress = false;

                    })
                    .finally(() => {

                    });
        },

        async getHours(){

            var payload = {
                with: 'shift',
                per_page: 1000
            };

            await this.ActionGetHoursGroups(payload)
                        .then((res) => {
                            this.loadHours(res.data);
                        }).catch((error) =>{

                        })
                        .finally(() => {
                            this.progress = false;
                        });

        },

        async getTeacher(){

            this.teacher = null;
            
            var payload = {
                id: this.teacherId,
                with: 'institution.termOptions,institution.hourGroups,emails,contracts.position,availability.availabilities',
                withContract: true,
                filter_relation_withs: [
                    {
                        name: 'confirmation',
                        key: 'work_time_id', 
                        value: this.workTimeId,
                    },
                    {
                        name: 'availability',
                        key: 'work_time_id', 
                        value: this.workTimeId,
                    }
                ] 
            };

            await this.ActionFindTeacher(payload)
                    .then((res) => {
                        this.teacher = res.data;
                        this.loadFilterItemsHours();
                        this.loadAvailability();
                        this.setConfigs();
                    })
                    .catch((error) =>{
                        this.showSnack('Dados não encontrados!', true);
                    })
                    .finally(() => {
                        this.progress = false;
                    });

        },
        setConfigs(){

            if(this.workTime){
                this.config.visibleBox.digital      = this.workTime.show_digital;
                this.config.visibleBox.shifts       = this.workTime.show_box_total;
                this.config.maxWorkLoad             = this.workTime.max_work_load;
                this.config.showDisciplinesInterest = this.workTime.show_disciplines_interest;
                this.config.showAvailabilityDate    = this.workTime.show_availability_date;
                this.config.showAvailabilityWeek    = this.workTime.show_availability_week;
                this.config.showUpdateData          = this.workTime.show_update_data;
                this.config.showAttributions        = this.workTime.display_indications;
                this.config.showOtherActivities     = this.workTime.show_other_activities;
                this.config.showTimeTable           = this.workTime.display_times;
                this.config.showActivityTimeTable   = this.workTime.show_activity_time_table;
                this.config.showConfirmation        = this.workTime.show_confirmation;
                this.config.showAgreeTerm           = this.workTime.show_agree_term;
                this.config.showOccurrences         = this.workTime.show_occurrences;
                this.config.autoCalculate           = this.workTime.auto_calculate_work_load;
                this.config.validateMinWorkLoad     = this.workTime.validate_min_work_load;
            }

            if(this.teacher && this.teacher.realInstitution && this.teacher.realInstitution.settings){
                this.config.showTermSignatures = this.teacher.realInstitution.settings.show_term_signatures;
                this.config.showAddress = this.teacher.realInstitution.settings.show_address_teacher;
            }

            

        },
        
        
        loadHours(hours){

            hours.forEach(h => {
            
            var shift = h.shift;

            h['seg'] = 0;
            h['ter'] = 0;
            h['qua'] = 0;
            h['qui'] = 0;
            h['sex'] = 0;
            h['sab'] = 0;
            h['dom'] = 0;

            if(shift.id == 1)
                this.config.showBox.morning = true;
            else if(shift.id == 2)
                this.config.showBox.evening = true;
            else if(shift.id == 3)
                this.config.showBox.night = true;

            });

            this.itemsHours = hours;
            this.loadFilterItemsHours();

        },

        loadFilterItemsHours(){

            let group_ids = [];
            let items = [];

            if(this.teacher && this.teacher.originalInstitution){
            
            if(this.teacher.originalCampus.hour_group_id){
                group_ids.push(this.teacher.originalCampus.hour_group_id);
            }

            }

            if(this.teacher && this.teacher.contracts){
            this.teacher.contracts.forEach(cont => {

                if(cont.position.hour_group_id){
                group_ids.push(cont.position.hour_group_id);
                }
    
            });
            }

            this.itemsHours.forEach(h => {
            
            const items2 = h.items;
            let newHour = Object.assign({}, h);

            newHour.items = [];
            newHour.number_of_classes = 0;

            if(group_ids.length > 0){
                
                if(newHour.groups.find(g => group_ids.includes(g.id))){
                items2.forEach(h2 => {
                    if(h2.groups.find(g2 => group_ids.includes(g2.id))){
                    newHour.items.push(h2);
                    newHour.number_of_classes += h2.number_of_classes;
                    }
                })

                if(newHour.items.length){
                    newHour.start = newHour.items[0].start;
                    newHour.end = newHour.items[newHour.items.length - 1].end;
                }

                items.push(newHour);

                }

            }else{
                
                if(newHour.groups.find(g => g.is_default)){
                
                items2.forEach(h2 => {
                    if(h2.groups.find(g2 => g2.is_default)){
                    newHour.items.push(h2);
                    newHour.number_of_classes += h2.number_of_classes;
                    }
                })

                if(newHour.items.length){
                    newHour.start = newHour.items[0].start;
                    newHour.end = newHour.items[newHour.items.length - 1].end;
                }
                
                items.push(newHour);

                }
            }

            });

            if(!this.selectedHourGroup && this.hourGroupsList.length > 0){
                this.selectedHourGroup = this.hourGroupsList[0].id;
            }

            this.filteredItemsHours = items;
            
        },

        showSnack(text, error){
            
            this.color = error ? 'error' : 'success';
            this.texto = text;
            this.snackbar = true;

        },
        
        getColor (valor) {
            return this.colors[valor];
        },
        
        getIcon (valor) {
            
            switch (valor) {
            case 0:
            case 2:   return "mdi-close-circle";
            case 4:   return "mdi-minus-circle-outline";   
            default:  return "mdi-checkbox-marked-circle";       
            }
        
        },

        getTextoHorario (item) {
            return item.inicio.substring(0, 5) + ' - ' + item.fim.substring(0, 5); 
        },

        disponibilizar(valor, select = false){
            
            var days = ['seg', 'ter', 'qua', 'qui', 'sex', 'sab', 'dom'];
            
            this.filteredItemsHours.forEach(item => {
                
            if(this.showHourGroup){
                
                if(!this.hasGroupHour(item)){
                return;
                }

            }
            
            days.forEach(day => {

                if(this.release && item[day] != 0 && item[day] != 3){
                    return;
                }
                
                if(day == 'sab'){
                    if(this.config.showSaturday){
                    if(this.config.showSaturdayNight || item.shift_id < 3){
                        item.sab = valor;
                    }else{
                        item.sab = 0;
                    }
                    }else{
                        item.sab = 0;
                    }
                }else if(day == 'dom'){
                    if(this.config.showSunday) {
                        
                        if(this.config.showSundayNight || item.shift_id < 3){
                        item.dom = valor;
                        }else{
                        item.dom = 0;
                        }
                    }else{
                        item.dom = 0;
                    }
                }else{
                    item[day] = valor;
                }

                });

            });
            
            if(valor == 0){
            this.chMaxManha = 0;
            this.chMaxTarde = 0;
            this.chMaxNoite = 0;
            this.chMaxSemanal = 0;
            this.chMinSemanal = 0;
            }
            
            this.updateWorktLoadTotal(false, select);

        },

        hasGroupHour(hour){

            let has = false;

            hour.groups.forEach(g => {
            if(g.id == this.selectedHourGroup){
                has = true;
            }
            });

            return has;

        },

        select(item, dia){
            
            if(!this.isAvailabilityEnabled && !this.release) {
            return;
            }

            var oldValue = -1;
            var newValue = -1;

            switch(dia){
            case 1: oldValue = item.dom;  break;  
            case 2: oldValue = item.seg;  break;
            case 3: oldValue = item.ter;  break;
            case 4: oldValue = item.qua;  break;
            case 5: oldValue = item.qui;  break;
            case 6: oldValue = item.sex;  break;
            case 7: oldValue = item.sab;  break;
            }

            if(!this.release && oldValue == 4){
            return;
            }

            if(this.release){
            
            switch (oldValue) {
                case 0: newValue = 3; break;
                case 1: newValue = 2; break;
                case 2: newValue = 1; break;
                case 4: 
                case 3: newValue = 0; break;
                default:return;
            }

            }else{
            newValue = (oldValue == 0 || oldValue == 2 || oldValue == 4) ? 1 : 0;
            }

            if(this.modelActivityHour){
            
            var key = dia + '|' + item.start;
            var find = this.originDisps.find(o => o.key == key);
            
            if(oldValue != 4){
                if(!find){
                this.originDisps.push({
                    key: key,
                    value: oldValue
                });
                }
                newValue = 4;
            }else{
                newValue = find ? find.value : 0;
            }
            }

            switch(dia){
            case 1: item.dom = newValue; break;
            case 2: item.seg = newValue; break;
            case 3: item.ter = newValue; break;
            case 4: item.qua = newValue; break;
            case 5: item.qui = newValue; break;
            case 6: item.sex = newValue; break;
            case 7: item.sab = newValue; break;
            }

            this.updateWorktLoadTotal(false, true);

        },

        updateWorktLoadTotal(initial, select = false){

            var totalSemanal = 0;
            var countSemanal = 0;
            var countManha = 0;
            var countTarde = 0;
            var countNoite = 0;
            var shift_id = 0;
            var days = ['seg', 'ter', 'qua', 'qui', 'sex', 'sab', 'dom'];

            this.filteredItemsHours.forEach((h) => {
            
            shift_id = parseInt(h.shift.id);

            days.forEach(day => {
                
                if(h[day] && (h[day] == 1 || h[day] == 3 || h[day] == 5)){
                
                countSemanal += h.number_of_classes;
                
                if(shift_id == 1){
                    countManha += h.number_of_classes;
                }else if(shift_id == 2){
                    countTarde += h.number_of_classes;
                }else
                    countNoite += h.number_of_classes;
                }

            });

            });

            totalSemanal = countSemanal + parseFloat(this.chMaxDigital);

            this.maxManha = countManha;
            this.maxTarde = countTarde;
            this.maxNoite = countNoite;

            if(!initial && this.config.autoCalculate){
            
            this.chMaxManha = countManha;
            this.chMaxTarde = countTarde;
            this.chMaxNoite = countNoite;
    
            if(totalSemanal > this.config.maxWorkLoad){
                totalSemanal = this.config.maxWorkLoad;
            }
    
            if(select){
                this.chMaxSemanal = totalSemanal;
            }
            }
            
        },

        validar(){

            if(!this.validarDisponibilidade())
            {
                this.showSnack('Informe sua disponibilidade de horário!', true);
            }else{
            
            var date = this.workTime.availability_end;

            if(this.teacher.is_available_availability) {
                date = this.teacher.release_availability_until;
            }
            
            if(this.workTime && this.workTime.availability_single){
                this.textoConfirmacao = "Após enviar sua disponibilidade não será possível fazer alteração. Você pode apenas salvar e efetuar o envio até " + this.$options.filters.date(date) + "!";
            }else
            {
                this.textoConfirmacao = "Você poderá realizar alterações até " + this.$options.filters.date(date) +  "!";
            }

                this.dialog = true;

            }

        },

        getDisponibilidades(){
            
            var availability = this.getAvailabilityTeacher();

            if(!availability){
            availability = this.getAvailabilityObject();
            }

            //availability.min_workload           = this.chMinSemanal;
            availability.max_workload           = this.chMaxSemanal;
            availability.digital_availability   = this.chMaxDigital;
            availability.activity_availability  = this.chMaxActivity;
            availability.morning_availability   = this.chMaxManha;
            availability.evening_availability   = this.chMaxTarde;
            availability.night_availability     = this.chMaxNoite;

            if(!this.release && this.workTime && this.workTime.show_agree_term){
            availability.agree_term           = this.agree_term;
            availability.term_option_id       = this.term_option_id;
            availability.term_id              = this.workTime.term_id;
            }

            if((this.chDigitalOrigem != this.chMaxDigital) && !this.release){
            availability.digital_availability_teacher = availability.digital_availability;
            }
            
            return availability;

        },

        getAvailabilityObject(){

            var availability = {
            id: 0,
            teacher_id: this.teacher.id,
            work_time_id: this.workTime.id,
            status_availability_id: 1,
            term_id: null,
            agree_term: null,
            term_option_id: null,
            is_priority: 0,
            requires_complementary: 0,
            // min_workload: 0,
            max_workload: 0,
            note: '',
            token: '',
            morning_availability: 0,
            evening_availability: 0,
            night_availability: 0,
            digital_availability: 0,
            digital_availability_teacher: 0,
            availabilities: []
            };

            return availability;

        },

        getAvailabilities(id){
            
            var availabilites = [];
            var days = [ 'dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'];
            
            this.filteredItemsHours.forEach(hour => {
            
            for (let i = 0; i < 7; i++) {
                
                if(hour[days[i]] != 0){
                
                hour.items.forEach(item => {
                    availabilites.push(
                    {
                        availability_id:      id,
                        hour_id:              item.id,
                        hour_start:           item.start,
                        hour_end:             item.end, 
                        day_of_week_id:       (i + 1),
                        availability_type_id: hour[days[i]]
                    }
                    );
                });
                }
            }
            });

            return availabilites;

        },
        
        validarDisponibilidade(){

            var count = this.chMaxDigital;

            this.filteredItemsHours.forEach(item => {
                if(item.dom != 0 && item.dom != 2 && item.dom != 4)
                    count++;
                if(item.seg != 0 && item.seg != 2 && item.seg != 4)
                    count++;
                if(item.ter != 0 && item.ter != 2 && item.ter != 4)
                    count++;
                if(item.qua != 0 && item.qua != 2 && item.qua != 4)
                    count++;
                if(item.qui != 0 && item.qui != 2 && item.qui != 4)
                    count++;
                if(item.sex != 0 && item.sex != 2 && item.sex != 4)
                    count++;
                if(item.sab != 0 && item.sab != 2 && item.sab != 4)
                    count++;
                
            });

            if(count == 0){
                return false;
            }else{

                return true;

            }
        },
        
        saveAvailability(status) {
            
            if(!this.release && this.workTime && this.workTime.show_agree_term && this.workTime.term){
                if((this.workTime.term.is_required && !this.agree_term) || (!this.workTime.term.is_required && !this.term_option_id)){
                    let el = document.createElement('a');
                    el.href="#term";
                    el.click();
                    this.showSnack('Informe se está de acordo ou não com o termo!', true);
                    return;
                }
            }

            var availability = this.getDisponibilidades();
            var availabilities = this.getAvailabilities(availability.id);
            this.dialog = false;
            this.progress = true;
            var method;

            availability.isToken = false;
            availability.availabilities = availabilities;
            availability.status_availability_id = status;

            if(this.release){
            availability.alter_from_user = true;
            }
            
            if(status == 2)
                this.textProgress = 'Salvando sua disponibilidade...';
            else
                this.textProgress = 'Enviando sua disponibilidade...';
            
            
            if(availability.id == 0){
                method = 'ActionAvailabilityCreate';
            }else{
                method = 'ActionAvailabilityUpdate';
            }

            var isError = false;
            var text = null;
            
            this[method](availability).then((res) => {
                if(availability.id == 0){
                this.teacher.availabilities.push(res.data);
                }
                text = res.message;

            })
            .catch((error) => {
            
                if(error.errors.max_workload){
                text = error.errors.max_workload[0];
                }else{
                text = error.errors.join(' | ');
                }

                isError = true;
                availability.status_availability_id = 1;

            }).finally(() => {
                this.showSnack(text, isError);
                this.progress = false;
            });
        },

        selectTeacher(teacher){

            this.visible = false;
            
            if(!teacher || teacher.length == 0){
                return;
            }

            this.teacher = teacher[0];

            this.loadFilterItemsHours();
            this.loadAvailability();
            this.setConfigs();
        },

        loadAvailability(){
        
        this.disponibilizar(0, true);

            this.originDisps = [];
            this.chMinSemanal = 0;
            this.chMaxSemanal = 0;
            this.chMaxDigital = 0;
            this.chMaxActivity = 0;
            this.chMaxManha   = 0;
            this.chMaxTarde   = 0;
            this.chMaxNoite   = 0;
            var showRelease = false;
            var showActivity = false;
            
            var availability = this.getAvailabilityTeacher();
            
            if(!availability)
                return;

            var availabilities = availability.availabilities;
            var item;

            availabilities.forEach((a) => {

                this.filteredItemsHours.forEach(hour => {

                if(item = hour.items.find((i) => i.id == a.hour_id)){
                
                    var type_id = parseInt(a.availability_type_id);
        
                    switch (parseInt(a.day_of_week_id)) {
                    case 1: hour.dom = type_id; break;
                    case 2: hour.seg = type_id; break;
                    case 3: hour.ter = type_id; break;
                    case 4: hour.qua = type_id; break;
                    case 5: hour.qui = type_id; break;
                    case 6: hour.sex = type_id; break;
                    case 7: hour.sab = type_id; break;
                    }

                    if(type_id == 2 || type_id == 3){
                    showRelease = true;
                    }

                    if(type_id == 4){
                    showActivity = true;
                    }
                }
                });
            });

            // this.chMinSemanal   = availability.min_workload;
            this.chMaxSemanal   = availability.max_workload;
            this.chMaxDigital   = availability.digital_availability;
            this.chMaxActivity  = availability.activity_availability;
            this.chMaxManha     = availability.morning_availability;
            this.chMaxTarde     = availability.evening_availability;
            this.chMaxNoite     = availability.night_availability;

            this.maxWorkLoadOrigin = availability.max_workload;

            if(!this.release){
                this.showLegendRelease = showRelease;
            }else{
                this.showLegendRelease = true;
            }

            this.showLegendActivity = showActivity;

            this.chDigitalOrigem = this.chMaxDigital;

            this.agree_term     = availability.agree_term;
            this.term_option_id = availability.term_option_id;

            this.updateWorktLoadTotal(true, false);

        
        },

        getAvailabilityTeacher(){
        
            if(this.teacher && this.teacher.availabilities && this.teacher.availabilities.length > 0){
                return this.teacher.availabilities[0];
            }else{
                return null;
            }
        
        },

        getThumbColor(){

            var ava = this.getAvailabilityTeacher();

            if(ava){
                var chTeacher = ava.digital_availability_teacher;

                if(chTeacher == this.chMaxDigital){
                return this.getColor(1);
                }else if(chTeacher > this.chMaxDigital){
                return this.getColor(2);
                }else{
                return this.getColor(3);
                }
            }
        

        },

        getHint(){

            var ava = this.getAvailabilityTeacher();
            if(ava){
                var wokLoad = parseFloat(ava.digital_availability_teacher);
                return 'CH original disponibilizada pelo professor: ' + wokLoad;
            }else{
                return "";
            }

        }

    },
    
}
</script>