<template>
    <div>
        <sys-register-base 
            :loading="loading" 
            @onSave="save" 
            :model.sync="model" 
            :title="title" 
            icon="mdi-flask-outline" 
            v-bind:show.sync="dialog"
            maxWidth="60%"
        >
            <template v-slot:tools>
                <v-tooltip v-if="model.id" bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon @click.prevent.stop="dialogImages = true"><v-icon color="cyan">mdi-camera-image</v-icon></v-btn>
                    </template>
                    <span>Imagens do Ambiente</span>
                </v-tooltip>
            </template>
            
            <template v-slot:form>
                <v-tabs class="ma-0" v-model="tab">
                    <v-tab key="main">Dados Principais</v-tab>
                    <v-tab key="resources">Recursos</v-tab>
                    <v-tab key="availability" v-if="model && model.id">Disponibilidade</v-tab>
                    <v-tab key="allocation" v-if="model && model.id">Agendamento Externo</v-tab>
                </v-tabs>
                <v-tabs-items  v-model="tab">
                    <v-tab-item key="main">
                        <v-row>
                            <v-col  cols="12" sm="7">
                                <SysSelectCampus
                                    :model.sync="model.campus_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.campus_id"
                                    label="Campus*"
                                    :show-add="false"
                                    auto-complete
                                />
                            </v-col>
                            <v-col  cols="12" sm="5">
                                <SysSelectAmbienceType
                                    :model.sync="model.ambience_type_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.ambience_type_id"
                                    label="Tipo de Ambiente*"
                                    showAdd
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <s-text-field text-helper="Nome ou número do ambiente." dense :error-messages="errors.name" v-model="model.name" label="Número/Nome*" />
                            </v-col>
                            <v-col cols="12" sm="8">
                                <s-text-field text-helper="Descrição do Ambiente" dense :error-messages="errors.description" v-model="model.description" label="Descrição" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col  cols="12" sm="4">
                                <SysSelectPlace
                                    :model.sync="model.place_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.place_id"
                                    label="Prédio"
                                    item-text="name_complete"
                                    clearable
                                    auto-complete
                                    :campus-id="model.campus_id"
                                />
                            </v-col>
                            <v-col  cols="12" sm="4">
                                <SysSelectBlock
                                    :model.sync="model.block_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.block_id"
                                    label="Bloco"
                                    item-text="name_complete"
                                    clearable
                                    auto-complete
                                    :place-id="model.place_id"
                                />
                            </v-col>
                            <v-col  cols="12" sm="4">
                                <SysSelectFloor
                                    :model.sync="model.floor_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.floor_id"
                                    label="Andar*"
                                    auto-complete
                                />
                            </v-col>
                            
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <SysSelectCategoryAmbience
                                    :model.sync="model.category_ambience_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.category_ambience_id"
                                    clearable
                                    label="Categoria de Ambiente"
                                    dense
                                />
                            </v-col>
                            <v-col cols="12" sm="2">
                                <s-text-field text-helper="Capacidade padrão do ambiente." dense :error-messages="errors.capacity" v-model="model.capacity" label="Capacidade*" />
                            </v-col>
                            <v-col cols="12" sm="2">
                                <s-text-field text-helper="Capacidade máxima, além da capacidade padrão do ambiente." dense :error-messages="errors.extended_capacity" v-model="model.extended_capacity" label="Cap. Est*" />
                            </v-col>
                            <v-col cols="12" sm="2">
                                <s-text-field text-helper="Capacidade reduzida, caso seja necessário reduzir a capacidade padrão do ambiente." dense :error-messages="errors.reduced_capacity" v-model="model.reduced_capacity" label="Cap Red*" />
                            </v-col>
                            <v-col cols="12" sm="2">
                                <s-text-field text-helper="Metragem do Ambiente." dense :error-messages="errors.area" v-model="model.area" label="Área*" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3">
                                <s-text-field code :error-messages="errors.code" v-model="model.code" label="Código" />
                            </v-col>
                            <v-col cols="12" sm="3">
                                <s-text-field text-helper="Código da Sala Externo" code :error-messages="errors.code_control" v-model="model.code_control" label="Código Sala"/>
                            </v-col>
                                <v-col v-if="user.is_master" cols="12" sm="3">
                                <s-switch text-helper="Validar dados cadastrados do ambiente" color="purple" inset v-model="model.validated" label="Validado"/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <s-switch text-helper="Ambiente não existe mais ou não será mais utilizado" inset v-model="model.active" label="Ativo"/>
                            </v-col>
                        </v-row>
                        </v-tab-item>
                        <v-tab-item key="resources">
                            <v-row>
                            <v-col cols="12" sm="12">
                                <v-data-table
                                    :headers="headers"
                                    :items="resources"
                                    class="elevation-1"
                                    :hide-default-footer="true"
                                    :items-per-page="-1"
                                    
                                >
                                    <template v-slot:top="{ on, attrs }">
                                        <v-btn color="indigo darken-1" @click.prevent.stop="addItem()" icon v-bind="attrs" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
                                    </template>

                                    <template dense v-slot:item.resource="{ item, index }">
                                        <sys-select-resource
                                            :model.sync="item.resource_id"
                                            :error="errors.length > 0"  
                                            :errors="errors['resources.' + index + '.resource_id']"
                                            label=""
                                            dense
                                            auto-complete
                                            item-text="name_complete"
                                        />
                                    </template>
                                    
                                    <template dense v-slot:item.total="{ item, index }">
                                        <v-text-field
                                            v-model="item.total"
                                            :error="errors.length > 0"  
                                            :error-messages="errors['resources.' + index + '.total']"
                                            dense
                                        ></v-text-field>
                                    </template>

                                    <template dense v-slot:item.allow_external_allocation="{ item  }">
                                        <s-switch text-helper="Recurso disponível para agendamento externo" inset v-model="item.allow_external_allocation" />
                                    </template>

                                    <template dense v-slot:item.active="{ item  }">
                                        <v-switch inset v-model="item.active"></v-switch>
                                    </template>
                                    
                                    <template v-slot:item.actions="{ item }">
                                        <v-icon
                                            small
                                            color="error"
                                            @click.prevent.stop="deleteItem(item)"
                                            dense
                                        >
                                            mdi-delete
                                        </v-icon>
                                    </template>
                                
                                </v-data-table>
                            </v-col>
                        </v-row>
                        </v-tab-item>
                        <v-tab-item key="availability">
                            <sys-availability-register :model="model" />
                            <s-switch class="ml-3" text-helper="Caso o ambiente esteja temporariamente indisponível para uso." inset v-model="model.not_available" color="purple" label="Indisponível"/>
                        </v-tab-item>
                        <v-tab-item key="allocation">
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <s-switch class="ml-3" text-helper="Ambiente disponível para consultas e agendamentos externos" inset v-model="model.allow_external_allocation" label="Disponível para agendamento externo"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="3">
                                    <emc-calendar-date-picker2 
                                        :model.sync="model.date_start_external_allocation" 
                                        label="Data Início" 
                                        :errorMessages="errors.date_start_external_allocation"
                                        :show="dialog"
                                        :disabled="!model.allow_external_allocation"
                                        clearable
                                    />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <emc-calendar-date-picker2 
                                        :model.sync="model.date_end_external_allocation" 
                                        label="Data Fim" 
                                        :errorMessages="errors.date_end_external_allocation"
                                        :show="dialog"
                                        :disabled="!model.allow_external_allocation"
                                        clearable
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="3">
                                    <emc-calendar-time-picker
                                        :time.sync="model.hour_start_external_allocation" 
                                        :error="!!errors.hour_start_external_allocation" 
                                        :errors="errors.hour_start_external_allocation" 
                                        label="Hora Início"
                                        dense
                                        :disabled="!model.allow_external_allocation"
                                        clearable
                                     />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <emc-calendar-time-picker
                                        :time.sync="model.hour_end_external_allocation" 
                                        :error="!!errors.hour_end_external_allocation" 
                                        :errors="errors.hour_end_external_allocation" 
                                        label="Hora Fim"
                                        dense
                                        :disabled="!model.allow_external_allocation"
                                        clearable
                                     />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-select
                                        v-model="model.allocationDays"
                                        item-value="id"
                                        item-text="name"
                                        :items="days.filter(d => d.visible)"
                                        label="Dias não Disponíveis"
                                        dense
                                        multiple
                                    />
                                </v-col>
                            </v-row>
                            
                        </v-tab-item>
                </v-tabs-items>
                <!-- Alert para mensagens -->
                <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
            </template>

        </sys-register-base>
        
        <sys-ambience-images :ambience="model" :show.sync="dialogImages" />
        
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterAmbience",
    data: (vm) => ({
        dialog: false,
        tab: null,
        dialogImages: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        resources: [],
        days: [
          {id: 1, name: 'Domingo',  visible: true},
          {id: 2, name: 'Segunda',  visible: true},
          {id: 3, name: 'Terça',    visible: true},
          {id: 4, name: 'Quarta',   visible: true},
          {id: 5, name: 'Quinta',   visible: true},
          {id: 6, name: 'Sexta',    visible: true},
          {id: 7, name: 'Sábado',   visible: true}
        ],
        headers: [
            {
                text: 'Recurso',
                align: 'start',
                sortable: false,
                value: 'resource',
                width: '75%'
            },
            { text: 'Quantidade',  align: 'center', value: 'total', sortable: false, width: '10%' },
            { text: 'Agendamento', value: 'allow_external_allocation', sortable: false,  width: '5%' },
            { text: 'Status', value: 'active', sortable: false,  width: '5%' },
            { text: '', value: 'actions', sortable: false,  width: '5%' },
        ],
    }),

    computed: {
      ...mapState('auth', ['acesso', 'user']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
           
            if(show_new){
                
                this.error = false;
                this.errors = {};
                this.resources = [];
                this.tab = null;
                this.model.updateAvailability = false;
                
                if(this.model.id){
                   Object.assign(this.resources, this.model.resources);
                   
                   if(this.model.allocationDays && this.model.allocationDays.length > 0){
                        this.model.allocationDays = this.model.allocationDays.map(a => a.id);
                   }

                }else{
                    this.model.area = 0;
                    this.model.active = true;
                    this.model.availabilities = [];
                }
                    
            }
                
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-alert"
        },
        title: {
            type: String,
            default: "Ambientes"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('ambience', [
            'ActionCreateAmbience', 
            'ActionUpdateAmbience', 
            ]),
        
        save(edit)
        {
            this.error = null;
            this.message = '';
            this.loading = true;
            this.model.resources = this.resources;

            
            if(this.model.hour_start_external_allocation){
                this.model.hour_start_external_allocation = this.model.hour_start_external_allocation.substring(0, 5);
            }

            if(this.model.hour_end_external_allocation){
                this.model.hour_end_external_allocation = this.model.hour_end_external_allocation.substring(0, 5);
            }
            
            if(edit) {
                this.ActionUpdateAmbience(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                    this.model.resources = [];
                    
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateAmbience(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

        addItem(){
            
            var item = {
                ambience_id:                this.model.id ? this.model.id : 0,
                resource_id:                null,
                total:                      1,
                allow_external_allocation:  0,
                active:                     1,
            };

            this.resources.push(item);
            
        },

        deleteItem(item){
            var index = this.resources.indexOf(item);
            this.resources.splice(index, 1);
        },

    }
}
</script>