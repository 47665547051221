<template>
    <sys-register-base 
        :loading="loading" 
        :model.sync="modelBase" 
        :title="title" 
        :icon="icon" 
        v-bind:show.sync="dialog"
        maxWidth="98%"
        :actions="false"
    >

    <template v-slot:tools>

        
    </template>
    
        <template v-slot:form>

            <main-container :showToolBar="false" icon="mdi-account-cash" title="Folha de Pagamento">
                <emc-table-items 
                    :headers="headers" 
                    v-bind:params.sync="params"
                    v-bind:collections.sync="collections"
                    v-bind:meta.sync="meta"
                    v-bind:itemDelete.sync="itemDelete" 
                    v-bind:itemEdit.sync="modelItem"
                    :loading="loading"
                    :find="false"
                    :showDelete="showDeleteButton"
                    :hideEdit="hideEdit"
                >
                    <template v-slot:item.month="{item}">
                        {{ item.month }} / {{ item.year }}
                    </template>

                    <template v-slot:actions="{item}">
                        <emc-table-icon-edit color="success" icon="mdi-calendar-account" tool-tip="Ver Marcações" @click="getSheetItems(item)" />
                        <!-- <emc-table-icon-edit color="error" icon="mdi-calendar-account" tool-tip="Espelho de Marcações" @click="exportPdf(item)" /> -->
                        <emc-table-icon-download-pdf toolTip="Espelho de Marcações" type="teacher-extract-time-sheet" :item="item" />
                    </template>
                    
                </emc-table-items>

                <emc-alert-modal text="Deseja excluir o item?" :disabled="deleting" v-bind:show.sync="showDelete">
                    <template v-slot:actions>
                        <v-btn color="error" @click.prevent.stop="deleteItem()" :loading="deleting" :disabled="deleting">Excluir <v-icon right dark>mdi-delete</v-icon></v-btn>
                    </template>
                </emc-alert-modal>

            </main-container>

            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!!message.length"  :message="message" :color="error ? 'error' : 'success'"/>

            <sys-occurrence-sheet-item-register @onCompleted="getData()" :model="modelItem" v-bind:show.sync="showFormItems" hideDelete showEditMark />

        </template>

    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    name: "SysOccurrenceTimeSheet",
    data: (vm) => ({
        dialog: false,
        collections: [],
        params: {},
        meta: {},
        error: false,
        errors: {},
        modelBase: {},
        modelItem: {},
        showFormItems: false,
        message: '',
        loading: false,
        showDelete: false,
        showForm: false,
        itemDelete: {},
        deleting: false,
        headers: [
            { text: 'ID', value: 'id'},
            { text: 'Campus', value: 'campus.name'},
            { text: 'Turno', value: 'shift.name'},
            { text: 'Mês', value: 'month'},
            { text: 'Período', value: ['date_start','date_end'], type: 'date', range: true},
            // { text: 'Status', value: 'status.name'},
            { text: 'Ações', value: 'actions'},
        ]
    }),
    watch: {
        
        itemDelete(item_new, item_old){
            if(item_new !== item_old && Object.keys(item_new).length > 0)
            {
                this.showDelete = true;
            } else {
                this.showDelete = false
            }
        },
        
        params(params_new, params_old){
            if(params_new != params_old) {
                if(!this.loading && this.params.page){
                    this.getData();
                }
            }
        },
        
        dialog(dialog_new, dialog_old) {
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }
        },

        show(show_new, show_old) {
            
            this.dialog = show_new;

            if(show_new){
                this.params = { search: null, column_order: 'id', direction: 'asc', page: 1, per_page: 5 };
                this.collections = [];
            }
        },

        modelItem(item_new, item_old){
          
        if(item_new !== item_old && this.modelItem.id)
          {
              this.showForm = true;
              this.errors = {};
          } else if(Object.keys(this.model).length === 0){
              this.showForm = false;
              this.errors = {};
          }           
      },

      showForm(show_new, show_old) {
          if(show_new != show_old && !show_new) {
              this.modelItem = {};
          }
      },
        
    },
    props: {
        model: {
            default: null
        },
        icon: {
            type: String,
            default: "mdi-account-cash"
        },
        title: {
            type: String,
            default: "Registros de Diário"
        },
        show: {
            type: Boolean,
            default: false
        },
        teacherId: {
            type: Number,
            required: true
        },
        workTimeId: {
            type: Number,
            required: true
        },
        showDeleteButton: {
            type: Boolean,
            default: true
        },
        hideEdit: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('timeSheet', ['ActionIndexOccurrenceSheets']),
        ...mapActions('controlPanel', ['ActionExportPdf']),

        getData(){
            this.error = false;
            this.message = '';
            this.params;
            this.loading = true;

            let payload = {
                ...this.params,
                with: 'campus,teacher,shift,status,types',
                find_columns: {
                    teacher_id: this.teacherId,
                    work_time_id: this.workTimeId,
                }
            };

            this.ActionIndexOccurrenceSheets(payload)
                .then((res) => {
                    this.collections = res.data;
                    this.meta = res.meta;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        getSheetItems(item){
            this.modelItem = item;
            this.showFormItems = true;
        },

        async exportPdf(item){
            
            var payload = {
                id: item.id,
                type: 'teacher-extract-time-sheet'
            };

            this.ActionExportPdf(payload)
                 .then(res => {
                    
                    //const uint8arr = new Uint8Array(await new Response(res.body).arrayBuffer());

                    var blob = new Blob([res.body], {
                        type: 'application/pdf'
                    });

                    var fileUrl = (window.URL || window.webkitURL).createObjectURL(blob);

                    window.open(fileUrl, '_blank');

                    // if (res.responseType === 'application/pdf') {
                    //     window.open(fileUrl);
                    // } else {
                    //     var a = document.createElement("a");
                    //     document.body.appendChild(a);
                    //     a.style = "display: none"
                    //     a.href = fileUrl;
                    //     a.download = 'arquivo.pdf';
                    //     a.target = "_blank";
                    //     a.click();
                    //     window.URL.revokeObjectURL(fileUrl);
                    // }
                    
                    // let blob = new Blob([res.data], { type: 'application/pdf' });
                    // const url = URL.createObjectURL(blob);
                    // window.open(url, '_blank');
                    // const a = document.createElement('a');
                    // a.style.display = 'none';
                    // a.href = url;
                    // a.download = 'arquivo.pdf';
                    // document.body.appendChild(a);
                    // a.click();
                    // window.URL.revokeObjectURL(url);
                 });

            return;

            this.ActionExportPdf(payload)
                .then((res) => {
                    
                    res.blob().then(blob => {
                        const url = URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = none;
                        a.href = url;
                        a.download = 'arquivo.pdf';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                    });
                    
                    // create element <a> for download PDF
                    // const link = document.createElement('a');
                    // link.href = res.body;
                    // link.target = '_blank';
                    // // link.download = this.pdfFileName;

                    // // Simulate a click on the element <a>
                    // document.body.appendChild(link);
                    // link.click();
                    // document.body.removeChild(link);

                    // const url = window.URL.createObjectURL(new Blob([res.data]));
                    // const link = document.createElement('a');
                    // link.href = url;
                    // link.setAttribute('download', 'teste.pdf');
                    // document.body.appendChild(link);
                    // link.click();


                    // window.open("data:application/pdf," + encodeURI(res.data));
                    // window.open("data:application/pdf," + window.URL.createObjectURL(new Blob([res.data])));
                    // window.open("data:application/pdf," + escape(res.data));
                    // window.open("data:application/octet-stream;charset=utf-16le;base64,"+ res.data);

                    // const file2 = new Blob([res.data], { type: 'application/pdf' });
                    // const url2 = URL.createObjectURL(file2);
                    // window.open(url2, '_blank');

                    // window.open("data:application/pdf," + encodeURI(res.data));
                    // window.open("data:application/pdf," + encodeURI(res.data));

                })
                .finally(() => {
                    
                });
        }

        
    
    }
}
</script>