var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _vm.teacher &&
      _vm.teacher.realInstitution &&
      _vm.teacher.realInstitution.text_availability &&
      _vm.teacher.realInstitution.text_availability != ""
        ? _c(
            "v-row",
            { staticClass: "ma-0" },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-alert",
                    {
                      attrs: {
                        border: "top",
                        "colored-border": "",
                        type: "info",
                        elevation: "2"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.teacher.realInstitution.text_availability
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "ma-0" },
        [
          _c(
            "v-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.config.showAvailabilityWeek,
                  expression: "config.showAvailabilityWeek"
                }
              ],
              attrs: { cols: "12", sm: "8" }
            },
            [
              _c(
                "v-card",
                { attrs: { id: "simple-table" } },
                [
                  _vm.acessoValido
                    ? _c("v-simple-table", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function() {
                                return [
                                  _vm.showHourGroup
                                    ? _c(
                                        "caption",
                                        [
                                          _c(
                                            "v-btn-toggle",
                                            {
                                              attrs: {
                                                rounded: false,
                                                group: ""
                                              },
                                              model: {
                                                value: _vm.selectedHourGroup,
                                                callback: function($$v) {
                                                  _vm.selectedHourGroup = $$v
                                                },
                                                expression: "selectedHourGroup"
                                              }
                                            },
                                            _vm._l(_vm.hourGroupsList, function(
                                              g,
                                              i
                                            ) {
                                              return _c(
                                                "v-tooltip",
                                                {
                                                  key: i,
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          var attrs = ref.attrs
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        g.id
                                                                    }
                                                                  },
                                                                  "v-btn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                g.icon
                                                                  ? _c(
                                                                      "span",
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "hidden-sm-and-down"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                g.initials
                                                                              )
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs: {
                                                                              small:
                                                                                "",
                                                                              right:
                                                                                "",
                                                                              end:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  g.icon
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          g.initials
                                                                        )
                                                                      )
                                                                    ])
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " + _vm._s(g.name) + " "
                                                    ),
                                                    g.description
                                                      ? _c("small", [
                                                          _c("br"),
                                                          _vm._v(
                                                            _vm._s(
                                                              g.description
                                                            )
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ])
                                                ]
                                              )
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("thead", [
                                    _c(
                                      "tr",
                                      _vm._l(_vm.headers, function(item, i) {
                                        return _c(
                                          "th",
                                          {
                                            key: i,
                                            staticClass: "text-center"
                                          },
                                          [
                                            i == 0
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c("emc-info-help", {
                                                      attrs: {
                                                        title:
                                                          "Disponibilidade Semanal",
                                                        text:
                                                          "Selecione os dias e horários que está disponível para lecionar"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        i == 0 ? "" : item.text
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.filteredItemsHours, function(
                                      hour,
                                      i
                                    ) {
                                      return _c(
                                        "tr",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.showHourByGroup(hour),
                                              expression:
                                                "showHourByGroup(hour)"
                                            }
                                          ],
                                          key: i
                                        },
                                        [
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "text-caption text-center"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  hour.start.substring(0, 5)
                                                ) + " "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    hour.end.substring(0, 5)
                                                  )
                                              )
                                            ]
                                          ),
                                          _vm._l(
                                            _vm.headers.filter(function(e) {
                                              return e.number != 0
                                            }),
                                            function(header, h) {
                                              return _c(
                                                "td",
                                                {
                                                  key: h,
                                                  staticClass: "text-center"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: _vm.showDays(
                                                            hour,
                                                            header.number
                                                          ),
                                                          expression:
                                                            "showDays(hour, header.number)"
                                                        }
                                                      ],
                                                      attrs: {
                                                        color: _vm.getColor(
                                                          hour[header.value]
                                                        ),
                                                        disabled: !_vm.selectedTeacher
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.select(
                                                            hour,
                                                            header.number
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getIcon(
                                                              hour[header.value]
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    }),
                                    0
                                  ),
                                  _c("tfoot", [
                                    _c("tr", [
                                      _c(
                                        "td",
                                        { attrs: { colspan: "2" } },
                                        [
                                          _vm.acessoValido &&
                                          (_vm.isAvailabilityEnabled ||
                                            _vm.release) &&
                                          _vm.selectedTeacher
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "mx-2",
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                    dark: ""
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.validar()
                                                                    }
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      dark: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "save"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " Salvar "
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    3865705400
                                                  )
                                                },
                                                [_c("span", [_vm._v("Salvar")])]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        {
                                          attrs: {
                                            colspan: _vm.headers.length - 4
                                          }
                                        },
                                        [
                                          _vm.selectedTeacher &&
                                          _vm.showEnableActivityTime
                                            ? _c("v-switch", {
                                                attrs: {
                                                  inset: "",
                                                  label: "Hora Atividade"
                                                },
                                                model: {
                                                  value: _vm.modelActivityHour,
                                                  callback: function($$v) {
                                                    _vm.modelActivityHour = $$v
                                                  },
                                                  expression:
                                                    "modelActivityHour"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c("td", { attrs: { colspan: "2" } }, [
                                        _vm.acessoValido &&
                                        (_vm.isAvailabilityEnabled ||
                                          _vm.release) &&
                                        _vm.selectedTeacher
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "mx-2",
                                                                    attrs: {
                                                                      "x-small":
                                                                        "",
                                                                      color: _vm.getColor(
                                                                        _vm.release
                                                                          ? 3
                                                                          : 1
                                                                      ),
                                                                      fab: "",
                                                                      dark: ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.disponibilizar(
                                                                          _vm.release
                                                                            ? 3
                                                                            : 1,
                                                                          true
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        dark: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-checkbox-marked-circle"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      93906249
                                                    )
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Disponibilizar Tudo"
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "mx-2",
                                                                    attrs: {
                                                                      color: _vm.getColor(
                                                                        0
                                                                      ),
                                                                      fab: "",
                                                                      "x-small":
                                                                        "",
                                                                      dark: ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.disponibilizar(
                                                                          0,
                                                                          true
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        dark: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "close"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      982620167
                                                    )
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Indisponibilizar Tudo"
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ])
                                    ])
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1535979837
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.selectedTeacher
            ? _c(
                "v-col",
                { attrs: { cols: "12", sm: "4" } },
                [
                  _vm.user.is_master || _vm.config.visibleBox.digital
                    ? _c(
                        "v-card",
                        { staticClass: "mx-auto", attrs: { outlined: "" } },
                        [
                          _c(
                            "v-app-bar",
                            {
                              staticClass: "elevation-0",
                              attrs: { dense: "" }
                            },
                            [
                              _c(
                                "v-toolbar-title",
                                { staticClass: "text-body-2" },
                                [
                                  _c("emc-info-help", {
                                    attrs: {
                                      title: "Disponibilidade EAD",
                                      text: _vm.isAvailabilityEnabled
                                        ? "Mova o balão para informar sua carga horária semanal disponível para as disciplinas EAD"
                                        : "Carga horária semanal disponível para disciplinas EAD:"
                                    }
                                  }),
                                  _c("strong", [_vm._v("Disponibilidade EAD")])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c("v-slider", {
                                attrs: {
                                  label: !_vm.isAvailabilityEnabled
                                    ? _vm.chMaxDigital.toString()
                                    : "0",
                                  "thumb-color": _vm.getThumbColor(),
                                  "thumb-label": "always",
                                  min: 0,
                                  max: _vm.config.maxWorkLoad,
                                  disabled:
                                    !_vm.release && !_vm.isAvailabilityEnabled,
                                  "persistent-hint":
                                    _vm.getAvailabilityTeacher() &&
                                    _vm.getAvailabilityTeacher()
                                      .digital_availability_teacher !=
                                      _vm.chMaxDigital,
                                  hint: _vm.getHint()
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.updateWorktLoadTotal(true)
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append",
                                      fn: function() {
                                        return [
                                          _c("div", {
                                            staticClass:
                                              "text-body-1 grey--text text--darken-1",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.config.maxWorkLoad
                                              )
                                            }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  808685001
                                ),
                                model: {
                                  value: _vm.chMaxDigital,
                                  callback: function($$v) {
                                    _vm.chMaxDigital = $$v
                                  },
                                  expression: "chMaxDigital"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-card",
                    {
                      staticClass: "pb-2 pa-0 mt-2",
                      attrs: { outlined: "", "max-height": "350" }
                    },
                    [
                      _c(
                        "v-app-bar",
                        { staticClass: "elevation-0", attrs: { dense: "" } },
                        [
                          _c(
                            "v-toolbar-title",
                            { staticClass: "text-body-2" },
                            [_c("strong", [_vm._v("Legenda")])]
                          )
                        ],
                        1
                      ),
                      _c("v-divider", { staticClass: "mb-2" }),
                      _c(
                        "div",
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "span",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "pb-2 pl-1 pr-0 grey--text text--darken-1",
                                                staticStyle: { cursor: "help" }
                                              },
                                              "span",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: _vm.getColor(0)
                                                }
                                              },
                                              [_vm._v(_vm._s(_vm.getIcon(0)))]
                                            ),
                                            _vm._v("Indisponível")
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3010914675
                              )
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "Horário indisponibilizado pelo professor"
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "span",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "pb-2 pl-1 pr-0 grey--text text--darken-1",
                                                staticStyle: { cursor: "help" }
                                              },
                                              "span",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: _vm.getColor(1)
                                                }
                                              },
                                              [_vm._v(_vm._s(_vm.getIcon(1)))]
                                            ),
                                            _vm._v("Disponível")
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3189893044
                              )
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " Horário disponibilizado pelo professor "
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _vm.showLegendRelease
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "span",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "pb-2 pl-1 pr-0 grey--text text--darken-1",
                                                    staticStyle: {
                                                      cursor: "help"
                                                    }
                                                  },
                                                  "span",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color: _vm.getColor(2)
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.getIcon(2))
                                                    )
                                                  ]
                                                ),
                                                _vm._v("Retirado")
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2986227483
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      " Horário retirado pela instituição "
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _vm.showLegendRelease
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "span",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "pb-2 pl-1 pr-0 grey--text text--darken-1",
                                                    staticStyle: {
                                                      cursor: "help"
                                                    }
                                                  },
                                                  "span",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color: _vm.getColor(3)
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.getIcon(3))
                                                    )
                                                  ]
                                                ),
                                                _vm._v("Liberado")
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    633758227
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      " Horário liberado pela instituição "
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _vm.showEnableActivityTime || _vm.showLegendActivity
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "span",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "pb-2 pl-1 pr-0 grey--text text--darken-1",
                                                    staticStyle: {
                                                      cursor: "help"
                                                    }
                                                  },
                                                  "span",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color: _vm.getColor(4)
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.getIcon(4))
                                                    )
                                                  ]
                                                ),
                                                _vm._v("Hora Atividade")
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3918865050
                                  )
                                },
                                [_c("span", [_vm._v(" Hora Atividade ")])]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.selectedTeacher &&
      (_vm.config.visibleBox.shifts || _vm.config.visibleBox.total)
        ? _c(
            "v-row",
            [
              _vm.config.visibleBox.shifts && _vm.config.showBox.morning
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "CH Desejável Manhã",
                          type: "number",
                          min: 0,
                          max: _vm.maxManha,
                          filled: ""
                        },
                        model: {
                          value: _vm.chMaxManha,
                          callback: function($$v) {
                            _vm.chMaxManha = $$v
                          },
                          expression: "chMaxManha"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.config.visibleBox.shifts && _vm.config.showBox.evening
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "CH Desejável Tarde",
                          type: "number",
                          min: 0,
                          max: _vm.maxTarde,
                          filled: ""
                        },
                        model: {
                          value: _vm.chMaxTarde,
                          callback: function($$v) {
                            _vm.chMaxTarde = $$v
                          },
                          expression: "chMaxTarde"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.config.visibleBox.shifts && _vm.config.showBox.night
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "CH Desejável Noite",
                          type: "number",
                          min: 0,
                          max: _vm.maxNoite,
                          filled: ""
                        },
                        model: {
                          value: _vm.chMaxNoite,
                          callback: function($$v) {
                            _vm.chMaxNoite = $$v
                          },
                          expression: "chMaxNoite"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.config.visibleBox.total
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          type: "number",
                          min: 0,
                          max: _vm.config.maxWorkLoad,
                          filled: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function() {
                                return [
                                  _c("emc-info-help", {
                                    attrs: {
                                      title: "Carga Horária Máxima",
                                      text:
                                        "Após informar sua disponibilidade de dias e horários, é possível informar também a carga horária máxima semanal que deseja lecionar"
                                    }
                                  }),
                                  _vm._v(" CH Máxima Desejada ")
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          4182669226
                        ),
                        model: {
                          value: _vm.chMaxSemanal,
                          callback: function($$v) {
                            _vm.chMaxSemanal = $$v
                          },
                          expression: "chMaxSemanal"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c("emc-alert-modal", {
        attrs: {
          title: "Salvar Disponibilidade",
          colorIcon: "success",
          icon: "mdi-content-save-move-outline",
          show: _vm.dialog
        },
        on: {
          "update:show": function($event) {
            _vm.dialog = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "text",
            fn: function() {
              return [
                !_vm.release &&
                _vm.workTime &&
                _vm.workTime.show_agree_term &&
                _vm.workTime.term
                  ? _c(
                      "div",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "v-alert",
                          {
                            attrs: {
                              type: "info",
                              border: "left",
                              elevation: "2",
                              "colored-border": ""
                            }
                          },
                          [
                            _c(
                              "emc-util-scroll",
                              { attrs: { maxHeight: "300px" } },
                              [
                                _c("div", {
                                  staticClass: "ma-1",
                                  domProps: {
                                    innerHTML: _vm._s(_vm.workTime.term.content)
                                  }
                                }),
                                _c("a", { attrs: { id: "term" } }),
                                _vm.workTime.term.is_required
                                  ? _c("v-checkbox", {
                                      staticClass: "ma-2",
                                      attrs: {
                                        label: _vm.workTime.term.label,
                                        color: "primary",
                                        "hide-details": ""
                                      },
                                      model: {
                                        value: _vm.agree_term,
                                        callback: function($$v) {
                                          _vm.agree_term = $$v
                                        },
                                        expression: "agree_term"
                                      }
                                    })
                                  : _c(
                                      "span",
                                      [
                                        _vm.teacher &&
                                        _vm.teacher.realInstitution
                                          ? _c(
                                              "v-radio-group",
                                              {
                                                staticClass: "ma-2",
                                                attrs: { row: "" },
                                                model: {
                                                  value: _vm.term_option_id,
                                                  callback: function($$v) {
                                                    _vm.term_option_id = $$v
                                                  },
                                                  expression: "term_option_id"
                                                }
                                              },
                                              _vm._l(
                                                _vm.teacher.realInstitution
                                                  .term_options,
                                                function(opt, i) {
                                                  return _c("v-radio", {
                                                    key: i,
                                                    attrs: {
                                                      label: opt.label,
                                                      value: opt.id,
                                                      color: opt.color
                                                    }
                                                  })
                                                }
                                              ),
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-alert",
                  {
                    attrs: {
                      color: "success",
                      border: "left",
                      elevation: "2",
                      "colored-border": ""
                    }
                  },
                  [
                    _c("div", { staticClass: "text-center" }, [
                      _c("strong", [
                        _vm._v("Confirma o envio da sua disponibilidade?")
                      ]),
                      _c("br"),
                      _c("small", [_vm._v(_vm._s(_vm.textoConfirmacao))])
                    ])
                  ]
                )
              ]
            },
            proxy: true
          },
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.saveAvailability(3)
                      }
                    }
                  },
                  [
                    _vm._v("Enviar"),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("send")
                    ])
                  ],
                  1
                ),
                _vm.workTime && _vm.workTime.availability_single
                  ? _c(
                      "v-btn",
                      {
                        attrs: { color: "orange" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.saveAvailability(2)
                          }
                        }
                      },
                      [
                        _vm._v("Salvar"),
                        _c("v-icon", { attrs: { right: "", dark: "" } }, [
                          _vm._v("save")
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-snackbar", {
        attrs: {
          timeout: 20000,
          show: _vm.snackbar,
          color: _vm.color,
          message: _vm.texto
        },
        on: {
          "update:show": function($event) {
            _vm.snackbar = $event
          }
        },
        scopedSlots: _vm._u(
          [
            _vm.showOpenNewLink
              ? {
                  key: "action",
                  fn: function() {
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "float-right",
                          attrs: { text: "", outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.$router.push("access-teacher")
                            }
                          }
                        },
                        [_vm._v(" Gerar Novo Link ")]
                      )
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }