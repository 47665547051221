export default [
    {
        path: '/vacancy-control-groups',
        name: 'vacancy-control-groups',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Controle de Vagas da Turma',
            icon: 'mdi-car-cruise-control',
            breadcrumbs: [
                { text: 'Controle de Vagas da Turma', disabled: true }
            ]
        }
    }
]