var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: {
        icon: "mdi-view-grid-plus-outline",
        title: "Disciplinas das Turmas"
      },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return [
              (_vm.isAnima() && _vm.user.is_master) || _vm.user.is_internal
                ? _c("emc-excel-import-icon", {
                    attrs: { param: "offer_grids" },
                    on: {
                      onCompleted: function($event) {
                        return _vm.getData()
                      }
                    }
                  })
                : _vm._e(),
              !_vm.isAnima()
                ? _c("emc-excel-import-icon", {
                    attrs: {
                      param: "grids_single",
                      color: "primary",
                      "tool-tip":
                        "Importar Disciplinas das Turmas (Simplificado)"
                    },
                    on: {
                      onCompleted: function($event) {
                        return _vm.getData()
                      }
                    }
                  })
                : _vm._e(),
              !_vm.isAnima()
                ? _c("emc-excel-import-icon", {
                    attrs: {
                      param: "grids",
                      color: "purple darken-1",
                      "tool-tip": "Importar Disciplinas das Turmas (Detalhado)"
                    },
                    on: {
                      onCompleted: function($event) {
                        return _vm.getData()
                      }
                    }
                  })
                : _vm._e(),
              _vm.isAnima() && _vm.user.is_master
                ? _c("s-icon-report-download", {
                    attrs: { report: "80", "show-message": "" }
                  })
                : _vm._e(),
              !_vm.isAnima()
                ? _c("s-icon-report-download", {
                    attrs: { report: "3", "show-message": "" }
                  })
                : _vm._e(),
              _vm.acesso.instituicao && _vm.acesso.instituicao.id == 2
                ? _c("s-icon-report-download", {
                    attrs: {
                      report: "115",
                      color: "primary",
                      "tool-tip": "Oferta SysClass/Lyceum",
                      "show-message": ""
                    }
                  })
                : _vm._e(),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: { color: "indigo darken-1", icon: "" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      _vm.showForm = true
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-plus")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v(" Cadastrar Disciplina da Turma")])]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("emc-loader-progress", { attrs: { show: _vm.loadingProgress } }),
      _c("sys-grid-filter", {
        attrs: { show: _vm.dialog },
        on: {
          "update:show": function($event) {
            _vm.dialog = $event
          },
          filter: function($event) {
            return _vm.filter($event)
          }
        }
      }),
      _c("emc-table-items", {
        attrs: {
          headers: _vm.headers,
          params: _vm.params,
          collections: _vm.collections,
          meta: _vm.meta,
          itemDelete: _vm.itemDelete,
          itemEdit: _vm.model,
          loading: _vm.loading,
          find: true,
          searchLength: 2,
          showAppend: "",
          label: "Pesquisar..."
        },
        on: {
          "update:params": function($event) {
            _vm.params = $event
          },
          "update:collections": function($event) {
            _vm.collections = $event
          },
          "update:meta": function($event) {
            _vm.meta = $event
          },
          "update:itemDelete": function($event) {
            _vm.itemDelete = $event
          },
          "update:item-delete": function($event) {
            _vm.itemDelete = $event
          },
          "update:itemEdit": function($event) {
            _vm.model = $event
          },
          "update:item-edit": function($event) {
            _vm.model = $event
          }
        },
        scopedSlots: _vm._u(
          [
            _vm.isAnima()
              ? {
                  key: "optionsFilter",
                  fn: function() {
                    return [
                      _c("v-select", {
                        attrs: {
                          items: _vm.optionsFilter,
                          label: "Pesquisar Por:",
                          "return-object": "",
                          "hide-details": "",
                          dense: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prepend-item",
                              fn: function() {
                                return [
                                  _c("v-switch", {
                                    staticClass: "ma-0 pa-0 ml-3",
                                    attrs: {
                                      dense: "",
                                      inset: "",
                                      label: "Busca Aproximada",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.approximate,
                                      callback: function($$v) {
                                        _vm.approximate = $$v
                                      },
                                      expression: "approximate"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          238570306
                        ),
                        model: {
                          value: _vm.optionFilter,
                          callback: function($$v) {
                            _vm.optionFilter = $$v
                          },
                          expression: "optionFilter"
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              : null,
            {
              key: "append",
              fn: function() {
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        color: _vm.payloadFilter
                                          ? "primary"
                                          : "grey",
                                        icon: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          _vm.dialog = true
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { btn: "" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.payloadFilter
                                          ? "mdi-filter-menu-outline"
                                          : "mdi-filter-outline"
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v(" Demais Filtros ")])]
                  )
                ]
              },
              proxy: true
            },
            {
              key: "item.group",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(" " + _vm._s(item.group.name) + " "),
                  item.workTimeDate
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              small: "",
                                              color: "primary"
                                            }
                                          },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v("event")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(" " + _vm._s(item.workTimeDate.name) + " "),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("date")(
                                    item.workTimeDate.classes_start
                                  )
                                ) +
                                " - " +
                                _vm._s(
                                  _vm._f("date")(item.workTimeDate.classes_end)
                                ) +
                                " "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  item.group.time_course
                    ? _c("small", [
                        _c("br"),
                        _vm._v(" " + _vm._s(item.group.time_course) + " ")
                      ])
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.discipline_name",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(" " + _vm._s(item.discipline_name)),
                  _vm.acesso.instituicao &&
                  _vm.acesso.instituicao.code == "3" &&
                  item.discipline.code
                    ? _c("span", { staticClass: "mr-1" }, [
                        _vm._v(" - " + _vm._s(item.discipline.code))
                      ])
                    : _vm._e(),
                  item.category && !item.category.default
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-chip",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticStyle: { cursor: "help" },
                                            attrs: {
                                              color: "purple",
                                              label: "",
                                              "x-small": "",
                                              outlined: ""
                                            }
                                          },
                                          "v-chip",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.category.initials) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [_c("span", [_vm._v(_vm._s(item.category.name))])]
                      )
                    : _vm._e(),
                  item.sub_group_id || item.sub_group
                    ? _c("small", [
                        _c("br"),
                        _vm._v(
                          " " +
                            _vm._s(
                              item.sub_group
                                ? item.sub_group
                                : item.subGroup.name
                            ) +
                            " "
                        )
                      ])
                    : _vm._e(),
                  item.specificity
                    ? _c("small", [
                        _c("br"),
                        _vm._v(" [" + _vm._s(item.specificity.name) + "] ")
                      ])
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.work_load",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        item.work_load_type.initials + " = " + item.work_load
                      ) +
                      " "
                  )
                ]
              }
            },
            {
              key: "item.teacherName",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm.getAttribution(item, 1)
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.getAttribution(item, 1).teacher.name))
                      ])
                    : _vm.getAttribution(item, 5)
                    ? _c(
                        "span",
                        { staticClass: "caption grey--text font-weight-light" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.getAttribution(item, 5).teacher.name) +
                              " "
                          ),
                          _c("br"),
                          _c("small", [
                            _vm._v("Início: "),
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("date")(
                                    _vm.getAttribution(item, 5).date_start
                                  )
                                )
                              )
                            ])
                          ])
                        ]
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "actions",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                item.join
                                  ? _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              color: "purple",
                                              small: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.showJoinGroups(item)
                                              }
                                            }
                                          },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-call-split ")]
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [_c("span", [_vm._v(" Ver turmas da Junção ")])]
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _c("sys-register-grid", {
        attrs: { model: _vm.model, show: _vm.showForm },
        on: {
          onCompleted: function($event) {
            return _vm.getData()
          },
          "update:show": function($event) {
            _vm.showForm = $event
          }
        }
      }),
      _vm.item && _vm.item.join && _vm.item.join.join_id
        ? _c("emc-alert-modal", {
            attrs: {
              show: _vm.showJoin,
              title: "Junção [" + _vm.item.join.join_id + "]"
            },
            on: {
              "update:show": function($event) {
                _vm.showJoin = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "text",
                  fn: function() {
                    return [
                      _vm.item && _vm.item.join
                        ? _c(
                            "ul",
                            _vm._l(_vm.item.joins, function(join) {
                              return _c(
                                "li",
                                { key: join.grid_id },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        join.group.campus.abbreviation +
                                          " | " +
                                          join.group.name +
                                          " | " +
                                          join.discipline.name
                                      ) +
                                      " "
                                  ),
                                  join.join.main
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticStyle: {
                                                              cursor: "help"
                                                            },
                                                            attrs: {
                                                              "x-small": "",
                                                              icon: ""
                                                            }
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              "x-small": "",
                                                              color: "amber",
                                                              btn: ""
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-star-half-full"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(" Turma Principal ")
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1414130616
            )
          })
        : _vm._e(),
      _c("emc-alert-modal", {
        attrs: { text: _vm.textDelete, disabled: _vm.deleting, show: _vm.show },
        on: {
          "update:show": function($event) {
            _vm.show = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "error",
                      loading: _vm.deleting,
                      disabled: _vm.deleting
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.deleteItem()
                      }
                    }
                  },
                  [
                    _vm._v("Excluir "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-snackbar", {
        attrs: {
          show: !!_vm.message.length,
          message: _vm.message,
          color: _vm.error ? "error" : "success"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }