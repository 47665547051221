var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading ? _c("EmcChartLoading") : _vm._e(),
      _c(
        "v-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading"
            }
          ],
          staticClass: "pa-2"
        },
        [
          _vm.renderComponent
            ? _c("canvas", {
                attrs: { id: _vm.name, width: _vm.width, height: _vm.height }
              })
            : _vm._e(),
          _c(
            "v-card-actions",
            [
              _c("ChartInfoBudget", {
                attrs: {
                  title:
                    "Indicador consolidado de sala de aula e outras atividades"
                }
              }),
              _vm.process
                ? _c("span", [
                    _vm.process.updated_last
                      ? _c("small", [
                          _vm._v(
                            " Atualização: " +
                              _vm._s(
                                _vm._f("dateTime")(_vm.process.updated_last)
                              ) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    _c("small", [
                      _vm.process.budget_process_status_id <= 3
                        ? _c("strong", [
                            _vm._v(
                              "(" + _vm._s(_vm.process.status.name) + "...)"
                            )
                          ])
                        : _vm._e()
                    ])
                  ])
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "v-btn-toggle",
                {
                  staticClass: "mr-1",
                  attrs: { borderless: "", color: "deep-purple accent-3" },
                  model: {
                    value: _vm.valueType,
                    callback: function($$v) {
                      _vm.valueType = $$v
                    },
                    expression: "valueType"
                  }
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { "x-small": "", value: "1" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.forceRerender()
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "hidden-sm-and-down" }, [
                        _vm._v("H/A")
                      ]),
                      _c("v-icon", { attrs: { "x-small": "", right: "" } }, [
                        _vm._v(" mdi-calendar-clock ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { "x-small": "", value: "2" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.forceRerender()
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "hidden-sm-and-down" }, [
                        _vm._v("R$")
                      ]),
                      _c("v-icon", { attrs: { "x-small": "", right: "" } }, [
                        _vm._v(" mdi-currency-brl ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.user.is_admin
                ? _c("emc-button-icon", {
                    attrs: {
                      loading: _vm.loadingGenerate,
                      icon: "mdi-flash",
                      text: "Processar",
                      color: "amber"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.refresh()
                      }
                    }
                  })
                : _vm._e(),
              _c("emc-button-icon", {
                attrs: {
                  loading: _vm.loadingRefresh,
                  icon: "mdi-refresh-circle",
                  text: "Atualizar",
                  color: "success"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.forceRerender()
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }