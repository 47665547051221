<template>
    <div>
        <sys-pop-over-config-base :model="model" :configs="configs" :errors="errors">

            <template v-slot:toolbar>
                <emc-button-icon icon="mdi-content-save" :text="error ? message : 'Salvar'" :loading="loadingSaving" :color="error ? 'error' : 'success'" @click="update()" />
            </template>

        </sys-pop-over-config-base>
    </div>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: "SysInstitutionPopOverSetting",
    data: () => ({
      model: {},
      modelOrigin: {},
      error: false,
      message: null,
      errors: {},
      loadingSaving: false,
      configs: [
        { 
            type: 'boolean',   
            field: 'totvs_integrate_only_main',                            
            label: 'Integrar apenas turmas principais',               
            textHelper: "Integrar apenas as turmas principais da junção. Desconsiderar as turmas secundárias.",
            sm: 6
        },
        { 
            type: 'boolean',   
            field: 'totvs_integrate_main',                            
            label: 'Integrar turmas gerenciais',               
            textHelper: "Integrar turmas gerenciais para o SysClass",
            sm: 6
        },
        { 
            type: 'boolean',   
            field: 'totvs_create_group_code',                            
            label: 'Criar código da turma',               
            textHelper: "Criar código da turma baseado no nome na integração da turma do SysClass para o Totvs",
            sm: 6
        },
        { 
            type: 'boolean',   
            field: 'totvs_create_group_initials',                            
            label: 'Criar nome reduzido da turma',               
            textHelper: "Criar nome reduzido da turma baseado no nome na integração da turma do SysClass para o Totvs",
            sm: 6
        },
        { 
            type: 'boolean',   
            field: 'totvs_integrate_sub_group_as_group',                            
            label: 'Integrar subturma como uma turma',               
            textHelper: "Integrar subturma do SysClass para o Totvs como cadastro de turma",
            sm: 6
        },
        { 
            type: 'boolean',   
            field: 'totvs_integration_campus_code',                            
            label: 'Integrar código do campus',               
            textHelper: "Integrar código do campus na integração da turma disciplina do SysClass para o Totvs",
            sm: 6
        },
        { 
            type: 'boolean',   
            field: 'totvs_integration_group_type',                            
            label: 'Integrar tipo de turma',               
            textHelper: "Integrar tipo de turma no campo complementar da turma baseado no código da categoria da turma na integração da turma do SysClass para o Totvs",
            sm: 6
        },
        { 
            type: 'boolean',   
            field: 'totvs_integrate_ambience_with_time_table',                            
            label: 'Integrar ambiente com horário de aula',               
            textHelper: "Integrar ambiente ao integrar os horários de aula do SysClass para o Totvs",
            sm: 6
        },
        { 
            type: 'boolean',   
            field: 'totvs_integrate_time_table_by_date',                            
            label: 'Integrar horário por data',               
            textHelper: "Integrar horário semanal por data do SysClass para o Totvs",
            sm: 6
        },
        { 
            type: 'boolean',   
            field: 'totvs_integration_with_sub_group',                            
            label: 'Integrar horário com subturma',               
            textHelper: "Integrar horário com subturma do SysClass para o Totvs",
            sm: 6
        },
        { 
            type: 'boolean',   
            field: 'totvs_integration_date_mask_code',                            
            label: 'Integrar tipo de máscara',               
            textHelper: "Integrar tipo de máscara como campo complementar ao integrar a turma disciplina do SysClass para o Totvs",
            sm: 6
        },
        { 
            type: 'boolean',   
            field: 'totvs_integration_required_curriculum_code',                            
            label: 'Obrigatório Matriz Aplicada',               
            textHelper: "Considerar o campo Matriz Aplicada como obrigatório ao integrar a turma do SysClass para o Totvs",
            sm: 6
        },
        { 
            type: 'boolean',   
            field: 'totvs_delete_grids_on_integration',                            
            label: 'Deletar grades',               
            textHelper: "Deletar grades do SysClass na integração de Turma Disciplina do Totvs para o SysClass quando não existir no Totvs.",
            sm: 6
        },
        { 
            type: 'boolean',   
            field: 'totvs_integrate_ambience_grid',                            
            label: 'Ambiente na Grade',               
            textHelper: "Integrar ambiente na Turma Disciplina durante a integração do ambiente no horário.",
            sm: 6
        },
        { 
            type: 'boolean',   
            field: 'totvs_activity_work_load_month',                            
            label: 'Atividade Mensal',               
            textHelper: "Converter carga horária para mensal durante a integração",
            sm: 6
        },
        { 
            type: 'text-field',   
            field: 'totvs_type_teacher',                            
            label: 'Tipo Professor',               
            textHelper: 'Tipo de Professor na integração de Professor da Turma',
            clearable: true,
            sm: 4
        },
        { 
            type: 'text-field',   
            field: 'totvs_key_join',                            
            label: 'Campo chave junção',               
            textHelper: "Campo chave para identificar as turmas disciplinas da mesma junção. Padrão: 'IDTURMADISC'",
            clearable: true,
            placeholder: 'IDTURMADISC',
            sm: 4
        },
        { 
            type: 'text-field',   
            field: 'totvs_regex_sub_group',                            
            label: 'Expressão SubTurma',               
            textHelper: "Expressão regular para identificar o número da divisão da subturma ao importar do Totvs para o SysClass",
            clearable: true,
            placeholder: '\\d+$',
            sm: 4
        },
        { 
            type: 'text-field',   
            field: 'totvs_has_salary',                            
            label: 'Compõe Salário',               
            textHelper: "Preencher campo Compõe Salário ao integrar o professor da Turma",
            clearable: true,
            placeholder: 'S/N',
            sm: 4
        },
      ]
    }),
    props: {
        institution: {
            type: Object,
            required: true,
        },
    },
    mounted(){
        this.getData();
    },
    watch: {
        institution(){
            this.getData();
        },

        // model (){
        //     return JSON.stringify(this.modelOrigin) !== JSON.stringify(this.model);
        // }
    },
    methods: {
        ...mapActions('institution', ['ActionShowGeneralSetting', 'ActionUpdateGeneralSetting']),

        getData(){

            this.model = {};

            if(!this.institution || !this.institution.settings){
                return;
            }

            let payload = {
                id: this.institution.settings.id
            }

            this.ActionShowGeneralSetting(payload)
                    .then((res) => {
                        
                        let data = res.data;
                        
                        this.model.id = data.id;

                        this.configs.forEach(cnf => {
                            this.model[cnf.field] = data[cnf.field];
                        });

                        Object.assign(this.modelOrigin, this.model);

                    })
                    .finally(() => {

                    })


        },

        update(){
            
            if(!this.model || !this.model.id){
                return;
            }

            this.loadingSaving = true;
            this.errors = {};
            this.error = false;
            this.message = null;

            this.ActionUpdateGeneralSetting(this.model)
                    .then((res) => {
                        
                    }).catch((error) =>{
                        this.message = error.message;
                        this.error = error;
                        this.errors = error.errors;
                    })
                    .finally(() => {
                        this.loadingSaving = false;
                    })

        }

    }
}
</script>