<template>
    <emc-info-help :title="title">
                                                            
        <template v-slot:text>
            
            <strong>Orçado</strong>
            <br/>
            Valor Orçado
            <br/>
            
            <strong>Previsto</strong>
            <br/>
            Valor Previsto na Oferta
            <br/>

            <strong>Empenhado</strong>
            <br/>
            Valor Empenhado com Professor
            <br/>

            <strong>Realizado</strong>
            <br/>
            Valor Processado na Folha de Pagto

        </template>

    </emc-info-help>

</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: 'ChartInfoBudget',
    data () {
        return {
            
        }
    },
    props: { 
        title: { type: String, default: 'Informações' }
    },
    computed: {
        
    },
    methods: {
        
    },
    created() {
    
    }

}
</script>