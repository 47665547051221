var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: "mdi-account-group-outline",
      show: _vm.dialog,
      maxWidth: "60%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u(
      [
        {
          key: "form",
          fn: function() {
            return [
              _c(
                "v-tabs",
                {
                  staticClass: "ma-0",
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c("v-tab", { key: "main" }, [_vm._v("Dados")]),
                  _c("v-tab", { key: "offer" }, [_vm._v("Oferta")]),
                  _c("v-tab", { key: "others" }, [_vm._v("Demais Dados")]),
                  _c("v-tab", { key: "students" }, [_vm._v("Alunos")]),
                  _vm.model && _vm.model.id
                    ? _c("v-tab", { key: "sub_groups" }, [_vm._v("Sub-turmas")])
                    : _vm._e(),
                  _vm.model && _vm.model.id
                    ? _c("v-tab", { key: "availability" }, [
                        _vm._v("Disponibilidade")
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    { key: "main" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("sys-select-course", {
                                attrs: {
                                  model: _vm.model.course_id,
                                  error: _vm.errors.length > 0,
                                  errors: _vm.errors.course_id,
                                  label: "Curso*",
                                  disabled: _vm.disableCourse,
                                  autoComplete: ""
                                },
                                on: {
                                  "update:model": function($event) {
                                    return _vm.$set(
                                      _vm.model,
                                      "course_id",
                                      $event
                                    )
                                  },
                                  onLoad: _vm.loadCourse
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  autofocus: "",
                                  error: _vm.errors.length > 0,
                                  "error-messages": _vm.errors.name,
                                  label: "Nome*",
                                  required: ""
                                },
                                model: {
                                  value: _vm.model.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.model, "name", $$v)
                                  },
                                  expression: "model.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("sys-select-shift", {
                                attrs: {
                                  model: _vm.model.shift_id,
                                  error: _vm.errors.length > 0,
                                  errors: _vm.errors.shift_id,
                                  label: "Turno*",
                                  dense: ""
                                },
                                on: {
                                  "update:model": function($event) {
                                    return _vm.$set(
                                      _vm.model,
                                      "shift_id",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("sys-select-status-group", {
                                attrs: {
                                  model: _vm.model.status_group_id,
                                  error: _vm.errors.length > 0,
                                  errors: _vm.errors.status_group_id,
                                  label: "Status*",
                                  dense: ""
                                },
                                on: {
                                  "update:model": function($event) {
                                    return _vm.$set(
                                      _vm.model,
                                      "status_group_id",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("sys-select-campus", {
                                attrs: {
                                  model: _vm.model.campus_id,
                                  error: _vm.errors.length > 0,
                                  errors: _vm.errors.campus_id,
                                  label: "Campus*",
                                  dense: "",
                                  "auto-complete": ""
                                },
                                on: {
                                  "update:model": function($event) {
                                    return _vm.$set(
                                      _vm.model,
                                      "campus_id",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("sys-select-category-group", {
                                attrs: {
                                  model: _vm.model.category_group_id,
                                  error: _vm.errors.length > 0,
                                  errors: _vm.errors.category_group_id,
                                  label: "Categoria",
                                  clearable: "",
                                  dense: ""
                                },
                                on: {
                                  "update:model": function($event) {
                                    return _vm.$set(
                                      _vm.model,
                                      "category_group_id",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "2" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  error:
                                    _vm.errors.group && _vm.errors.length > 0,
                                  "error-messages": _vm.errors.group,
                                  label: "Turma",
                                  required: ""
                                },
                                model: {
                                  value: _vm.model.group,
                                  callback: function($$v) {
                                    _vm.$set(_vm.model, "group", $$v)
                                  },
                                  expression: "model.group"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "2" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  error:
                                    _vm.errors.time_course &&
                                    _vm.errors.length > 0,
                                  "error-messages": _vm.errors.time_course,
                                  label: "Período",
                                  required: ""
                                },
                                model: {
                                  value: _vm.model.time_course,
                                  callback: function($$v) {
                                    _vm.$set(_vm.model, "time_course", $$v)
                                  },
                                  expression: "model.time_course"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "3" } },
                            [
                              _c("s-text-field", {
                                attrs: {
                                  dense: "",
                                  code: "",
                                  "error-messages": _vm.errors.code,
                                  label: "Código"
                                },
                                model: {
                                  value: _vm.model.code,
                                  callback: function($$v) {
                                    _vm.$set(_vm.model, "code", $$v)
                                  },
                                  expression: "model.code"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "3" } },
                            [
                              _c("s-text-field", {
                                attrs: {
                                  dense: "",
                                  code: "",
                                  "error-messages": _vm.errors.code_control,
                                  label: "Código Controle"
                                },
                                model: {
                                  value: _vm.model.code_control,
                                  callback: function($$v) {
                                    _vm.$set(_vm.model, "code_control", $$v)
                                  },
                                  expression: "model.code_control"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { key: "offer" },
                    [
                      _c(
                        "v-container",
                        [
                          _vm.showOffer
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "12" } },
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c(
                                            "v-toolbar-title",
                                            [
                                              _c(
                                                "v-app-bar",
                                                {
                                                  staticClass:
                                                    "mb-4 elevation-1",
                                                  attrs: {
                                                    dense: "",
                                                    height: "45"
                                                  }
                                                },
                                                [
                                                  _c("v-icon", {
                                                    attrs: { left: "" }
                                                  }),
                                                  _c(
                                                    "v-toolbar-title",
                                                    {
                                                      staticClass: "subtitle-1"
                                                    },
                                                    [
                                                      _vm._t("title", [
                                                        _vm._v("Oferta "),
                                                        _c("emc-info-help", {
                                                          attrs: {
                                                            title:
                                                              "Tipos de Oferta"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "text",
                                                                fn: function() {
                                                                  return [
                                                                    _c(
                                                                      "strong",
                                                                      [
                                                                        _vm._v(
                                                                          "Oferta avulsa"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c("br"),
                                                                    _vm._v(
                                                                      " Oferta de disciplinas avulsas, pertencentes ou não a uma matriz. "
                                                                    ),
                                                                    _c("br"),
                                                                    _c("br"),
                                                                    _c(
                                                                      "strong",
                                                                      [
                                                                        _vm._v(
                                                                          "Oferta por módulo"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c("br"),
                                                                    _vm._v(
                                                                      " Oferta de um módulo completo de uma matriz. "
                                                                    ),
                                                                    _c("br"),
                                                                    _c("br"),
                                                                    _c(
                                                                      "strong",
                                                                      [
                                                                        _vm._v(
                                                                          "Oferta por disciplina"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c("br"),
                                                                    _vm._v(
                                                                      " Oferta de disciplinas de uma matriz, independente de estarem no mesmo módulo. "
                                                                    )
                                                                  ]
                                                                },
                                                                proxy: true
                                                              }
                                                            ],
                                                            null,
                                                            false,
                                                            1400833548
                                                          )
                                                        })
                                                      ])
                                                    ],
                                                    2
                                                  ),
                                                  _c("v-spacer"),
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            ""
                                                                        },
                                                                        nativeOn: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.clearOffer()
                                                                          }
                                                                        }
                                                                      },
                                                                      "v-btn",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "error lighten-2"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-eraser"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        270503415
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v("Limpar")
                                                      ])
                                                    ]
                                                  ),
                                                  !_vm.workTime
                                                    .auto_generate_grids &&
                                                  _vm.model &&
                                                  _vm.model.id
                                                    ? _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                ""
                                                                            },
                                                                            nativeOn: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                _vm.showGenerateOffer = true
                                                                              }
                                                                            }
                                                                          },
                                                                          "v-btn",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs: {
                                                                              color:
                                                                                "orange lighten-2"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-flash"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            false,
                                                            2063612428
                                                          )
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Gerar Oferta"
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c("v-spacer")
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "ma-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-radio-group",
                                                        {
                                                          attrs: { row: "" },
                                                          on: {
                                                            change:
                                                              _vm.changeOfferType
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.model
                                                                .offer_type_id,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.model,
                                                                "offer_type_id",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "model.offer_type_id"
                                                          }
                                                        },
                                                        [
                                                          _c("v-radio", {
                                                            attrs: {
                                                              label:
                                                                "Oferta Avulsa",
                                                              color:
                                                                "red darken-3",
                                                              value: 1
                                                            }
                                                          }),
                                                          _c("v-radio", {
                                                            attrs: {
                                                              label:
                                                                "Por Módulo",
                                                              color:
                                                                "blue darken-3",
                                                              value: 2
                                                            }
                                                          }),
                                                          _c("v-radio", {
                                                            attrs: {
                                                              label:
                                                                "Por Disciplina",
                                                              color:
                                                                "green darken-3",
                                                              value: 3
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm.offerTypeMode == 1
                                                ? _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "12"
                                                          }
                                                        },
                                                        [
                                                          _c("v-data-table", {
                                                            staticClass:
                                                              "elevation-1",
                                                            attrs: {
                                                              headers:
                                                                _vm.headers,
                                                              items:
                                                                _vm.model
                                                                  .disciplines,
                                                              "sort-by": "name",
                                                              "hide-default-footer":
                                                                ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "top",
                                                                  fn: function() {
                                                                    return [
                                                                      _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            bottom:
                                                                              ""
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  var attrs =
                                                                                    ref.attrs
                                                                                  return [
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      _vm._g(
                                                                                        _vm._b(
                                                                                          {
                                                                                            attrs: {
                                                                                              color:
                                                                                                "indigo darken-1",
                                                                                              icon:
                                                                                                ""
                                                                                            },
                                                                                            on: {
                                                                                              click: function(
                                                                                                $event
                                                                                              ) {
                                                                                                $event.preventDefault()
                                                                                                $event.stopPropagation()
                                                                                                _vm.dialogAddDiscipline = true
                                                                                              }
                                                                                            }
                                                                                          },
                                                                                          "v-btn",
                                                                                          attrs,
                                                                                          false
                                                                                        ),
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "mdi-plus"
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            false,
                                                                            1879678342
                                                                          )
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                " Incluir Disciplina "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  },
                                                                  proxy: true
                                                                },
                                                                {
                                                                  key:
                                                                    "item.actions",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var item =
                                                                      ref.item
                                                                    return [
                                                                      _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            bottom:
                                                                              ""
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  var attrs =
                                                                                    ref.attrs
                                                                                  return [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      _vm._g(
                                                                                        _vm._b(
                                                                                          {
                                                                                            attrs: {
                                                                                              small:
                                                                                                "",
                                                                                              color:
                                                                                                "red"
                                                                                            },
                                                                                            on: {
                                                                                              click: function(
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.showDeleteItem(
                                                                                                  item
                                                                                                )
                                                                                              }
                                                                                            }
                                                                                          },
                                                                                          "v-icon",
                                                                                          attrs,
                                                                                          false
                                                                                        ),
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _vm._v(
                                                                                          " mdi-delete "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                " Excluir Disciplina "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              false,
                                                              868990248
                                                            )
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.offerTypeMode != 1
                                                ? _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "5"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "sys-select-curriculum",
                                                            {
                                                              attrs: {
                                                                model:
                                                                  _vm.model
                                                                    .curriculum_id,
                                                                error:
                                                                  _vm.errors
                                                                    .length > 0,
                                                                errors:
                                                                  _vm.errors
                                                                    .curriculum_id,
                                                                label:
                                                                  "Matriz Curricular",
                                                                courseId:
                                                                  _vm.model
                                                                    .course_id,
                                                                clearable: "",
                                                                modelObject:
                                                                  _vm.selectedCurriculum,
                                                                autoComplete:
                                                                  "",
                                                                dense: ""
                                                              },
                                                              on: {
                                                                "update:model": function(
                                                                  $event
                                                                ) {
                                                                  return _vm.$set(
                                                                    _vm.model,
                                                                    "curriculum_id",
                                                                    $event
                                                                  )
                                                                },
                                                                "update:modelObject": function(
                                                                  $event
                                                                ) {
                                                                  _vm.selectedCurriculum = $event
                                                                },
                                                                "update:model-object": function(
                                                                  $event
                                                                ) {
                                                                  _vm.selectedCurriculum = $event
                                                                },
                                                                clearSelected: function(
                                                                  $event
                                                                ) {
                                                                  _vm.selectedCurriculum = {}
                                                                }
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "7"
                                                          }
                                                        },
                                                        [
                                                          _vm.offerTypeMode != 3
                                                            ? _c("v-select", {
                                                                attrs: {
                                                                  items:
                                                                    _vm.sequencies,
                                                                  label:
                                                                    "Módulo da Matriz",
                                                                  "item-value":
                                                                    "id",
                                                                  "item-text":
                                                                    "name",
                                                                  error:
                                                                    _vm.errors
                                                                      .length >
                                                                    0,
                                                                  "error-messages":
                                                                    _vm.errors
                                                                      .curriculum_sequency_item_id,
                                                                  disabled:
                                                                    !_vm.selectedCurriculum ||
                                                                    !_vm
                                                                      .selectedCurriculum
                                                                      .id,
                                                                  dense: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "item",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var item =
                                                                          ref.item
                                                                        return [
                                                                          item.description
                                                                            ? _c(
                                                                                "v-tooltip",
                                                                                {
                                                                                  attrs: {
                                                                                    bottom:
                                                                                      ""
                                                                                  },
                                                                                  scopedSlots: _vm._u(
                                                                                    [
                                                                                      {
                                                                                        key:
                                                                                          "activator",
                                                                                        fn: function(
                                                                                          ref
                                                                                        ) {
                                                                                          var on =
                                                                                            ref.on
                                                                                          var attrs =
                                                                                            ref.attrs
                                                                                          return [
                                                                                            _c(
                                                                                              "span",
                                                                                              _vm._g(
                                                                                                _vm._b(
                                                                                                  {},
                                                                                                  "span",
                                                                                                  attrs,
                                                                                                  false
                                                                                                ),
                                                                                                on
                                                                                              ),
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      item.name
                                                                                                    ) +
                                                                                                    " "
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ]
                                                                                        }
                                                                                      }
                                                                                    ],
                                                                                    null,
                                                                                    true
                                                                                  )
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            item.description
                                                                                          ) +
                                                                                          " "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      item.name
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  false,
                                                                  4253279257
                                                                ),
                                                                model: {
                                                                  value:
                                                                    _vm.curriculumSequencyItemId,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.curriculumSequencyItemId = $$v
                                                                  },
                                                                  expression:
                                                                    "curriculumSequencyItemId"
                                                                }
                                                              })
                                                            : _vm._e(),
                                                          _vm.offerTypeMode == 3
                                                            ? _c("v-select", {
                                                                attrs: {
                                                                  "return-object":
                                                                    "",
                                                                  items:
                                                                    _vm.disciplines,
                                                                  label:
                                                                    "Disciplinas",
                                                                  "item-value":
                                                                    "discipline_id",
                                                                  "item-text":
                                                                    "discipline.name_complete",
                                                                  error:
                                                                    _vm.errors
                                                                      .length >
                                                                    0,
                                                                  "error-messages":
                                                                    _vm.errors
                                                                      .disciplines,
                                                                  dense: "",
                                                                  disabled:
                                                                    !_vm.selectedCurriculum ||
                                                                    !_vm
                                                                      .selectedCurriculum
                                                                      .id,
                                                                  multiple: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "selection",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var item =
                                                                          ref.item
                                                                        var index =
                                                                          ref.index
                                                                        return [
                                                                          index ===
                                                                          0
                                                                            ? _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      item
                                                                                        .discipline
                                                                                        .name
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          index ===
                                                                          1
                                                                            ? _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "grey--text caption ml-1"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " (+" +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .model
                                                                                          .disciplines
                                                                                          .length -
                                                                                          1
                                                                                      ) +
                                                                                      " outros) "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _vm._e()
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  false,
                                                                  730673808
                                                                ),
                                                                model: {
                                                                  value:
                                                                    _vm.disciplinesSelected,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.disciplinesSelected = $$v
                                                                  },
                                                                  expression:
                                                                    "disciplinesSelected"
                                                                }
                                                              })
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12"
                                                      }
                                                    },
                                                    [
                                                      _vm.showDisciplines &&
                                                      _vm.showDisciplines
                                                        .length > 0
                                                        ? _c(
                                                            "emc-util-scroll",
                                                            {
                                                              attrs: {
                                                                height: "130px"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "ul",
                                                                _vm._l(
                                                                  _vm.showDisciplines,
                                                                  function(
                                                                    item,
                                                                    i
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key: i
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              item
                                                                                .discipline
                                                                                .name_complete
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                        item.category &&
                                                                        !item
                                                                          .category
                                                                          .default
                                                                          ? _c(
                                                                              "v-tooltip",
                                                                              {
                                                                                attrs: {
                                                                                  bottom:
                                                                                    ""
                                                                                },
                                                                                scopedSlots: _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key:
                                                                                        "activator",
                                                                                      fn: function(
                                                                                        ref
                                                                                      ) {
                                                                                        var on =
                                                                                          ref.on
                                                                                        var attrs =
                                                                                          ref.attrs
                                                                                        return [
                                                                                          _c(
                                                                                            "v-chip",
                                                                                            _vm._g(
                                                                                              _vm._b(
                                                                                                {
                                                                                                  staticStyle: {
                                                                                                    cursor:
                                                                                                      "help"
                                                                                                  },
                                                                                                  attrs: {
                                                                                                    color:
                                                                                                      "purple",
                                                                                                    label:
                                                                                                      "",
                                                                                                    "x-small":
                                                                                                      "",
                                                                                                    outlined:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                "v-chip",
                                                                                                attrs,
                                                                                                false
                                                                                              ),
                                                                                              on
                                                                                            ),
                                                                                            [
                                                                                              _vm._v(
                                                                                                " " +
                                                                                                  _vm._s(
                                                                                                    item
                                                                                                      .category
                                                                                                      .initials
                                                                                                  ) +
                                                                                                  " "
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ]
                                                                                      }
                                                                                    }
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                )
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        item
                                                                                          .category
                                                                                          .name
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e()
                                                                      ],
                                                                      1
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { key: "others" },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("sys-select-base-group", {
                                    attrs: {
                                      model: _vm.model.baseGroups,
                                      error: _vm.errors.length > 0,
                                      errors: _vm.errors.groups,
                                      label: "Ingressantes",
                                      "course-id": _vm.model.course_id,
                                      "campus-id": _vm.model.campus_id,
                                      "shift-id": _vm.model.shift_id,
                                      multiple: "",
                                      dense: ""
                                    },
                                    on: {
                                      "update:model": function($event) {
                                        return _vm.$set(
                                          _vm.model,
                                          "baseGroups",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      error:
                                        _vm.errors.code_curriculum &&
                                        _vm.errors.length > 0,
                                      "error-messages":
                                        _vm.errors.code_curriculum,
                                      label: "Matriz Aplicada",
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.model.code_curriculum,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model,
                                          "code_curriculum",
                                          $$v
                                        )
                                      },
                                      expression: "model.code_curriculum"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("sys-select-resource", {
                                    attrs: {
                                      model: _vm.model.resources,
                                      error: _vm.errors.length > 0,
                                      errors: _vm.errors.resources,
                                      multiple: "",
                                      label: "Recursos Necessários",
                                      dense: ""
                                    },
                                    on: {
                                      "update:model": function($event) {
                                        return _vm.$set(
                                          _vm.model,
                                          "resources",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("sys-select-hour-group", {
                                    attrs: {
                                      model: _vm.model.hour_group_id,
                                      error: _vm.errors.length > 0,
                                      errors: _vm.errors.hour_group_id,
                                      label: "Grupo de Horário",
                                      clearable: "",
                                      dense: ""
                                    },
                                    on: {
                                      "update:model": function($event) {
                                        return _vm.$set(
                                          _vm.model,
                                          "hour_group_id",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("sys-select-shift", {
                                    attrs: {
                                      model: _vm.model.time_shift_id,
                                      error: _vm.errors.length > 0,
                                      errors: _vm.errors.time_shift_id,
                                      label: "Turno do Horário",
                                      clearable: "",
                                      dense: ""
                                    },
                                    on: {
                                      "update:model": function($event) {
                                        return _vm.$set(
                                          _vm.model,
                                          "time_shift_id",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("s-text-field", {
                                    attrs: {
                                      "text-helper":
                                        "Número de semanas letivas. Utilizado para converter a carga horária total para semanal. Se não informado, utiliza o valor padrão cadastrado no Período Letivo",
                                      dense: "",
                                      "error-messages": _vm.errors.weeks,
                                      label: "Número de Semanas"
                                    },
                                    model: {
                                      value: _vm.model.weeks,
                                      callback: function($$v) {
                                        _vm.$set(_vm.model, "weeks", $$v)
                                      },
                                      expression: "model.weeks"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("sys-select-branch", {
                                    attrs: {
                                      model: _vm.model.branch_id,
                                      error: _vm.errors.length > 0,
                                      errors: _vm.errors.branch_id,
                                      label: "Filial",
                                      "campus-id": _vm.model.campus_id,
                                      clearable: ""
                                    },
                                    on: {
                                      "update:model": function($event) {
                                        return _vm.$set(
                                          _vm.model,
                                          "branch_id",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("sys-select-group-identifier", {
                                    attrs: {
                                      model: _vm.model.group_identifier_id,
                                      error: _vm.errors.length > 0,
                                      errors: _vm.errors.group_identifier_id,
                                      label: "Identificador",
                                      clearable: "",
                                      "show-add": "",
                                      "auto-complete": ""
                                    },
                                    on: {
                                      "update:model": function($event) {
                                        return _vm.$set(
                                          _vm.model,
                                          "group_identifier_id",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("sys-select-category-ambience", {
                                    attrs: {
                                      model: _vm.model.category_ambience_id,
                                      error: _vm.errors.length > 0,
                                      errors: _vm.errors.category_ambience_id,
                                      label: "Categoria de Ambiente",
                                      clearable: "",
                                      "auto-complete": ""
                                    },
                                    on: {
                                      "update:model": function($event) {
                                        return _vm.$set(
                                          _vm.model,
                                          "category_ambience_id",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("sys-select-ambience", {
                                    attrs: {
                                      model: _vm.model.ambience_id,
                                      error:
                                        _vm.errors.ambience_id &&
                                        _vm.errors.length > 0,
                                      errors: _vm.errors.ambience_id,
                                      label: "Ambiente",
                                      clearable: "",
                                      autoComplete: "",
                                      "category-id":
                                        _vm.model.category_ambience_id,
                                      "campus-id": _vm.model.campus_id
                                    },
                                    on: {
                                      "update:model": function($event) {
                                        return _vm.$set(
                                          _vm.model,
                                          "ambience_id",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("s-switch", {
                                    attrs: {
                                      "text-helper":
                                        "Bloquear atribuição de professor",
                                      inset: "",
                                      label: "Bloquear Atribuição"
                                    },
                                    model: {
                                      value: _vm.model.lock_attribution,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model,
                                          "lock_attribution",
                                          $$v
                                        )
                                      },
                                      expression: "model.lock_attribution"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { key: "others" },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("s-text-field", {
                                    attrs: {
                                      dense: "",
                                      "error-messages": _vm.errors.students,
                                      label: "Alunos",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.model.students,
                                      callback: function($$v) {
                                        _vm.$set(_vm.model, "students", $$v)
                                      },
                                      expression: "model.students"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("s-text-field", {
                                    attrs: {
                                      dense: "",
                                      "error-messages":
                                        _vm.errors.students_forecast,
                                      label: "Alunos Previsão",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.model.students_forecast,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model,
                                          "students_forecast",
                                          $$v
                                        )
                                      },
                                      expression: "model.students_forecast"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("s-text-field", {
                                    attrs: {
                                      dense: "",
                                      "error-messages": _vm.errors.students_max,
                                      label: "Vagas",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.model.students_max,
                                      callback: function($$v) {
                                        _vm.$set(_vm.model, "students_max", $$v)
                                      },
                                      expression: "model.students_max"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("s-text-field", {
                                    attrs: {
                                      dense: "",
                                      "error-messages":
                                        _vm.errors.students_freshman,
                                      label: "Calouros",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.model.students_freshman,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model,
                                          "students_freshman",
                                          $$v
                                        )
                                      },
                                      expression: "model.students_freshman"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("s-text-field", {
                                    attrs: {
                                      dense: "",
                                      "error-messages":
                                        _vm.errors.students_free,
                                      label: "Bolsistas",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.model.students_free,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model,
                                          "students_free",
                                          $$v
                                        )
                                      },
                                      expression: "model.students_free"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { key: "sub_groups" },
                    [
                      _c("sys-group-sub-group", { attrs: { model: _vm.model } })
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { key: "availability" },
                    [
                      _c("sys-availability-register", {
                        attrs: { model: _vm.model }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.errors.generate
                ? _c("v-alert", { attrs: { type: "error" } }, [
                    _c(
                      "ul",
                      _vm._l(_vm.errors.generate, function(error, i) {
                        return _c("li", { key: i }, [
                          _vm._v(" " + _vm._s(error) + " ")
                        ])
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              _c("emc-alert-snackbar", {
                attrs: {
                  show: !!_vm.message.length,
                  message: _vm.message,
                  color: _vm.error ? "error" : "success"
                }
              }),
              _c("sys-find-discipline", {
                attrs: {
                  showAdd: "",
                  showSelect: "",
                  singleSelect: false,
                  show: _vm.dialogAddDiscipline
                },
                on: {
                  onSelect: function($event) {
                    return _vm.selectDiscipline($event)
                  },
                  "update:show": function($event) {
                    _vm.dialogAddDiscipline = $event
                  }
                }
              }),
              _c("emc-alert-modal", {
                attrs: {
                  disabled: _vm.loadingOffer,
                  text: "Confirma a geração da oferta?",
                  show: _vm.showGenerateOffer
                },
                on: {
                  "update:show": function($event) {
                    _vm.showGenerateOffer = $event
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "actions",
                    fn: function() {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              disabled: _vm.loadingOffer,
                              loading: _vm.loadingOffer,
                              color: "orange lighten-2"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.generateOffer()
                              }
                            }
                          },
                          [
                            _vm._v("Gerar Oferta"),
                            _c("v-icon", { attrs: { right: "", dark: "" } }, [
                              _vm._v("mdi-flash")
                            ])
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c("emc-alert-modal", {
                attrs: {
                  text: "Deseja excluir a disciplina?",
                  show: _vm.showDeleteDiscipline
                },
                on: {
                  "update:show": function($event) {
                    _vm.showDeleteDiscipline = $event
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "actions",
                    fn: function() {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "error" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.deleteItem()
                              }
                            }
                          },
                          [
                            _vm._v("Excluir "),
                            _c("v-icon", { attrs: { right: "", dark: "" } }, [
                              _vm._v("mdi-delete")
                            ])
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }