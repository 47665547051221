import { mapActions, mapState } from 'vuex'
import state from '../../auth/store/state'
import { myMixin } from '../../../mixins';

export default {
    name: 'Disponibilidade',
    mixins: [myMixin],
    data () {
      return {
        title: 'Disponibilidade do Professor',
        showMailAvailability: false,
        release: false,
        showLegendRelease: false,
        showLegendActivity: false,
        showOpenNewLink: false,
        waiting: 'Carregando...',
        chDigitalOrigem: 0,
        panel: 0,
        chMaxDigital: 0,
        chMaxActivity: 0,
        chMaxManha: 0,
        chMaxTarde: 0,
        chMaxNoite: 0,
        chMinSemanal: 0,
        chMaxSemanal: 0,
        maxManha: 0,
        maxTarde: 0,
        maxNoite: 0,
        originDisps: [],
        maxWorkLoadOrigin: 0,
        agree_term: null,
        selectedHourGroup: null,
        term_option_id: null,
        config: {
          maxWorkLoad: 44,
          showDisciplinesInterest: false,
          showAvailabilityDate: false,
          showAvailabilityWeek: false,
          showUpdateData: false,
          showSaturday: true,
          showSunday: false,
          showSaturdayNight: false,
          showSundayNight: false,
          showAttributions: false,
          showOtherActivities: false,
          showTimeTable: false,
          showActivityTimeTable: false,
          showConfirmation: false,
          showAgreeTerm: false,
          showOccurrences: false,
          showTermSignatures: false,
          showAddress: false,
          validateMinWorkLoad: false,
          visibleBox: {
            digital: true,
            shifts: true,
            total: false
          },
          showBox: {
            morning: false,
            evening: false,
            night: false
          },
        },
        tokenTeacher: null,
        textProgress: 'Carregando dados...',
        progress: true,
        visible: false,
        visibleSearch: true,
        acessoValido: true,
        acessoEspecifico: false,
        teacher: null,
        modelActivityHour: false,
        workTime: null,
        nomeProfessor: '',
        dialog: false,
        snackbar: false,
        itemsProfessores: [],
        texto: '',
        search: '',
        textoConfirmacao: "",
        itemsHours: [],
        filteredItemsHours: [],
        color: '',
        colors: ['error', 'success', 'orange', 'blue', 'grey', 'purple'],
        headers: [
          {
            text: 'Horário',
            align: 'start',
            sortable: false,
            value: 'horario',
            number: 0
          },
          {
            text: 'Segunda',
            align: 'center',
            sortable: false,
            value: 'seg',
            number: 2
          },
          {
            text: 'Terça',
            align: 'center',
            sortable: false,
            value: 'ter',
            number: 3
          },
          {
            text: 'Quarta',
            align: 'center',
            sortable: false,
            value: 'qua',
            number: 4
          },
          {
            text: 'Quinta',
            align: 'center',
            sortable: false,
            value: 'qui',
            number: 5
          },
          {
            text: 'Sexta',
            align: 'center',
            sortable: false,
            value: 'sex',
            number: 6
          },
          {
            text: 'Sábado',
            align: 'center',
            sortable: false,
            value: 'sab',
            number: 7
          },
          {
            text: 'Domingo',
            align: 'center',
            sortable: false,
            value: 'dom',
            number: 1
          },
          
        ],
        headersTeacher: [
          { text: 'Nome', align: 'left', value: 'name' },
      ],
        
      }
    },

    created(){
      
      // Remover o Domingo da Lista de Cabeçalho
      if(!this.config.showSunday){
        this.headers.splice(7, 1);
      }

      if(!this.config.showSaturday){
        this.headers.splice(6, 1);
      }
      
      this.teacher = {
        id: 0,
        name: ''
      };
      this.workTime = {
        id: 0,
        name: '',
        term: null,
        term_id: null
      };

          

    },

    computed: {
      ...mapState('auth', ['acesso', 'user', 'token']),
      
      showActions() {
        
        if(this.$route.query.token) {
          return false;
        }

        if(this.acesso.instituicao) {
          return true
        }

        return false
      },
      
      selectedTeacher () {
           return this.teacher && this.teacher.id != 0;
      },

      showHourGroup () {

        if(this.teacher && this.teacher.institution && this.teacher.institution.settings){
          return this.teacher.institution.settings.show_hour_group_availability;
        }else{
          return false;
        }

      },

      hourGroupsList (){

        if(this.showHourGroup ){

          let groups = this.teacher.institution.hour_groups.filter(h => (h.active && h.show_availability));

          if(!this.selectedHourGroup && groups.length > 0){
            this.selectedHourGroup = groups[0].id;
          }
          
          return groups;

        }else{
          return [];
        }

      },

      showEnableActivityTime(){
        
        let value = (this.acesso.instituicao && this.acesso.instituicao.enable_activity_time) ||
               (this.teacher && this.teacher.institution && this.teacher.institution.enable_activity_time);

        return value;

      },

      isAvailabilityEnabled() {

        if(this.progress) {
          return true;
        }

        if(!this.workTime) {
          return false;  
        }
        
        if(this.teacher && !this.workTime.is_available_availability) {

          /*
            if(this.teacher.is_available_availability) {
              this.workTime.availability_end = this.teacher.release_availability_until
            }

            */
            return this.teacher.is_available_availability;
        }

        return this.workTime.is_available_availability;

      },
    },

    mounted(){
      this.getData();
    },
    methods: {
      ...mapActions('availability', 
        [
          'ActionAvailabilityCreate',
          'ActionAvailabilityUpdate',
          'ActionGetWorkTimeByToken',
        ]),
        ...mapActions('hour', ['ActionGetHoursGroups', 'ActionGetHoursByToken']),
        ...mapActions('teacher', ['ActionFindTeacherByToken']),
        ...mapActions('auth', ['ActionSetToken', 'ActionSetUser']),
                
      getData() {
        
        var token = this.$route.query.token;
        
        if(token){
          
          // const url = new URL(window.location);
          // const newUrl = new URL(url.origin + url.pathname);
          // window.history.pushState({}, '', newUrl);
          this.ActionSetToken(token);

        }else{

          if(this.token && (this.user && this.user.mode == 'teacher')){
            token = this.token;
          }

        }

        if(this.$route.name === 'availability-teacher' && token){
          
            this.visibleSearch = false;
            this.acessoEspecifico = true;
  
            this.getHours(token);
            
            this.release = false;
          
        }else{
          
          this.release = true;
          this.getHours(null);
          this.workTime = state.acesso.periodo;
          this.setConfigs();
          
        }
        
      },

      showHourByGroup(hour){

        if(this.showHourGroup){
          return this.hasGroupHour(hour);
        }else{
          return true;
        }

      },

      showDays(hour, day){
        
        if(day == 1){ // Domingo

          if(this.config.showSunday){
            
            if(hour.shift_id == 3 &&  !this.config.showSundayNight){
              return false;
            }else{
              return true;
            }
            
          }else{
            return false;
          }

        }else if(day == 7){ // Sábado

          if(this.config.showSaturday){
            
            if(hour.shift_id == 3 &&  !this.config.showSaturdayNight){
              return false;
            }else{
              return true;
            }
          
          }else{
            return false;
          }

        }else{
          return true;
        }
        
      },

      getHours(token){

        if(token){
          
          var payload = {
            token: token
          };

          this.ActionGetHoursByToken(payload).then((res) => {
              this.loadHours(res.data);
              this.getWorkTime(token);
          }).catch((error) =>{
            
            if(error.code == 401 || error.code == 422){
              this.showOpenNewLink = true;
            }

            this.showSnack(error.message, true);
            this.progress = false;

          })
          .finally(() => {
            this.progress = false;
          });

        }else{
          
          var payload = {
            with: 'shift',
            per_page: 1000
          };

          
          this.ActionGetHoursGroups(payload)
          .then((res) => {
            
            if(res.success){
              this.loadHours(res.data);
            }
          }).catch((error) =>{

          })
          .finally(() => {
            this.progress = false;
          });
        }
        
      },

      getWorkTime(token){

        
        var payload = {
          token: token,
          with: 'term',
        };
        
        this.ActionGetWorkTimeByToken(payload)
        
          .then((res) => {
            
            if(res.success){
              this.workTime = res.data;
              this.getTeacherByToken(token);
              this.setConfigs();
            }
          
          })
          .catch((error) =>{
            
            if(error.code == 401 || error.code == 422){
              this.showOpenNewLink = true;
            }
            
            this.showSnack(error.message, true);
            this.progress = false;

          })
          .finally(() => {

           
            
          });
      },

      setConfigs(){

        if(this.workTime){
          this.config.visibleBox.digital      = this.workTime.show_digital;
          this.config.visibleBox.shifts       = this.workTime.show_box_total;
          this.config.maxWorkLoad             = this.workTime.max_work_load;
          this.config.showDisciplinesInterest = this.workTime.show_disciplines_interest;
          this.config.showAvailabilityDate    = this.workTime.show_availability_date;
          this.config.showAvailabilityWeek    = this.workTime.show_availability_week;
          this.config.showUpdateData          = this.workTime.show_update_data;
          this.config.showAttributions        = this.workTime.display_indications;
          this.config.showOtherActivities     = this.workTime.show_other_activities;
          this.config.showTimeTable           = this.workTime.display_times;
          this.config.showActivityTimeTable   = this.workTime.show_activity_time_table;
          this.config.showConfirmation        = this.workTime.show_confirmation;
          this.config.showAgreeTerm           = this.workTime.show_agree_term;
          this.config.showOccurrences         = this.workTime.show_occurrences;
          this.config.autoCalculate           = this.workTime.auto_calculate_work_load;
          this.config.validateMinWorkLoad     = this.workTime.validate_min_work_load;
        }

        if(this.teacher && this.teacher.realInstitution && this.teacher.realInstitution.settings){
          this.config.showTermSignatures = this.teacher.realInstitution.settings.show_term_signatures;
          this.config.showAddress = this.teacher.realInstitution.settings.show_address_teacher;
        }

        

      },
      
      getTeacherByToken(token){

        var payload = {
          token: token,
          with: 'institution.termOptions,institution.hourGroups,emails,contracts.position,availability.availabilities',
          withContract: true,
          filter_relation_withs: [
            {
                name: 'confirmation',
                key: 'work_time_id', 
                value: this.workTime.id,
            },
            {
              name: 'availability',
              key: 'work_time_id', 
              value: this.workTime.id,
          }
          ] 
        };
        
        this.ActionFindTeacherByToken(payload)
        .then((res) => {
          
          if(res.success){
            
            this.teacher = res.data;
            this.loadFilterItemsHours();
            this.loadAvailability();
            this.tokenTeacher = token;
            this.setConfigs();

            let user = {
              id:         this.teacher.id,
              name:       this.teacher.name,
              url_avatar: this.teacher.url_avatar,
              initials:   this.teacher.initials,
              mode:       'teacher'
            };

            this.ActionSetUser(user);

          }else{
            this.showSnack('Acesso inválido!', true);
          }
        })
        .catch((error) =>{
            this.showSnack('Dados não encontrados!', true);
        })
        .finally(() => {
          this.progress = false;
        });

      },

      loadHours(hours){

        hours.forEach(h => {
          
          var shift = h.shift;

          h['seg'] = 0;
          h['ter'] = 0;
          h['qua'] = 0;
          h['qui'] = 0;
          h['sex'] = 0;
          h['sab'] = 0;
          h['dom'] = 0;

          if(shift.id == 1)
            this.config.showBox.morning = true;
          else if(shift.id == 2)
            this.config.showBox.evening = true;
          else if(shift.id == 3)
            this.config.showBox.night = true;

        });

        this.itemsHours = hours;
        this.loadFilterItemsHours();

      },

      loadFilterItemsHours(){

        let group_ids = [];
        let items = [];

        if(this.teacher && this.teacher.originalInstitution){
          
          if(this.teacher.originalCampus.hour_group_id){
            group_ids.push(this.teacher.originalCampus.hour_group_id);
          }

        }

        if(this.teacher && this.teacher.contracts){
          this.teacher.contracts.forEach(cont => {

            if(cont.position.hour_group_id){
              group_ids.push(cont.position.hour_group_id);
            }
  
          });
        }

        this.itemsHours.forEach(h => {
          
          const items2 = h.items;
          let newHour = Object.assign({}, h);

          newHour.items = [];
          newHour.number_of_classes = 0;

          if(group_ids.length > 0){
            
            if(newHour.groups.find(g => group_ids.includes(g.id))){
              items2.forEach(h2 => {
                if(h2.groups.find(g2 => group_ids.includes(g2.id))){
                  newHour.items.push(h2);
                  newHour.number_of_classes += h2.number_of_classes;
                }
              })

              if(newHour.items.length){
                newHour.start = newHour.items[0].start;
                newHour.end = newHour.items[newHour.items.length - 1].end;
              }

              items.push(newHour);

            }

          }else{
            
            if(newHour.groups.find(g => g.is_default)){
              
              items2.forEach(h2 => {
                if(h2.groups.find(g2 => g2.is_default)){
                  newHour.items.push(h2);
                  newHour.number_of_classes += h2.number_of_classes;
                }
              })

              if(newHour.items.length){
                newHour.start = newHour.items[0].start;
                newHour.end = newHour.items[newHour.items.length - 1].end;
              }
              
              items.push(newHour);

            }
          }

        });

        this.filteredItemsHours = items;
        
      },

      showSnack(text, error){
        
        this.color = error ? 'error' : 'success';
        this.texto = text;
        this.snackbar = true;

      },
      
      getColor (valor) {
        return this.colors[valor];
      },
      
      getIcon (valor) {
        
        switch (valor) {
          case 0:
          case 2:   return "mdi-close-circle";
          case 4:   return "mdi-minus-circle-outline";   
          default:  return "mdi-checkbox-marked-circle";       
        }
       
      },

      getTextoHorario (item) {
        return item.inicio.substring(0, 5) + ' - ' + item.fim.substring(0, 5); 
      },

      disponibilizar(valor, select = false){
          
        var days = ['seg', 'ter', 'qua', 'qui', 'sex', 'sab', 'dom'];
        
        this.filteredItemsHours.forEach(item => {
            
          if(this.showHourGroup){
            
            if(!this.hasGroupHour(item)){
              return;
            }

          }
          
          days.forEach(day => {

              if(this.release && item[day] != 0 && item[day] != 3){
                return;
              }
              
              if(day == 'sab'){
                if(this.config.showSaturday){
                  if(this.config.showSaturdayNight || item.shift_id < 3){
                    item.sab = valor;
                  }else{
                    item.sab = 0;
                  }
                }else{
                    item.sab = 0;
                }
              }else if(day == 'dom'){
                  if(this.config.showSunday) {
                    
                    if(this.config.showSundayNight || item.shift_id < 3){
                      item.dom = valor;
                    }else{
                      item.dom = 0;
                    }
                  }else{
                    item.dom = 0;
                  }
              }else{
                item[day] = valor;
              }

            });

          });
        
        if(valor == 0){
          this.chMaxManha = 0;
          this.chMaxTarde = 0;
          this.chMaxNoite = 0;
          this.chMaxSemanal = 0;
          this.chMinSemanal = 0;
        }
        
        this.updateWorktLoadTotal(false, select);

      },

      hasGroupHour(hour){

        let has = false;

        hour.groups.forEach(g => {
          if(g.id == this.selectedHourGroup){
            has = true;
          }
        });

        return has;

      },

      select(item, dia){
        
        if(!this.isAvailabilityEnabled && !this.release) {
          return;
        }

        var oldValue = -1;
        var newValue = -1;

        switch(dia){
          case 1: oldValue = item.dom;  break;  
          case 2: oldValue = item.seg;  break;
          case 3: oldValue = item.ter;  break;
          case 4: oldValue = item.qua;  break;
          case 5: oldValue = item.qui;  break;
          case 6: oldValue = item.sex;  break;
          case 7: oldValue = item.sab;  break;
        }

        if(this.release){
          
          switch (oldValue) {
            case 0: newValue = 3; break;
            case 1: newValue = 2; break;
            case 2: newValue = 1; break;
            case 4: 
            case 3: newValue = 0; break;
            default:return;
          }

        }else{
          newValue = (oldValue == 0 || oldValue == 2 || oldValue == 4) ? 1 : 0;
        }

        if(this.modelActivityHour){
          
          var key = dia + '|' + item.start;
          var find = this.originDisps.find(o => o.key == key);
          
          if(oldValue != 4){
            if(!find){
              this.originDisps.push({
                key: key,
                value: oldValue
              });
            }
            newValue = 4;
          }else{
            newValue = find ? find.value : 0;
          }
        }

        switch(dia){
          case 1: item.dom = newValue; break;
          case 2: item.seg = newValue; break;
          case 3: item.ter = newValue; break;
          case 4: item.qua = newValue; break;
          case 5: item.qui = newValue; break;
          case 6: item.sex = newValue; break;
          case 7: item.sab = newValue; break;
        }

        this.updateWorktLoadTotal(false, true);

      },

      updateWorktLoadTotal(initial, select = false){

        var totalSemanal = 0;
        var countSemanal = 0;
        var countManha = 0;
        var countTarde = 0;
        var countNoite = 0;
        var shift_id = 0;
        var days = ['seg', 'ter', 'qua', 'qui', 'sex', 'sab', 'dom'];

        this.filteredItemsHours.forEach((h) => {
          
          shift_id = parseInt(h.shift.id);

          days.forEach(day => {
            
            if(h[day] && (h[day] == 1 || h[day] == 3 || h[day] == 5)){
              
              countSemanal += h.number_of_classes;
              
              if(shift_id == 1){
                countManha += h.number_of_classes;
              }else if(shift_id == 2){
                countTarde += h.number_of_classes;
              }else
                countNoite += h.number_of_classes;
            }

          });

        });

        totalSemanal = countSemanal + parseFloat(this.chMaxDigital);

        this.maxManha = countManha;
        this.maxTarde = countTarde;
        this.maxNoite = countNoite;

        if(!initial && this.config.autoCalculate){
          
          this.chMaxManha = countManha;
          this.chMaxTarde = countTarde;
          this.chMaxNoite = countNoite;
  
          if(totalSemanal > this.config.maxWorkLoad){
            totalSemanal = this.config.maxWorkLoad;
          }
  
          if(select){
            this.chMaxSemanal = totalSemanal;
          }
        }
        
      },

      validar(){

        if(!this.validarDisponibilidade())
        {
          this.showSnack('Informe sua disponibilidade de horário!', true);
        }else{
          
          var date = this.workTime.availability_end;

          if(this.teacher.is_available_availability) {
              date = this.teacher.release_availability_until;
          }
          
          if(this.workTime && this.workTime.availability_single){
            this.textoConfirmacao = "Após enviar sua disponibilidade não será possível fazer alteração. Você pode apenas salvar e efetuar o envio até " + this.$options.filters.date(date) + "!";
          }else
          {
            this.textoConfirmacao = "Você poderá realizar alterações até " + this.$options.filters.date(date) +  "!";
          }

          this.dialog = true;

        }

      },

      getDisponibilidades(){
        
        var availability = this.getAvailabilityTeacher();

        if(!availability){
          availability = this.getAvailabilityObject();
        }

        //availability.min_workload           = this.chMinSemanal;
        availability.max_workload           = this.chMaxSemanal;
        availability.digital_availability   = this.chMaxDigital;
        availability.activity_availability  = this.chMaxActivity;
        availability.morning_availability   = this.chMaxManha;
        availability.evening_availability   = this.chMaxTarde;
        availability.night_availability     = this.chMaxNoite;

        if(!this.release && this.workTime.show_agree_term){
          availability.agree_term           = this.agree_term;
          availability.term_option_id       = this.term_option_id;
          availability.term_id              = this.workTime.term_id;
        }

        if((this.chDigitalOrigem != this.chMaxDigital) && !this.release){
          availability.digital_availability_teacher = availability.digital_availability;
        }
        
        return availability;

      },

      getAvailabilityObject(){

        var availability = {
          id: 0,
          teacher_id: this.teacher.id,
          work_time_id: this.workTime.id,
          status_availability_id: 1,
          term_id: null,
          agree_term: null,
          term_option_id: null,
          is_priority: 0,
          requires_complementary: 0,
          // min_workload: 0,
          max_workload: 0,
          note: '',
          token: '',
          morning_availability: 0,
          evening_availability: 0,
          night_availability: 0,
          digital_availability: 0,
          digital_availability_teacher: 0,
          availabilities: []
        };

        return availability;

      },

      getAvailabilities(id){
        
        var availabilites = [];
        var days = [ 'dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'];
        
        this.filteredItemsHours.forEach(hour => {
          
          for (let i = 0; i < 7; i++) {
            
            if(hour[days[i]] != 0){
              
              hour.items.forEach(item => {
                availabilites.push(
                  {
                    availability_id:      id,
                    hour_id:              item.id,
                    hour_start:           item.start,
                    hour_end:             item.end, 
                    day_of_week_id:       (i + 1),
                    availability_type_id: hour[days[i]]
                  }
                );
              });
            }
          }
        });

        return availabilites;

      },
      
      validarDisponibilidade(){

        var count = this.chMaxDigital;

        this.filteredItemsHours.forEach(item => {
          if(item.dom != 0 && item.dom != 2 && item.dom != 4)
            count++;
          if(item.seg != 0 && item.seg != 2 && item.seg != 4)
            count++;
          if(item.ter != 0 && item.ter != 2 && item.ter != 4)
            count++;
          if(item.qua != 0 && item.qua != 2 && item.qua != 4)
            count++;
          if(item.qui != 0 && item.qui != 2 && item.qui != 4)
            count++;
          if(item.sex != 0 && item.sex != 2 && item.sex != 4)
            count++;
          if(item.sab != 0 && item.sab != 2 && item.sab != 4)
            count++;
          
          
      });

      if(count == 0){
        return false;
      }else{

      return true;

      }
    },
      
      saveAvailability(status) {
        
        if(!this.release && this.workTime.show_agree_term && this.workTime.term){
          if((this.workTime.term.is_required && !this.agree_term) || (!this.workTime.term.is_required && !this.term_option_id)){
            let el = document.createElement('a');
            el.href="#term";
            el.click();
            this.showSnack('Informe se está de acordo ou não com o termo!', true);
            return;
          }
        }

        var availability = this.getDisponibilidades();
        var availabilities = this.getAvailabilities(availability.id);
        this.dialog = false;
        this.progress = true;
        var method;

        availability.isToken = this.tokenTeacher != null;
        availability.availabilities = availabilities;
        availability.status_availability_id = status;

        if(this.release){
          availability.alter_from_user = true;
        }
        
        if(status == 2)
            this.textProgress = 'Salvando sua disponibilidade...';
        else
            this.textProgress = 'Enviando sua disponibilidade...';
        
        
        if(availability.id == 0){
          method = 'ActionAvailabilityCreate';
        }else{
          method = 'ActionAvailabilityUpdate';
        }

        if(this.tokenTeacher){
          availability.token = this.tokenTeacher;
        }

        var isError = false;
        var text = null;
        
        this[method](availability).then((res) => {
            if(availability.id == 0){
              this.teacher.availabilities.push(res.data);
            }
            text = res.message;

        })
        .catch((error) => {
          
            if(error.errors.max_workload){
              text = error.errors.max_workload[0];
            }else{
              text = error.errors.join(' | ');
            }

            isError = true;
            availability.status_availability_id = 1;

        }).finally(() => {
            this.showSnack(text, isError);
            this.progress = false;
        });
    },

    selectTeacher(teacher){

      this.visible = false;
      
      if(!teacher || teacher.length == 0){
        return;
      }

      this.teacher = teacher[0];

      this.loadFilterItemsHours();
      this.loadAvailability();
      this.setConfigs();
    },

    loadAvailability(){
      
      this.disponibilizar(0, true);

      this.originDisps = [];
      this.chMinSemanal = 0;
      this.chMaxSemanal = 0;
      this.chMaxDigital = 0;
      this.chMaxActivity = 0;
      this.chMaxManha   = 0;
      this.chMaxTarde   = 0;
      this.chMaxNoite   = 0;
      var showRelease = false;
      var showActivity = false;
      
      var availability = this.getAvailabilityTeacher();
      
      if(!availability)
        return;

      var availabilities = availability.availabilities;
      var item;

      availabilities.forEach((a) => {

        this.filteredItemsHours.forEach(hour => {

          if(item = hour.items.find((i) => i.id == a.hour_id)){
          
            var type_id = parseInt(a.availability_type_id);
  
            switch (parseInt(a.day_of_week_id)) {
              case 1: hour.dom = type_id; break;
              case 2: hour.seg = type_id; break;
              case 3: hour.ter = type_id; break;
              case 4: hour.qua = type_id; break;
              case 5: hour.qui = type_id; break;
              case 6: hour.sex = type_id; break;
              case 7: hour.sab = type_id; break;
            }

            if(type_id == 2 || type_id == 3){
              showRelease = true;
            }

            if(type_id == 4){
              showActivity = true;
            }
          }
        });
      });

      // this.chMinSemanal   = availability.min_workload;
      this.chMaxSemanal   = availability.max_workload;
      this.chMaxDigital   = availability.digital_availability;
      this.chMaxActivity  = availability.activity_availability;
      this.chMaxManha     = availability.morning_availability;
      this.chMaxTarde     = availability.evening_availability;
      this.chMaxNoite     = availability.night_availability;

      this.maxWorkLoadOrigin = availability.max_workload;

      if(!this.release){
        this.showLegendRelease = showRelease;
      }else{
        this.showLegendRelease = true;
      }

      this.showLegendActivity = showActivity;

      this.chDigitalOrigem = this.chMaxDigital;

      this.agree_term     = availability.agree_term;
      this.term_option_id = availability.term_option_id;

      this.updateWorktLoadTotal(true, false);

     
    },

    getAvailabilityTeacher(){
      
      if(this.teacher && this.teacher.availabilities && this.teacher.availabilities.length > 0){
        return this.teacher.availabilities[0];
      }else{
        return null;
      }
      
    },

    getThumbColor(){

      var ava = this.getAvailabilityTeacher();

      if(ava){
        var chTeacher = ava.digital_availability_teacher;

        if(chTeacher == this.chMaxDigital){
          return this.getColor(1);
        }else if(chTeacher > this.chMaxDigital){
          return this.getColor(2);
        }else{
          return this.getColor(3);
        }
      }
      

    },

    getHint(){

      var ava = this.getAvailabilityTeacher();
      if(ava){
        var wokLoad = parseFloat(ava.digital_availability_teacher);
        return 'CH original disponibilizada pelo professor: ' + wokLoad;
      }else{
        return "";
      }

    }

   },

 }

